import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import {
  alpha,
  Box,
  Divider,
  IconButton,
  lighten,
  Stack,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';

import { ReactComponent as LendinLogo } from '@/assets/images/logo-main.svg';
import { SidebarContext } from '@/contexts/SidebarContext';

import HeaderUserbox from './Userbox';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  // States
  const [currentTime, setCurrentTime] = useState(new Date());

  // Responsive
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));

  // useEffect
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      gap={2}
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`
      }}
    >
      {!matchesMD ? (
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" variant="middle" flexItem />}
          alignItems="center"
          spacing={2}
        >
          {matchesLG ? (
            <LendinLogo />
          ) : (
            <Typography
              variant="body1"
              fontSize={matchesMD ? '18px' : '26px'}
              fontWeight="bold"
            >
              {t('general.welcome_to_lendin')}
            </Typography>
          )}

          <Stack>
            <Typography color={theme.colors.alpha.black[50]} variant="h3">
              {dayjs(currentTime).format('hh:mm:ss A')}
            </Typography>

            <Typography color={theme.colors.alpha.black[50]} variant="h6">
              {dayjs(currentTime).format('ddd, DD MMMM YYYY')}
            </Typography>
          </Stack>
          {/* <HeaderMenu /> */}
        </Stack>
      ) : (
        <LendinLogo />
      )}

      <Box display="flex" alignItems="center">
        {/* <HeaderButtons /> */}
        <HeaderUserbox />
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title={t('common.toggle_menu')}>
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
