import {
  alpha,
  Box,
  darken,
  Divider,
  Drawer,
  lighten,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { t } from 'i18next';
import { useContext } from 'react';

//import Logo from '@/components/Logo';
import { ReactComponent as Logo } from '@/assets/images/lendin-logo-white.svg';
import Scrollbar from '@/components/Scrollbar';
import { SidebarContext } from '@/contexts/SidebarContext';

import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 40px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background:
            theme.palette.mode === 'dark'
              ? alpha(lighten(theme.header.background, 0.1), 0.5)
              : darken(theme.colors.alpha.black[100], 0.5),
          boxShadow:
            theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
        }}
      >
        <Scrollbar>
          <Box
            mt={3}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Logo height={'auto'} width="80%" />
          </Box>

          <Divider
            sx={{
              mt: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10]
            }}
          />

          <SidebarMenu />
        </Scrollbar>

        <Typography
          sx={{ px: 2, mt: 1 }}
          variant="subtitle1"
          color="common.white"
          fontSize="12px"
        >
          &copy; {t('general.footer_text')}
        </Typography>
      </SidebarWrapper>

      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.white[100]
                : darken(theme.colors.alpha.black[100], 0.5)
          }}
        >
          <Scrollbar>
            <Box mt={3} width="100%">
              <Logo width="100%" height={40} />
            </Box>

            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10]
              }}
            />

            <SidebarMenu />
          </Scrollbar>

          <Typography
            sx={{ px: 2, mt: 1 }}
            variant="subtitle1"
            color="common.white"
            fontSize="12px"
          >
            &copy; {t('general.footer_text')}
          </Typography>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
