/* eslint-disable simple-import-sort/imports */
import './utils/i18n/config';
import './assets/fonts/fonts.css';
import 'react-toastify/dist/ReactToastify.css';
import './utils/firebase';

import { CssBaseline } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { wrapUseRoutes } from '@sentry/react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import router from '@/router';
import { Notifications } from 'react-push-notification';
import ThemeProvider from './theme/ThemeProvider';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { useEffect } from 'react';
import { selectAccessToken } from './redux/slices/authSlice';
import { getUserAddress, getUserProfile } from './redux/thunks/userThunk';
import { injectZumRailsConnector } from './utils/injectScript';

const useSentryRoutes = wrapUseRoutes(useRoutes);

function App() {
  const content = useSentryRoutes(router);

  // Redux
  const accessToken = useAppSelector(selectAccessToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      dispatch(getUserProfile(navigate));
      dispatch(getUserAddress());
    }
  }, [accessToken]);

  useEffect(() => {
    injectZumRailsConnector();
  }, []);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {/* Loading until role loaded */}
        {content}
        {/* {content} */}

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
          draggable
          theme="colored"
          bodyClassName="meow"
        />

        <Notifications />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
