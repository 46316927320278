import { t } from 'i18next';
import Swal, { SweetAlertCustomClass, SweetAlertIcon } from 'sweetalert2';

interface AlertProps {
  title: string;
  text?: string;
  icon?: SweetAlertIcon;
  confirmLoader?: boolean;
  cancel?: boolean;
  confirm?: boolean;
  cancelText?: string;
  confirmText?: string;
  outside?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
  afterConfirm?: boolean | (() => void);
  customClass?: SweetAlertCustomClass;
}

export default function Alert(props: AlertProps): void {
  const {
    title,
    text,
    icon,
    confirmLoader,
    cancel,
    confirm,
    cancelText,
    confirmText,
    outside,
    onConfirm,
    onClose,
    afterConfirm,
    customClass
  } = props;
  Swal.fire({
    title: title,
    text: text ?? '',
    icon: icon ?? 'success',
    customClass,
    showLoaderOnConfirm: confirmLoader || false,
    showCancelButton: cancel || false,
    showConfirmButton: confirm !== undefined ? confirm : true,
    cancelButtonText: cancelText || t('alert.cancel'),
    confirmButtonText: confirmText || t('alert.confirm'),
    preConfirm: onConfirm,
    didClose: onClose,
    allowOutsideClick: outside !== undefined ? outside : () => !Swal.isLoading()
  }).then((result) => {
    if (result.isConfirmed) {
      if (typeof afterConfirm === 'function') {
        afterConfirm();
      }
    }
  });
}
