import { createSlice } from '@reduxjs/toolkit';
import AccessControl from 'abacl';

import { AddressRes, ProfileRes } from '@/@types/stub/authorization_message';
import { PermissionOfRole } from '@/models/Permission';
import { Role } from '@/models/Role';

import { RootState } from '../store';
import { getUserAddress, getUserProfile } from '../thunks/userThunk';

// Define a type for the slice state
interface AuthState {
  permissions: PermissionOfRole[];
  roles: Role[];
  accessControl?: AccessControl;
  userData?: ProfileRes;
  addressData?: AddressRes;
}
const initialState: AuthState = {
  permissions: [],
  roles: []
};

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    removePermissions: (state) => {
      state.permissions = [];
      state.roles = [];
    }
  },
  extraReducers: (builder) => {
    // builder.addCase(getUserPermissions.fulfilled, (state, action) => {
    //   state.permissions = action.payload.permissions;
    //   state.roles = action.payload.roles;
    // });

    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.userData = action.payload;
    });

    builder.addCase(getUserAddress.fulfilled, (state, action) => {
      state.addressData = action.payload;
    });
  }
});

export const { removePermissions } = userSlice.actions;

export default userSlice.reducer;

// Other code such as selectors can use the imported `RootState` type

export const selectAccessControl = (state: RootState) =>
  state.user.accessControl;

export const selectUserData = (state: RootState) => state.user.userData;

export const selectAddressData = (state: RootState) => state.user.addressData;

// export const selectRole = (state: RootState) => state.authorization.role;
