/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-underscore-dangle */
import axios, { AxiosRequestConfig } from 'axios';

import { setAccessToken } from '@/redux/slices/authSlice';
import { store } from '@/redux/store';
import constants from '@/utils/constants';

const apiClient = axios.create({
  // Later read this URL from an environment variable
  baseURL: constants.API_URL,
  headers: {
    'Accept-Language': 'en-us',
    'Platform-Version': constants.PLATFORM_VERSION
  }
});

apiClient.interceptors.request.use((config) => {
  // If temp exists use it
  const tempTokens = sessionStorage.getItem(constants.TEMP_ACCESS_TOKEN);

  const token = localStorage.getItem(constants.ACCESS_TOKEN);
  if (tempTokens && config.headers) {
    config.headers.Authorization = tempTokens;
  } else if (token && config.headers) {
    config.headers.Authorization = token;
  }

  const windowLocation = window.location.pathname;
  if (windowLocation.startsWith('/gateway')) {
    config.headers['Platform-Version'] = constants.GATEWAY_PLATFORM_VERSION;
  }

  return config;
});

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if ('config' in error) {
      const originalConfig = error.config as AxiosRequestConfig & {
        _retry: boolean;
      };
      if (error.response) {
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          // Get Tokens
          const refreshToken = localStorage.getItem(constants.REFRESH_TOKEN);
          const accessToken = localStorage.getItem(constants.ACCESS_TOKEN);

          try {
            const newToken = await axios.get(
              '/v1/public/authorization/refresh-token',
              {
                baseURL: constants.API_URL,
                headers: {
                  'Accept-Language': 'en-us',
                  'Platform-Version': constants.PLATFORM_VERSION,
                  Authorization: accessToken as string,
                  'Refresh-Token': refreshToken as string
                }
              }
            );
            store.dispatch(setAccessToken(newToken.data.data.access_token));
            //store.dispatch(setRefreshToken(newToken.data.data.refresh_token));

            originalConfig.headers = {
              ...originalConfig.headers,
              Authorization: `${newToken.data.data.access_token}`
            };
          } catch (er) {
            if (axios.isAxiosError(er)) {
              if (er.response?.status === 401) {
                localStorage.removeItem(constants.ACCESS_TOKEN);
                localStorage.removeItem(constants.REFRESH_TOKEN);
                localStorage.removeItem(constants.USER_ROLE);
                localStorage.removeItem(constants.PROFILE_WELCOME_MODAL);
                window.location.replace(constants.LOGIN_ROUTE);
              }
            }
          }

          return axios(originalConfig);
        }
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
