import {
  AccountBalanceWalletTwoTone,
  Groups2TwoTone
} from '@mui/icons-material';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Hidden,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Skeleton,
  Typography
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { UserPermissionType } from '@/@types/stub/authorization_message';
import AvatarImg from '@/assets/images/avatar.svg';
import ChangeRoleModal from '@/components/ChangeRoleModal';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import useApi from '@/hooks/useApi';
import { logout, selectRole } from '@/redux/slices/authSlice';
import { GetFederatedService } from '@/services/AuthService';
import HandleError from '@/utils/HandleError';

import { selectUserData } from '../../../../redux/slices/userSlice';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

export const mainRoles = {
  [UserPermissionType.Admin]: t('common.admin'),
  [UserPermissionType.provider]: t('common.provider'),
  [UserPermissionType.Investor]: t('common.investor'),
  [UserPermissionType.Borrower]: t('common.borrower'),
  [UserPermissionType.UNRECOGNIZED]: t('common.unknown')
};

function HeaderUserbox() {
  const [isOpen, setOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector(selectUserData);
  const selectedRole = useAppSelector(selectRole);

  const ref = useRef<any>(null);

  // API
  const federatedLogoutApi = useApi(GetFederatedService);
  // const normalLogoutApi = useApi(userLogoutService);

  // Functions
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      if (userData?.is_federated) {
        const res = await federatedLogoutApi.request();
        dispatch(logout());
        window.open(res.data.data.list[0].logout_url, '_self');
      } else {
        // await normalLogoutApi.request();
        dispatch(logout());
        handleClose();
        navigate('/auth/login');
      }
    } catch (error) {
      HandleError(error);
    }
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        {!userData ? (
          <Skeleton variant="rounded" width={40} height={40} />
        ) : (
          <Avatar
            sx={{
              borderRadius: '100%',
              width: 40,
              height: 40,
              backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.3)
            }}
            src={userData?.profile_image ? userData?.profile_image : AvatarImg}
            alt={[userData?.first_name, userData?.last_name].join(' ')}
            variant="rounded"
          />
        )}

        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {!userData ? (
                <Skeleton height={20} width={100} />
              ) : (
                <>{[userData?.first_name, userData?.last_name].join(' ')}</>
              )}
            </UserBoxLabel>

            {Boolean(mainRoles[selectedRole]) && (
              <Chip
                label={mainRoles[selectedRole]}
                size="small"
                color="primary"
                sx={{ fontWeight: 'normal' }}
              />
            )}
          </UserBoxText>
        </Hidden>

        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>

      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex" alignItems="center">
          <Avatar
            sx={{
              borderRadius: '100%',
              width: 40,
              height: 40,
              backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.3)
            }}
            src={userData?.profile_image ? userData?.profile_image : AvatarImg}
            alt={[userData?.first_name, userData?.last_name].join(' ')}
            variant="rounded"
          />

          <UserBoxText>
            <UserBoxLabel variant="body1">
              {[userData?.first_name, userData?.last_name].join(' ')}
            </UserBoxLabel>

            {Boolean(mainRoles[selectedRole]) && (
              <Chip
                label={mainRoles[selectedRole]}
                size="small"
                color="primary"
              />
            )}
          </UserBoxText>
        </MenuUserBox>

        <Divider sx={{ mb: 0 }} />

        <List sx={{ p: 1 }} component="nav">
          {selectedRole !== UserPermissionType.Admin ? (
            <>
              <ListItemButton
                onClick={() => {
                  handleClose();
                }}
                to="/account"
                component={NavLink}
                sx={{ gap: 1 }}
              >
                <AccountBoxTwoToneIcon fontSize="small" />

                <ListItemText primary={t('common.my_profile')} />
              </ListItemButton>

              <ListItemButton
                to="/wallet/my-wallet"
                onClick={() => {
                  handleClose();
                }}
                component={NavLink}
                sx={{ gap: 1 }}
              >
                <AccountBalanceWalletTwoTone fontSize="small" />

                <ListItemText primary={t('common.my_wallet')} />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  handleClose();
                }}
                to="/club"
                component={NavLink}
                sx={{ gap: 1 }}
              >
                <Groups2TwoTone fontSize="small" />

                <ListItemText primary={t('common.club')} />
              </ListItemButton>
            </>
          ) : null}

          <ChangeRoleModal />
        </List>

        <Divider />

        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            {t('common.signout')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
