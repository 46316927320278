import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

interface AuthLayoutProps {
  children?: ReactNode;
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  // const navigate = useNavigate();
  // const accessToken = useAppSelector(
  //   (state) => state.authorization.accessToken
  // );

  // useEffect(() => {
  //   if (accessToken) {
  //     navigate('/account');
  //   }
  // }, []);

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {children || <Outlet />}
    </Box>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node
};

export default AuthLayout;
