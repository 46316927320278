import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { UserPermissionType } from '@/@types/stub/authorization_message';
import constants from '@/utils/constants';

import { RootState } from '../store';

// Define a type for the slice state
interface AuthState {
  accessToken?: string | null;
  refreshToken?: string | null;
  selectedRole: UserPermissionType | null;
  tempAccessToken?: string;
  tempRefreshToken?: string;
}

const initialState: AuthState = {
  accessToken: localStorage.getItem(constants.ACCESS_TOKEN),
  refreshToken: localStorage.getItem(constants.REFRESH_TOKEN),
  selectedRole: Number(localStorage.getItem(constants.USER_ROLE)),
  tempAccessToken: sessionStorage.getItem(constants.TEMP_ACCESS_TOKEN),
  tempRefreshToken: sessionStorage.getItem(constants.TEMP_REFRESH_TOKEN)
};

export const authSlice = createSlice({
  name: 'authorization',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAccessToken: (state, action: PayloadAction<string>) => {
      localStorage.setItem(constants.ACCESS_TOKEN, action.payload);
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      localStorage.setItem(constants.REFRESH_TOKEN, action.payload);
      state.refreshToken = action.payload;
    },
    setTempTokens: (
      state,
      action: PayloadAction<{ access: string; refresh: string }>
    ) => {
      sessionStorage.setItem(
        constants.TEMP_ACCESS_TOKEN,
        action.payload.access
      );
      sessionStorage.setItem(
        constants.TEMP_REFRESH_TOKEN,
        action.payload.refresh
      );

      state.tempAccessToken = action.payload.access;
      state.tempRefreshToken = action.payload.refresh;
    },
    logout: (state) => {
      localStorage.removeItem(constants.REFRESH_TOKEN);
      localStorage.removeItem(constants.ACCESS_TOKEN);
      localStorage.removeItem(constants.USER_ROLE);
      localStorage.removeItem(constants.PROFILE_WELCOME_MODAL);

      state.accessToken = null;
      state.refreshToken = null;
      state.selectedRole = null;
    },
    setSelectedRole: (
      state,
      action: PayloadAction<UserPermissionType | null>
    ) => {
      localStorage.setItem(constants.USER_ROLE, String(action.payload));
      state.selectedRole = action.payload;
    }
  }
});

export const {
  setAccessToken,
  logout,
  setRefreshToken,
  setSelectedRole,
  setTempTokens
} = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export const selectRefreshToken = (state: RootState) =>
  state.authorization.refreshToken;

export const selectAccessToken = (state: RootState) =>
  state.authorization.accessToken;

export const selectRole = (state: RootState) =>
  state.authorization.selectedRole;

export const selectTempAccessToken = (state: RootState) =>
  state.authorization.tempAccessToken;
export const selectTempRefreshToken = (state: RootState) =>
  state.authorization.tempRefreshToken;

export default authSlice.reducer;
