/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { t } from 'i18next';
import { useState } from 'react';

import MyError from '@/utils/MyError';

const useApi = <T extends any, R extends any[]>(
  apiFunc: (...args: R) => Promise<T>
) => {
  const [data, setData] = useState<T>();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const request = async (...args: Parameters<typeof apiFunc>) => {
    setLoading(true);
    try {
      setError('');
      setData(undefined);

      const result = await apiFunc(...args);

      setData(result);
      return result;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.status >= 500 && err.status <= 599) {
          Sentry.captureException(err);
        }
        const errMsg = err.response?.data?.message;
        if (errMsg) {
          setError(errMsg);
          throw new MyError(errMsg);
        }
      }
      console.log(err);

      // everything else
      throw new MyError(t('alert.something_was_wrong'));
    } finally {
      setLoading(false);
    }
  };
  const reset = () => {
    setData(undefined);
    setLoading(false);
    setError('');
  };

  return {
    data,
    error,
    loading,
    request,
    reset
  };
};

export default useApi;
