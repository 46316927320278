import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  alpha,
  Box,
  Button,
  List,
  ListItem,
  styled,
  useTheme
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import { SidebarContext } from '@/contexts/SidebarContext';
import { IMenuItem } from '@/interface/Menu';
import { Role } from '@/utils/accessControl';
import accessControl from '@/utils/accessControl';
import { MenuItems } from '@/utils/constants';

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      padding: 0;

      .MuiListItem-root {
        padding: 1px  0;

        a {
          font-weight: normal;

          .MuiButton-startIcon {
            margin-left: 0
          }
        }

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[70]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            // background-color: ${alpha(
              theme.colors.alpha.trueWhite[100],
              0.06
            )};
             background-color: ${theme.palette.primary.dark};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
            
            &:hover {
            background-color: ${alpha(theme.palette.primary.main, 0.2)};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;
          

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const Collapsible = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(1)};
    

    .MuiAccordion-root {
      padding: ${theme.spacing(1)};
      padding-top: 0;
      background-color: transparent;

      .MuiAccordionDetails-root {
        padding: ${theme.spacing(1, 2)};
      }

      .MuiButtonBase-root {
        display: flex;
        color: ${theme.colors.alpha.trueWhite[70]};
        background-color: transparent;
        width: 100%;
        justify-content: flex-start;
        padding: ${theme.spacing(1.2, 3)};
        border-radius: 10px;
        transition: ${theme.transitions.create(['background-color'])};
        transition: ${theme.transitions.create(['color'])};

        &.active,
        &:hover {
          background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
          color: ${theme.colors.alpha.trueWhite[100]};

          .MuiSvgIcon-root {
            color: ${theme.colors.alpha.trueWhite[100]};
          }
          &:hover {
            background-color: ${alpha(theme.palette.primary.main, 0.2)};
            }
        }
      }
      
      .MuiSvgIcon-root {
        color: ${theme.colors.alpha.trueWhite[70]};
        font-size: ${theme.typography.pxToRem(20)};
        font-weight: bold;
      }

      .MuiAccordionSummary-content {
        margin: 0;
        align-items: center;
        gap: ${theme.spacing(1)};
      }

      .MuiAccordionSummary-root.Mui-expanded {
        min-height: 48px;
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  // State
  const [expanded, setExpanded] = useState<string | false>(false);

  // Location
  const location = useLocation();

  // Theme
  const theme = useTheme();

  // Function
  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  // Hook
  useEffect(() => {
    let currentMenu: IMenuItem = null;

    MenuItems.some((menu: IMenuItem) =>
      menu.children?.some((item: IMenuItem) =>
        item.children?.some((submenu: IMenuItem) => {
          if (location.pathname.includes(submenu.path)) {
            currentMenu = item;
            return true;
          }
          return false;
        })
      )
    );

    if (currentMenu) {
      setExpanded(currentMenu.key);
    } else {
      setExpanded(false);
    }
  }, [MenuItems, location.pathname, setExpanded]);

  return (
    <>
      <Collapsible>
        {MenuItems.map((menu: IMenuItem) => {
          if (
            menu.children &&
            !menu.children.some(
              (item: IMenuItem) =>
                item.children &&
                item.children?.some(
                  (child: IMenuItem) =>
                    accessControl.can(
                      [Role.User],
                      child.method,
                      child.permission
                    ).granted
                )
            )
          ) {
            return null;
          }

          if (menu.children && menu.children?.length) {
            return (
              <Box mb={2}>
                <Typography
                  variant="button"
                  color={theme.colors.alpha.trueWhite[50]}
                  fontWeight="600"
                  ml={1}
                  mb={2}
                >
                  {menu.title}
                </Typography>

                {menu.children.map((item: IMenuItem) => {
                  if (item.children?.length) {
                    if (
                      !item.children?.some(
                        (child: IMenuItem) =>
                          accessControl.can(
                            [Role.User],
                            child.method,
                            child.permission
                          ).granted
                      )
                    ) {
                      return null;
                    }
                    return (
                      <Accordion
                        key={item.title}
                        onChange={handleChange(item.key)}
                        expanded={expanded === item.key}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className={
                            item.children.find((item) =>
                              location.pathname.includes(item.path)
                            )
                              ? 'active'
                              : ''
                          }
                        >
                          {item.Icon && <item.Icon />}
                          <Typography>{item.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <SubMenuWrapper>
                            <List component="div">
                              {item.children.map((submenu: IMenuItem) => {
                                if (
                                  !accessControl.can(
                                    [Role.User],
                                    submenu.method,
                                    submenu.permission
                                  ).granted
                                )
                                  return null;

                                return (
                                  <ListItem component="div" key={submenu.title}>
                                    <Button
                                      disableRipple
                                      component={RouterLink}
                                      onClick={closeSidebar}
                                      to={submenu.path}
                                      startIcon={
                                        submenu.Icon && <submenu.Icon />
                                      }
                                    >
                                      {submenu.title}
                                    </Button>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </SubMenuWrapper>
                        </AccordionDetails>
                      </Accordion>
                    );
                  } else {
                    if (
                      accessControl.can(
                        [Role.User],
                        item.method,
                        item.permission
                      ).granted
                    ) {
                      return (
                        <SubMenuWrapper>
                          <List
                            component="div"
                            sx={{ px: '9px !important', pb: '9px !important' }}
                          >
                            <ListItem component="div" key={item.title}>
                              <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to={item.path}
                                startIcon={<item.Icon />}
                              >
                                {item.title}
                              </Button>
                            </ListItem>
                          </List>
                        </SubMenuWrapper>
                      );
                    } else {
                      return null;
                    }
                  }
                })}
              </Box>
            );
          }
        })}
      </Collapsible>
    </>
  );
}

export default SidebarMenu;
