import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import authSlice from './slices/authSlice';
import gatewaySlice from './slices/gatewaySlice';
import userSlice from './slices/userSlice';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

export const store = configureStore({
  reducer: {
    authorization: authSlice,
    user: userSlice,
    gateway: gatewaySlice
  },
  enhancers: [sentryReduxEnhancer]
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
