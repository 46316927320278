import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { NavigateFunction } from 'react-router';

import {
  AddressRes,
  ProfileRes,
  UserPermissionType
} from '@/@types/stub/authorization_message';
import { GetUserProfileService } from '@/services/AuthService';
import { GetUserAddressService } from '@/services/UserService';
import constants from '@/utils/constants';

// export const getUserPermissions = createAsyncThunk(
//   'authorization/getUserPermissions',
//   async () => {
//     const response = await GetUserPermissionsService();
//
//     response.data.data.permissions.map((permission) =>
//       accessControl.update({
//         subject: Role.User,
//         action: permission.method,
//         object: permission.route
//       })
//     );
//
//     return response.data.data;
//   }
// );

export const getUserProfile = createAsyncThunk(
  'authorization/getUserProfile',
  async (navigate: NavigateFunction): Promise<ProfileRes> => {
    const response = await GetUserProfileService();

    // Set Sentry User
    Sentry.setUser({
      id: response.data.data.phone_number,
      username: `${response.data.data.first_name} ${response.data.data.last_name}`,
      email: response.data.data.email
    });

    const localStorageRole = Number(localStorage.getItem(constants.USER_ROLE));
    const isRoleMismatched =
      (localStorageRole === UserPermissionType.Admin &&
        !response.data.data?.is_admin) ||
      (localStorageRole === UserPermissionType.provider &&
        !response.data.data?.is_provider) ||
      (localStorageRole === UserPermissionType.Investor &&
        !response.data.data?.is_investor) ||
      (localStorageRole === UserPermissionType.Borrower &&
        !response.data.data?.is_borrower);

    if (isRoleMismatched) {
      navigate('/auth/login', {
        replace: true,
        state: {
          step: 2
        }
      });
    }

    return response.data.data;
  }
);

export const getUserAddress = createAsyncThunk(
  'authorization/getUserAddress',
  async (): Promise<AddressRes> => {
    const response = await GetUserAddressService();

    return response.data.data;
  }
);
