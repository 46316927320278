// Import the functions you need from the SDKs you need
import * as firebase from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDWwVCKWntmbaniYg4wwZSoP93EzXuhtrQ',
  authDomain: 'lendin-app.firebaseapp.com',
  projectId: 'lendin-app',
  storageBucket: 'lendin-app.appspot.com',
  messagingSenderId: '354256094312',
  appId: '1:354256094312:web:3f7d951be9f766b138a7dd',
  measurementId: 'G-KS67Y0TF0G'
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);
export const token = getToken(messaging);
// onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);
//   // Handle the message
//   // alert(payload.notification.body);
// });
// console.log(token);
