import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import Humanize from 'humanize-plus';

import { Currency } from '../../@types/stub/wallet_message';

interface IProps {
  amount: string | number;
  currency: Currency;
  bold?: boolean;
  fontSize?: string | number;
  color?: string;
  center?: boolean;
  variant?: Variant;
  currencyColor?: string;
}

function Amount(props: IProps) {
  const {
    amount,
    currency,
    bold,
    fontSize,
    color,
    center,
    variant,
    currencyColor
  } = props;

  return (
    <Typography
      component="span"
      fontWeight={bold ? 'bold' : 'normal'}
      fontSize={fontSize}
      color={color || 'common.black'}
      textAlign={center ? 'center' : 'left'}
      variant={variant}
    >
      {currency?.symbol}
      {Humanize.formatNumber(Number(amount), currency?.decimal)}{' '}
      {/*<Typography variant="caption" color={currencyColor || 'text.disabled'}>*/}
      {/*  ({currency?.code ? currency?.code : 'CAD'})*/}
      {/*</Typography>*/}
    </Typography>
  );
}

export default Amount;
