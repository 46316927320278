import { UserAnalyticsInstallmentList } from '@/@types/stub/lendin_message';
import {
  UserAnalyticsIncomeList,
  UserAnalyticsOutcomeList
} from '@/@types/stub/wallet_message';
import { ServiceResponse } from '@/interface/service.interface';
import {
  IAddRole,
  IAssignPermissions,
  IChangePassword,
  IDeleteRole,
  IGetUser,
  IPermissionsResponse,
  IRolePermissions,
  IRolePermissionsResponse,
  IUserAuthApp,
  IUserMfaPreference
} from '@/interface/user.interface';
import { Role } from '@/models/Role';

import {
  AddressRes,
  AdminGetAllRolesRes,
  AdminGetListUsersReq,
  AdminUsersRes,
  GetUserPermissionRes,
  GetUserSpecialRolesRes,
  ProfileRes,
  UpdateAddressReq,
  UpdateProfileReq,
  UsereSubmitSpecialRoleReq,
  UserExtraInformationRes,
  UserInfo,
  UserPermissionType,
  UserUpdateExtraInformationReq
} from '../@types/stub/authorization_message';
import apiClient from './Api';

/* Admin */

export const GetUsersListService = (queryParams?: AdminGetListUsersReq) =>
  apiClient.get<ServiceResponse<AdminUsersRes>>('/v1/admin/users', {
    params: queryParams
  });

export const GetUserService = (queryParams: IGetUser) =>
  apiClient.get<ServiceResponse<UserInfo>>('/v1/admin/user', {
    params: queryParams
  });

export const GetUserByIDService = (queryParams: IGetUser) =>
  apiClient.get<ServiceResponse<UserInfo>>('/v1/admin/user', {
    params: queryParams
  });

export const GetRolesListService = () =>
  apiClient.get<ServiceResponse<AdminGetAllRolesRes>>('/v1/admin/roles');

export const GetAllRolesListService = () =>
  apiClient.get<ServiceResponse<AdminGetAllRolesRes>>('/v1/admin/roles/all');

export const UpdateUserRolesService = (role_ids: number[], user_id: number) =>
  apiClient.put('/v1/admin/roles/user', { role_ids, user_id });

export const AddRoleService = (data: IAddRole) =>
  apiClient.post<ServiceResponse<Role>>('/v1/admin/role', data);

export const EditRoleService = (data: Role) =>
  apiClient.put<ServiceResponse<{ success: boolean }>>('/v1/admin/role', data);

export const DeleteRoleService = (data: IDeleteRole) =>
  apiClient.delete<ServiceResponse<{ success: boolean }>>('/v1/admin/role', {
    data: data
  });
export const GetPermissionsService = () =>
  apiClient.get<ServiceResponse<IPermissionsResponse>>('/v1/admin/permissions');

export const GetRolePermissionsService = (queryParams: IRolePermissions) =>
  apiClient.get<ServiceResponse<IRolePermissionsResponse>>(
    '/v1/admin/role/permissions',
    {
      params: queryParams
    }
  );
export const AssignPermissionsService = (data: IAssignPermissions) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/admin/role/permission',
    data
  );

/* User */

export const GetUserProfileService = () =>
  apiClient.get<ServiceResponse<ProfileRes>>(
    '/v1/authorization/client/profile'
  );
export const UpdateUserProfileService = (data: UpdateProfileReq) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/authorization/client/profile',
    data
  );

export const GetUserAddressService = () =>
  apiClient.get<ServiceResponse<AddressRes>>(
    '/v1/authorization/client/profile/address'
  );
export const UpdateUserAddressService = (data: UpdateAddressReq) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/authorization/client/profile/address',
    data
  );

export const ChangeUserPasswordService = (data: IChangePassword) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/authorization/client/change-password',
    data
  );

export const GetUserPermissionsService = (user_type: UserPermissionType) =>
  apiClient.get<ServiceResponse<GetUserPermissionRes>>(
    '/v1/authorization/client/permissions',
    {
      params: {
        user_type: user_type
      }
    }
  );

export const SetUserMfaPreferenceService = (data: IUserMfaPreference) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/authorization/client/mfa',
    data
  );

export const GetUserMfaPreferenceService = () =>
  apiClient.get<ServiceResponse<IUserMfaPreference>>(
    '/v1/authorization/client/mfa'
  );

export const SetAuthenticatorAppService = () =>
  apiClient.post<ServiceResponse<IUserAuthApp>>(
    '/v1/authorization/client/mfa/software'
  );

export const VerifyAuthenticatorAppService = (code: string) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/authorization/client/mfa/software/verify',
    {
      code: code
    }
  );

export const UserSpecialRoleRequestService = (
  data: UsereSubmitSpecialRoleReq
) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/authorization/client/profile/special-role',
    data
  );

export const UserGetSpecialRolesService = () =>
  apiClient.get<ServiceResponse<GetUserSpecialRolesRes>>(
    '/v1/authorization/client/special-role'
  );

export const GetUserInstallmentsMonthlyService = () =>
  apiClient.get<ServiceResponse<UserAnalyticsInstallmentList>>(
    '/v1/analytic/client/installments'
  );

export const GetUserIncomeService = () =>
  apiClient.get<ServiceResponse<UserAnalyticsIncomeList>>(
    '/v1/analytic/client/income'
  );

export const GetUserOutcomeService = () =>
  apiClient.get<ServiceResponse<UserAnalyticsOutcomeList>>(
    '/v1/analytic/client/outcome'
  );

export const UpdateUserExtraInformationService = (
  data: UserUpdateExtraInformationReq
) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/authorization/client/profile-extra',
    data
  );

export const GetUserExtraInformationService = () =>
  apiClient.get<ServiceResponse<UserExtraInformationRes>>(
    '/v1/authorization/client/profile-extra'
  );
