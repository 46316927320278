import {
  AccountBalanceWalletTwoTone,
  AccountCircleTwoTone,
  CurrencyExchangeTwoTone,
  DashboardTwoTone,
  DescriptionTwoTone,
  Groups2TwoTone,
  MonetizationOnTwoTone,
  NotificationsTwoTone,
  ReceiptLongTwoTone,
  ReceiptTwoTone,
  RequestPageTwoTone,
  StorefrontTwoTone
} from '@mui/icons-material';
import { SettingsTwoTone } from '@mui/icons-material';
import { t } from 'i18next';

import { IMenuItem } from '@/interface/Menu';

export const MenuItems: IMenuItem[] = [
  {
    title: t('menu.user'),
    key: 'user',
    children: [
      {
        title: t('menu.dashboard'),
        key: 'borrower_dashboard',
        Icon: DashboardTwoTone,
        path: '/dashboard',
        permission: '/v1/special/borrower/pending-invoice/confirm',
        method: 'POST'
      },
      {
        title: t('menu.dashboard'),
        key: 'provider_dashboard',
        Icon: DashboardTwoTone,
        path: '/dashboard',
        permission: '/v1/special/provider/pending-invoice',
        method: 'POST'
      },
      {
        title: t('menu.dashboard'),
        key: 'investor_dashboard',
        Icon: DashboardTwoTone,
        path: '/dashboard',
        permission: '/v1/special/investor/investment/invests',
        method: 'GET'
      },
      {
        title: t('menu.wallet'),
        key: 'user_wallet',
        Icon: AccountBalanceWalletTwoTone,
        children: [
          {
            title: t('menu.my_wallet'),
            key: 'user_wallet_my_wallet',
            path: '/wallet/my-wallet',
            permission: '/v1/wallet/client/wallets',
            method: 'GET'
          },
          {
            title: t('menu.transactions'),
            key: 'user_wallet_transactions',
            path: '/wallet/transactions',
            permission: '/v1/wallet/client/transactions',
            method: 'GET'
          },
          {
            title: t('menu.payment_methods'),
            key: 'user_wallet_payment_methods',
            path: '/wallet/payment-methods',
            permission: '/v1/wallet/client/wallets',
            method: 'GET'
          }
        ]
      },
      {
        title: t('menu.investment'),
        key: 'user_investment',
        Icon: CurrencyExchangeTwoTone,
        children: [
          {
            title: t('menu.my_investment_policies'),
            key: 'user_investment_policies',
            path: '/investment/investments_policies',
            permission: '/v1/special/investor/investment/invests',
            method: 'GET'
          },
          {
            title: t('menu.pending_invoice'),
            key: 'user_pending_invoices',
            path: '/investment/suggested_invoices',
            permission: '/v1/special/investor/investment/invests',
            method: 'GET'
          },
          {
            title: t('menu.loans'),
            key: 'user_investor_loans',
            path: '/investment/loans',
            permission: '/v1/special/investor/investment/invests',
            method: 'GET'
          }
        ]
      },
      {
        title: t('menu.provider'),
        key: 'user_provider',
        Icon: StorefrontTwoTone,
        children: [
          {
            title: t('menu.invoices'),
            key: 'user_provider_invoice_list',
            path: '/invoice/provider',
            permission: '/v1/special/provider/pending-invoice',
            method: 'POST'
          },
          {
            title: t('menu.unpaid_installments'),
            key: 'user_provider_installment_list',
            path: '/installment/provider',
            permission: '/v1/special/provider/installments',
            method: 'GET'
          },
          {
            title: t('invoice.new.title'),
            key: 'user_invoice_new',
            path: '/invoice/new',
            permission: '/v1/special/provider/pending-invoice',
            method: 'POST'
          }
        ]
      },
      {
        title: t('menu.purchases'),
        key: 'user_purchases',
        Icon: ReceiptLongTwoTone,
        children: [
          {
            title: t('menu.my_invoices'),
            key: 'user_purchases_user_invoice_list',
            path: '/invoice/list',
            permission: '/v1/special/borrower/pending-invoice/confirm',
            method: 'POST'
          },
          {
            title: t('menu.my_loans'),
            key: 'user_purchases_user_loans',
            path: '/loans/my-loans',
            permission: '/v1/special/borrower/pending-invoice/confirm',
            method: 'POST'
          },

          {
            title: t('menu.my_installments'),
            key: 'user_purchases_user_installment_list',
            path: '/installment/list',
            permission: '/v1/special/borrower/pending-invoice/confirm',
            method: 'POST'
          }
        ]
      },
      // {
      //   title: t('menu.collaterals'),
      //   key: 'user_collaterals',
      //   Icon: RequestPageTwoTone,
      //   children: [
      //     {
      //       title: t('menu.collaterals_list'),
      //       key: 'user_collaterals_list',
      //       path: '/collaterals/list',
      //       permission: '/v1/special/borrower/collateral/crypto/request',
      //       method: 'POST'
      //     },
      //     {
      //       title: t('menu.transfer_history'),
      //       key: 'user_collaterals_transfer_history',
      //       path: '/collaterals/transfer_history',
      //       permission: '/v1/special/borrower/collateral/crypto/request',
      //       method: 'POST'
      //     }
      //   ]
      // },
      {
        title: t('menu.club'),
        key: 'user_club',
        Icon: Groups2TwoTone,
        path: '/club',
        permission: '/v1/authorization/client/profile/invitation-codes',
        method: 'GET'
      }
    ]
  },
  {
    title: t('menu.admin'),
    key: 'admin',
    children: [
      {
        title: t('menu.dashboard'),
        key: 'admin_dashboard',
        Icon: DashboardTwoTone,
        path: '/dashboard',
        permission: '/v1/admin/analytic',
        method: 'GET'
      },
      {
        title: t('menu.user'),
        key: 'admin_user',
        Icon: AccountCircleTwoTone,
        children: [
          {
            title: t('menu.users'),
            key: 'admin_user_list',
            path: '/admin/user/list',
            permission: '/v1/admin/users',
            method: 'GET'
          },
          {
            title: t('menu.roles'),
            key: 'admin_user_roles',
            path: '/admin/role/list',
            permission: '/v1/admin/roles',
            method: 'GET'
          },
          {
            title: t('menu.groups'),
            key: 'admin_user_groups',
            path: '/admin/group/list',
            permission: '/v1/admin/user-group/list',
            method: 'GET'
          }
        ]
      },
      {
        title: t('menu.investment'),
        key: 'admin_investment',
        Icon: MonetizationOnTwoTone,
        children: [
          {
            title: t('menu.user_investments'),
            key: 'admin_investment_investments',
            path: '/admin/investment/list',
            permission: '/v1/admin/investment/invests',
            method: 'GET'
          },
          {
            title: t('menu.policies'),
            key: 'admin_investment_policies',
            path: '/admin/policy/list',
            permission: '/v1/admin/investment_policy/policies',
            method: 'GET'
          },
          {
            title: t('menu.invest_level'),
            key: 'admin_invest_level',
            path: '/admin/invest_level/list',
            permission: '/v1/admin/invest-level/levels',
            method: 'GET'
          },
          {
            title: t('menu.loans_list'),
            key: 'admin_loans',
            path: '/admin/loans/list',
            permission: '/v1/admin/loan/loans',
            method: 'GET'
          },
          {
            title: t('menu.installments'),
            key: 'admin_installments',
            path: '/admin/installments/list',
            permission: '/v1/admin/installment/installments',
            method: 'GET'
          }
        ]
      },
      {
        title: t('menu.transactions'),
        key: 'admin_transactions',
        Icon: ReceiptTwoTone,
        children: [
          {
            title: t('menu.transactions_list'),
            key: 'admin_transactions_list',
            path: '/admin/transaction/list',
            permission: '/v1/admin/wallet/transactions',
            method: 'GET'
          },
          {
            title: t('menu.awaiting_confirmation'),
            key: 'admin_transactions_awaiting_withdraws',
            path: '/admin/transaction/withdraws',
            permission: '/v1/admin/wallet/transactions/waiting-confirm',
            method: 'GET'
          },
          {
            title: t('menu.reconciliation'),
            key: 'admin_transactions_reconciliation',
            path: '/admin/transaction/reconciliation',
            permission: '/v1/admin/wallet/transactions/conflict',
            method: 'GET'
          },
          {
            title: t('menu.bank_transactions_list'),
            key: 'admin_bank_transactions_list',
            path: '/admin/transaction/bank_transactions',
            permission: '/v1/admin/wallet/transactions',
            method: 'GET'
          }
        ]
      },
      {
        title: t('menu.invoices'),
        key: 'admin_invoices',
        Icon: DescriptionTwoTone,
        children: [
          {
            title: t('menu.invoices_list'),
            key: 'admin_invoices_list',
            path: '/admin/invoice',
            permission: '/v1/admin/invoice/invoices',
            method: 'GET'
          },
          {
            title: t('menu.pending_refunds'),
            key: 'admin_invoices_pending_refunds',
            path: '/admin/invoice/pending-refunds',
            permission: '/v1/admin/invoice/invoices',
            method: 'GET'
          }
        ]
      },
      {
        title: t('menu.providers'),
        key: 'admin_providers',
        Icon: StorefrontTwoTone,
        children: [
          {
            title: t('menu.providers_list'),
            key: 'admin_providers_list',
            path: '/admin/provider/list',
            permission: '/v1/admin/provider/providers',
            method: 'GET'
          },
          {
            title: t('menu.categories'),
            key: 'admin_providers_categories',
            path: '/admin/provider/category/list',
            permission: '/v1/admin/provider/categories',
            method: 'GET'
          }
        ]
      },
      {
        title: t('menu.collaterals'),
        key: 'admin_collaterals',
        Icon: RequestPageTwoTone,
        children: [
          {
            title: t('menu.collaterals_list'),
            key: 'admin_collaterals_list',
            path: '/admin/collateral/list',
            permission: '/v1/admin/collateral/requests',
            method: 'GET'
          },
          {
            title: t('menu.transfer_history'),
            key: 'admin_collaterals_transfer_history',
            path: '/admin/collateral/transfer_history',
            permission: '/v1/admin/collateral/user-credit/transfers',
            method: 'GET'
          }
        ]
      },
      {
        title: t('menu.club'),
        key: 'admin_club',
        Icon: Groups2TwoTone,
        path: '/admin/club',
        permission: '/v1/admin/config/invitation-points',
        method: 'GET'
      },
      {
        title: t('menu.notifications'),
        key: 'admin_notifications',
        Icon: NotificationsTwoTone,
        children: [
          {
            title: t('menu.custom_notifications'),
            key: 'admin_notifications_send',
            path: '/admin/notification/send',
            permission: '/v1/admin/config/notification',
            method: 'POST'
          },
          {
            title: t('menu.notification_settings'),
            key: 'admin_notifications_settings',
            path: '/admin/notification/settings',
            permission: '/v1/admin/config/notification-templates',
            method: 'GET'
          }
        ]
      },
      {
        title: t('menu.system'),
        key: 'admin_system',
        Icon: SettingsTwoTone,
        children: [
          {
            title: t('menu.countries'),
            key: 'admin_system_countries',
            path: '/admin/country/list',
            permission: '/v1/admin/authorization/countries',
            method: 'GET'
          },
          {
            title: t('menu.currencies'),
            key: 'admin_system_currencies',
            path: '/admin/currency/list',
            permission: '/v1/admin/wallet/currencies',
            method: 'GET'
          },
          {
            title: t('menu.federateds'),
            key: 'admin_system_federateds',
            path: '/admin/federated/list',
            permission: '/v1/admin/federated',
            method: 'GET'
          },
          {
            title: t('menu.collateral_configs'),
            key: 'admin_system_collateral_configs',
            path: '/admin/collateral_configs',
            permission: '/v1/admin/collateral/configs',
            method: 'GET'
          },
          {
            title: t('menu.investment_configs'),
            key: 'admin_system_investment_configs',
            path: '/admin/investment_configs',
            permission: '/v1/admin/investment/configs',
            method: 'GET'
          },
          {
            title: t('menu.wallet_setting'),
            key: 'admin_system_wallet_setting',
            path: '/admin/wallet_setting',
            permission: '/v1/admin/wallet/zum/account-group',
            method: 'GET'
          },
          // TODO: change permissions
          // {
          //   title: t('menu.auth_config'),
          //   key: 'admin_system_auth_config',
          //   path: '/admin/settings/auth_config',
          //   permission: '/v1/admin/wallet/zum/account-group',
          //   method: 'GET'
          // },
          {
            title: t('menu.system_cache'),
            key: 'admin_system_settings_cache',
            path: '/admin/settings/cache',
            permission: '/v1/admin/system/cache',
            method: 'DELETE'
          }
        ]
      }
    ]
  }
];

function getConfigs() {
  if (!import.meta.env.VITE_SLUG)
    throw new Error('VITE_SLUG not defined in environment');

  // Api URL
  if (!import.meta.env.VITE_API_URL)
    throw new Error('VITE_API_URL not defined in environment');

  if (!import.meta.env.VITE_PLATFORM_VERSION)
    throw new Error('VITE_PLATFORM_VERSION not defined in environment');

  if (!import.meta.env.VITE_GATEWAY_PLATFORM_VERSION)
    throw new Error('VITE_GATEWAY_PLATFORM_VERSION not defined in environment');

  return {
    ACCESS_TOKEN: `${import.meta.env.VITE_SLUG}_accessToken`,
    REFRESH_TOKEN: `${import.meta.env.VITE_SLUG}_refreshToken`,

    TEMP_ACCESS_TOKEN: `${import.meta.env.VITE_SLUG}_tempAccessToken`,
    TEMP_REFRESH_TOKEN: `${import.meta.env.VITE_SLUG}_tempRefreshToken`,

    USER_ROLE: `${import.meta.env.VITE_SLUG}_userRole`,
    PROFILE_WELCOME_MODAL: `${import.meta.env.VITE_SLUG}_profileWelcomeModal`,

    LOGIN_ROUTE: `/auth/login`,

    API_URL: import.meta.env.VITE_API_URL,
    PLATFORM_VERSION: import.meta.env.VITE_PLATFORM_VERSION,
    GATEWAY_PLATFORM_VERSION: import.meta.env.VITE_GATEWAY_PLATFORM_VERSION,

    GATEWAY_TOKEN_STORAGE: `${import.meta.env.VITE_SLUG}_gatewayToken`,
    GATEWAY_SELECTED_POLICY_STORAGE: `${
      import.meta.env.VITE_SLUG
    }_gatewayPolicy`,
    GATEWAY_POLICY_INVOICE_ID_STORAGE: `${
      import.meta.env.VITE_SLUG
    }_gatewayPolicyInvoiceId`,

    REDIRECT_AFTER_LOGIN: '/dashboard',

    TINYMCE_API_KEY: 'hyt4c0o7wsa9jcvkh1mlfbz8053cy2rbwmq9ysx92mx1yjj0'
  };
}

export default getConfigs();
