import {
  Box,
  Card,
  Container,
  Grid,
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
  Typography
} from '@mui/material';
import moment from 'moment';
import { FC, ReactNode, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Outlet } from 'react-router-dom';

import LendinLogoPng from '../../assets/images/logo-solid.png';
import SuspenseLoader from '../../components/SuspenseLoader';
import Amount from '../../components/UI/Amount';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useApi from '../../hooks/useApi';
import {
  selectGatewayInfo,
  selectGatewayToken,
  setGatewayInfo,
  setGatewayToken
} from '../../redux/slices/gatewaySlice';
import { selectUserData } from '../../redux/slices/userSlice';
import { GetGatewayInfoService } from '../../services/GatewayService';
import Alert from '../../utils/Alert';
import HandleError from '../../utils/HandleError';

// Styles
const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4, 0)
}));
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main
  }
}));

interface GatewayLayoutProps {
  children?: ReactNode;
}

const GatewayLayout: FC<GatewayLayoutProps> = ({ children }) => {
  // Utils
  const params = useParams<{ token: string }>();
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const gatewayInfo = useAppSelector(selectGatewayInfo);
  const gatewayToken = useAppSelector(selectGatewayToken);
  const userData = useAppSelector(selectUserData);

  // Api
  const getGatewayInfoApi = useApi(GetGatewayInfoService);

  // State
  const [timeout, setTimeout] = useState<string>();
  const [progress, setProgress] = useState(0);
  const [createdInvoice, setCreatedInvoice] = useState(0);
  const [redirectUrl, setRedirectUrl] = useState('');

  // Effect
  useEffect(() => {
    if (params.token) {
      sessionStorage.clear();
      dispatch(setGatewayToken(params.token));
    }
  }, [params]);

  useEffect(() => {
    if (gatewayToken) {
      getGatewayInfoApi
        .request(gatewayToken)
        .then((res) => {
          dispatch(setGatewayInfo(res.data.data));
          setTimeout(res.data.data.time_out_at);
          setRedirectUrl(res.data.data.redirect_url);
          setCreatedInvoice(moment(res.data.data.invoice?.created_at).unix());
        })
        .catch((err) => HandleError(err));
    }
  }, [gatewayToken]);

  // useEffect(() => {
  //   window.addEventListener('beforeunload', doBeforeCloseTab);
  //   return () => window.removeEventListener('beforeunload', doBeforeCloseTab);
  // }, []);

  const expired = () => {
    if (timeout) {
      Alert({
        title: 'Session expired',
        icon: 'warning',
        text: 'Your purchase time has expired',
        confirmText: `Redirect to ${getGatewayInfoApi.data?.data.data?.provider_info?.name}`,
        outside: false,
        afterConfirm: () => {
          window.location.replace(encodeURI(redirectUrl));
        }
      });
    }
  };

  if (getGatewayInfoApi.loading) return <SuspenseLoader />;

  return (
    <Container
      sx={{
        height: '100vh'
      }}
    >
      <Wrapper>
        {/*{getGatewayInfoApi.loading && <SuspenseLoader />}*/}
        {gatewayInfo && (
          <Grid component={Card} container alignItems="stretch" height="100%">
            <Grid
              item
              sm={6}
              py={4}
              px={4}
              xs={12}
              sx={{ borderRight: `1px solid #C4C1BF` }}
            >
              <Stack justifyContent="center" alignItems="center" spacing={2}>
                <Box component="img" src={LendinLogoPng} width="270px" />
                <Typography variant="h3" fontWeight={500}>
                  {t('gateway.title')}
                </Typography>

                <Amount
                  fontSize={25}
                  amount={gatewayInfo.invoice?.total_amount}
                  currency={gatewayInfo.invoice?.currency}
                />

                <Box width="100%">
                  <BorderLinearProgress
                    variant="determinate"
                    value={progress}
                  />
                  <Typography textAlign="center" mt={1} variant="h4">
                    Timeout in:{' '}
                    {timeout && (
                      <Countdown
                        date={timeout}
                        key={timeout}
                        onComplete={expired}
                        onTick={() => {
                          // progress
                          const now = moment().unix();
                          const prgrs = now - createdInvoice;
                          const diff = moment(timeout).unix() - createdInvoice;

                          setProgress((prgrs * 100) / diff);
                        }}
                        renderer={(props) => {
                          return (
                            <Typography
                              variant="subtitle2"
                              fontWeight="bold"
                            >{`${props.minutes} minute(s), ${props.seconds} second(s)`}</Typography>
                          );
                        }}
                      />
                    )}
                  </Typography>
                </Box>

                {/*  Provider Info */}
                <Typography
                  pt={4}
                  variant="h5"
                  textAlign="center"
                >{`You are about to purchase the invoice titled '${gatewayInfo.invoice?.title}' for the amount of ${gatewayInfo.invoice?.total_amount} from ${gatewayInfo.provider_info?.name}. `}</Typography>
              </Stack>

              {userData && (
                <Stack
                  sx={{ borderTop: `1px dashed #C4C1BF` }}
                  justifyContent="center"
                  alignItems="center"
                  mt={4}
                  py={4}
                >
                  <Typography variant="body1" fontWeight={500}>
                    {`${userData.first_name} ${userData.last_name}`}
                  </Typography>
                </Stack>
              )}
            </Grid>
            <Grid item sm={6} xs={12} height="100%">
              {children || <Outlet />}
            </Grid>
          </Grid>
        )}
      </Wrapper>
    </Container>
  );
};

export default GatewayLayout;
