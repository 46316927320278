import {
  AddressRes,
  Country,
  GetFederatedListRes,
  ProfileRes,
  RespondToAuthChallengeReq,
  SignInRes,
  UpdateEmailReq,
  UserVerificationRes,
  VerifyEmailReq,
  VerifyPhoneNumberReq,
  VerifySoftwareTokenReq,
  VerifySoftwareTokenRes
} from '@/@types/stub/authorization_message';
import { StatusRes } from '@/@types/stub/base';
import {
  GetZumAggregationTokenRes,
  SubmitAggregationRequestIdReq
} from '@/@types/stub/wallet_message';
import {
  IAddCountry,
  ICitiesByState,
  ICitiesListResponse,
  IForgotPasswordConfirm,
  IRegisterConfirm,
  IRegisterRequest,
  IRegisterResend,
  ISignIn,
  ISignInResponse,
  IStatesByCountry,
  IStatesListResponse,
  IUpdateCountry,
  IUserAddress,
  IUserProfile
} from '@/interface/auth.interface';
import { ServiceResponse } from '@/interface/service.interface';
import { IRespondToAuthChallenge } from '@/interface/user.interface';

import apiClient from './Api';

export const registerService = (data: IRegisterRequest) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/public/authorization/sign-up',
    data
  );

export const forgotPasswordService = (phone_number: string) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/public/authorization/forgot-password',
    {
      phone_number
    }
  );

export const forgotPasswordConfirmService = (data: IForgotPasswordConfirm) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/public/authorization/forgot-password/confirm',
    data
  );

export const RespondToAuthChallengeService = (data: IRespondToAuthChallenge) =>
  apiClient.post<ServiceResponse<ISignInResponse>>(
    '/v1/public/authorization/sign-in/challenge',
    data
  );

export const signInService = (data: ISignIn) =>
  apiClient.post<ServiceResponse<SignInRes>>(
    '/v1/public/authorization/sign-in',
    data
  );

export const RegisterConfirmService = (data: IRegisterConfirm) =>
  apiClient.post<ServiceResponse<SignInRes>>(
    '/v1/public/authorization/sign-up/confirm',
    data
  );

export const GetAllCountriesService = () =>
  apiClient.get<ServiceResponse<{ countries: Country[] }>>(
    '/v1/public/authorization/countries'
  );

export const GetAdminCountriesService = () =>
  apiClient.get<ServiceResponse<{ countries: Country[] }>>(
    '/v1/admin/authorization/countries'
  );

export const GetCurrentCountriesService = () =>
  apiClient.get<ServiceResponse<{ countries: Country[] }>>(
    '/v1/public/authorization/client/countries'
  );
export const AddCountryService = (data: IAddCountry) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/admin/authorization/country',
    data
  );
export const UpdateCountryService = (data: IUpdateCountry) =>
  apiClient.put<ServiceResponse<{ success: boolean }>>(
    '/v1/admin/authorization/country',
    data
  );

export const RegisterResendService = (data: IRegisterResend) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/public/authorization/sign-up/resend',
    data
  );

export const GetUserProfileService = () =>
  apiClient.get<ServiceResponse<ProfileRes>>(
    '/v1/authorization/client/profile'
  );

export const SetUserProfileService = (data: IUserProfile) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/authorization/client/profile',
    data
  );

export const RegisterUserProfileService = (data: IUserProfile) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/authorization/client/register/profile',
    data
  );

export const GetUSerAddressService = () =>
  apiClient.get<ServiceResponse<AddressRes>>(
    '/v1/authorization/client/profile/address'
  );

export const SetUserAddressService = (data: IUserAddress) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/authorization/client/profile/address',
    data
  );

export const GetStatesByCountryService = (queryParams: IStatesByCountry) =>
  apiClient.get<ServiceResponse<IStatesListResponse>>(
    '/v1/public/authorization/client/states',
    {
      params: queryParams
    }
  );

export const GetCitiesByStateService = (queryParams: ICitiesByState) =>
  apiClient.get<ServiceResponse<ICitiesListResponse>>(
    '/v1/public/authorization/client/cities',
    {
      params: queryParams
    }
  );

export const GetFederatedService = () =>
  apiClient.get<ServiceResponse<GetFederatedListRes>>(
    '/v1/public/authorization/federated'
  );

export const userLogoutService = () =>
  apiClient.get<ServiceResponse<{ success: boolean }>>(
    '/v1/public/authorization/sign-out'
  );

export const SendFederatedCodeService = (code: string) =>
  apiClient.post<ServiceResponse<ISignInResponse>>(
    '/v1/public/authorization/federated/code',
    {
      code
    }
  );

export const UpdatePhoneNumberService = (phone_number: string) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/authorization/client/profile/phone-number',
    {
      phone_number
    }
  );

export const VerifyUpdatePhoneNumberService = (data: VerifyPhoneNumberReq) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/authorization/client/profile/phone-number/verify',
    data
  );

export const UpdateEmailService = (data: UpdateEmailReq) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/authorization/client/profile/email',
    data
  );

export const VerifyUpdateEmailService = (data: VerifyEmailReq) =>
  apiClient.put<ServiceResponse<{ success: boolean }>>(
    '/v1/authorization/client/profile/email',
    data
  );

export const VerifySignInChallengeService = (data: RespondToAuthChallengeReq) =>
  apiClient.post<ServiceResponse<SignInRes>>(
    '/v1/public/authorization/sign-in/challenge',
    data
  );

export const VerifySoftwareTokenMfaService = (data: VerifySoftwareTokenReq) =>
  apiClient.post<ServiceResponse<VerifySoftwareTokenRes>>(
    '/v1/authorization/client/mfa/software/verify',
    data
  );

export const UserRequestVerificationService = () =>
  apiClient.post<ServiceResponse<UserVerificationRes>>(
    '/v1/authorization/client/profile/verification',
    {
      iam: undefined
    }
  );

export const UserGetLastVerificationRequestService = () =>
  apiClient.get<ServiceResponse<UserVerificationRes>>(
    '/v1/authorization/client/profile/verification'
  );

export const GetZumAggregationTokenService = () =>
  apiClient.get<ServiceResponse<GetZumAggregationTokenRes>>(
    '/v1/wallet/client/zum/aggregation'
  );

export const SubmitZumAggregationService = (
  data: SubmitAggregationRequestIdReq
) =>
  apiClient.post<ServiceResponse<StatusRes>>(
    '/v1/wallet/client/zum/aggregation',
    data
  );
