import { alpha, Box, lighten, useTheme } from '@mui/material';
import { onMessage } from 'firebase/messaging';
import { useCallback, useEffect, useState } from 'react';
import addNotification from 'react-push-notification';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { selectAccessToken, selectRole } from '@/redux/slices/authSlice';
import { SendTokenPushNotificationService } from '@/services/PushNotificationService';
import { getPermissionsAsync } from '@/utils/accessControl';
import { messaging, token } from '@/utils/firebase';

import SuspenseLoader from '../../components/SuspenseLoader';
import withAuthentication from '../../components/withAuthentication';
import useApi from '../../hooks/useApi';
import HandleError from '../../utils/HandleError';
import Header from './Header';
import Sidebar from './Sidebar';

function SidebarLayout() {
  const theme = useTheme();

  // Redux
  const accessToken = useAppSelector(selectAccessToken);
  const dispatch = useAppDispatch();
  const selectedRole = useAppSelector(selectRole);
  const navigate = useNavigate();

  // State
  const [loading, setLoading] = useState(false);

  // Firebase
  const sendPushTokenApi = useApi(SendTokenPushNotificationService);

  const sendPushTokenFunc = useCallback(async () => {
    try {
      if (messaging) {
        onMessage(messaging, (payload) => {
          addNotification({
            title: payload.notification.title,
            subtitle: payload.notification.icon,
            message: payload.notification.body,
            icon: '/images/logo-solid.png',
            theme: 'darkblue',
            duration: 40000, //optional, default: 5000,
            native: true
          });
        });
        await sendPushTokenApi.request({
          iam: undefined,
          token: await token
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [messaging]);

  // Get Profile data
  const getUserData = useCallback(async () => {
    try {
      if (accessToken) {
        setLoading(true);

        await getPermissionsAsync(dispatch, selectedRole, navigate);
        await sendPushTokenFunc();
      }
    } catch (e) {
      HandleError(e);
    } finally {
      setLoading(false);
    }
  }, [accessToken]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  if (loading) {
    return <SuspenseLoader />;
  }

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',

          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.black[100],
                    0.1
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05
                  )}`
          }
        }}
      >
        <Header />

        <Sidebar />

        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('lg')]: {
              ml: `${theme.sidebar.width}`
            }
          }}
        >
          <Box display="block">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default withAuthentication(SidebarLayout);
