import { SubmitPushNotificationTokenReq } from '@/@types/stub/notification_message';
import { ServiceResponse } from '@/interface/service.interface';

import apiClient from './Api';

export const SendTokenPushNotificationService = (
  data: SubmitPushNotificationTokenReq
) =>
  apiClient.post<ServiceResponse<{ success: boolean }>>(
    '/v1/notification/client/token',
    data
  );
