// Define a type for the slice state
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetInvoicePendingCachedRes,
  Invoice,
  ProviderInvestmentPolicyData
} from '../../@types/stub/lendin_message';
import { FullProviderInfo } from '../../@types/stub/provider_message';
import constants from '../../utils/constants';
import { RootState } from '../store';

interface GatewayState {
  gatewayToken: string | null;
  invoice?: Invoice;
  policies: ProviderInvestmentPolicyData[];
  time_out_at?: string;
  provider_info?: FullProviderInfo;
  selectedPolicy?: ProviderInvestmentPolicyData;
  policyInvoiceId?: number;
  redirectUrl?: string;
}

const invoiceIdData = sessionStorage.getItem(
  constants.GATEWAY_POLICY_INVOICE_ID_STORAGE
);

const initialState: GatewayState = {
  gatewayToken: sessionStorage.getItem(constants.GATEWAY_TOKEN_STORAGE),
  policies: [],
  selectedPolicy: JSON.parse(
    sessionStorage.getItem(constants.GATEWAY_SELECTED_POLICY_STORAGE)
  ),
  policyInvoiceId: invoiceIdData ? Number(invoiceIdData) : undefined
};

export const gatewaySlice = createSlice({
  name: 'gateway',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setGatewayToken: (state, action: PayloadAction<string>) => {
      sessionStorage.setItem(constants.GATEWAY_TOKEN_STORAGE, action.payload);
      state.gatewayToken = action.payload;
    },
    setGatewayInfo: (
      state,
      action: PayloadAction<GetInvoicePendingCachedRes>
    ) => {
      state.policies = action.payload.policies;
      state.provider_info = action.payload.provider_info;
      state.time_out_at = action.payload.time_out_at;
      state.invoice = action.payload.invoice;
      state.redirectUrl = action.payload.redirect_url;
    },
    setGatewaySelectedPolicy: (
      state,
      action: PayloadAction<ProviderInvestmentPolicyData>
    ) => {
      sessionStorage.setItem(
        constants.GATEWAY_SELECTED_POLICY_STORAGE,
        JSON.stringify(action.payload)
      );
      state.selectedPolicy = action.payload;
    },
    setPolicyInvoiceId: (state, action: PayloadAction<number>) => {
      sessionStorage.setItem(
        constants.GATEWAY_POLICY_INVOICE_ID_STORAGE,
        String(action.payload)
      );
      state.policyInvoiceId = action.payload;
    }
  }
});

export const {
  setGatewayToken,
  setGatewayInfo,
  setGatewaySelectedPolicy,
  setPolicyInvoiceId
} = gatewaySlice.actions;

export default gatewaySlice.reducer;

// Other code such as selectors can use the imported `RootState` type

export const selectGatewayToken = (state: RootState) =>
  state.gateway.gatewayToken;

export const selectGatewayInfo = (state: RootState) => ({
  policies: state.gateway.policies,
  invoice: state.gateway.invoice,
  time_out_at: state.gateway.time_out_at,
  provider_info: state.gateway.provider_info,
  redirectUrl: state.gateway.redirectUrl
});

export const selectSelectedPolicy = (state: RootState) =>
  state.gateway.selectedPolicy;

export const selectPolicyInvoiceId = (state: RootState) =>
  state.gateway.policyInvoiceId;
