import {
  ConfirmInvoiceByBorrowerReq,
  ConfirmInvoiceByBorrowerRes,
  GetInvoicePendingCachedReq,
  GetInvoicePendingCachedRes,
  LoanFeeCalculatorByInvoiceReq,
  LoanFeeCalculatorByInvoiceRes,
  PendingInvoicePolicyChooseByBorrowerReq,
  PendingInvoicePolicyChooseByBorrowerRes
} from '../@types/stub/lendin_message';
import { ServiceResponse } from '../interface/service.interface';
import apiClient from './Api';

export const GetGatewayInfoService = (token: string) =>
  apiClient.get<ServiceResponse<GetInvoicePendingCachedRes>>(
    '/v1/public/lendin/cached-invoice',
    { params: { token } }
  );

export const ChooseCachedInvoiceService = (
  data: PendingInvoicePolicyChooseByBorrowerReq
) =>
  apiClient.post<ServiceResponse<PendingInvoicePolicyChooseByBorrowerRes>>(
    '/v1/lendin/client/cached-invoice/borrower/choose',
    data
  );

export const ConfirmCachedInvoiceService = (
  data: ConfirmInvoiceByBorrowerReq
) =>
  apiClient.post<ServiceResponse<ConfirmInvoiceByBorrowerRes>>(
    '/v1/lendin/client/cached-invoice/borrower/confirm',
    data
  );

export const CalculateLoanFeeByInvoiceService = (
  params: LoanFeeCalculatorByInvoiceReq
) =>
  apiClient.get<ServiceResponse<LoanFeeCalculatorByInvoiceRes>>(
    '/v1/lendin/client/loan/loan-fee',
    { params }
  );

export const GetCachedInvoiceService = (data: GetInvoicePendingCachedReq) =>
  apiClient.get<ServiceResponse<GetInvoicePendingCachedRes>>(
    '/v1/public/lendin/cached-invoice',
    { params: data }
  );
