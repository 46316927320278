import { t } from 'i18next';
import { toast } from 'react-toastify';

import MyError from './MyError';

export default function HandleError(error: any): void {
  if (error instanceof MyError) {
    error.toastMessage();
  } else {
    console.error(t('alert.lendin_error'), error);
    toast.error(t('alert.an_error_occured'));
  }
}
