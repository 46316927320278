import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from '@/components/SuspenseLoader';
import BaseLayout from '@/layouts/BaseLayout';
import SidebarLayout from '@/layouts/SidebarLayout';

import AuthLayout from './layouts/AuthLayout';
import GatewayLayout from './layouts/GatewayLayout';
import constants from './utils/constants';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

// Dashboard
// const InvestorDashboardPage = Loader(
//   lazy(() => import('@/pages/Dashboard/InvestorDashboard'))
// );

const DashboardPage = Loader(lazy(() => import('@/pages/Dashboard')));

const InvestmentPolicyDetailsPage = Loader(
  lazy(() => import('@/pages/InvestmentPolicyDetails'))
);
const UserInvestmentsPolicies = Loader(
  lazy(() => import('@/pages/UserInvestmentsPolicies'))
);

const UserPendingInvoices = Loader(
  lazy(() => import('@/pages/PendingInvoices'))
);

const UserPendingInvoiceDetailsPage = Loader(
  lazy(() => import('@/pages/PendingInvoiceDetails'))
);

// Status

const Status404 = Loader(
  lazy(() => import('@/content/pages/Status/Status404'))
);

const Status500 = Loader(
  lazy(() => import('@/content/pages/Status/Status500'))
);

const StatusComingSoon = Loader(
  lazy(() => import('@/content/pages/Status/ComingSoon'))
);

const StatusMaintenance = Loader(
  lazy(() => import('@/content/pages/Status/Maintenance'))
);

// Authentication

const RegisterPage = Loader(lazy(() => import('@/pages/Auth/Register')));

const LoginPage = Loader(lazy(() => import('@/pages/Auth/Login')));

const ForgotPasswordPage = Loader(
  lazy(() => import('@/pages/Auth/ForgotPassword'))
);

const ForceChangePasswordPage = Loader(
  lazy(() => import('@/pages/Auth/ForceChangePassword'))
);

// User Management

const UserListPage = Loader(
  lazy(() => import('@/pages/Admin/UserManagement/List'))
);

const EditAndNewUserPage = Loader(
  lazy(() => import('@/pages/Admin/UserManagement/EditAndNew'))
);

const ViewUserPage = Loader(
  lazy(() => import('@/pages/Admin/UserManagement/View'))
);

const RolesListPage = Loader(
  lazy(() => import('@/pages/Admin/UserManagement/Roles/List'))
);

const PermissionsListPage = Loader(
  lazy(() => import('@/pages/Admin/UserManagement/Roles/Permissions'))
);

const GroupsListPage = Loader(
  lazy(() => import('@/pages/Admin/UserManagement/Groups/List'))
);

const AddEditGroupPage = Loader(
  lazy(() => import('@/pages/Admin/UserManagement/Groups/AddEdit'))
);

// System

const CountriesListPage = Loader(
  lazy(() => import('@/pages/Admin/Country/List'))
);

const FederatedListPage = Loader(
  lazy(() => import('@/pages/Admin/Federated/List'))
);

const CurrenciesListPage = Loader(
  lazy(() => import('@/pages/Admin/Currency/List'))
);

const ClearSystemCachePage = Loader(
  lazy(() => import('@/pages/Admin/Settings/ClearCache'))
);

// Transactions

const AdminTransactionsListPage = Loader(
  lazy(() => import('@/pages/Admin/Transactions/List'))
);

const WithdrawConfirmationPage = Loader(
  lazy(() => import('@/pages/Admin/Transactions/WithdrawConfirmation'))
);

const ReconciliationPage = Loader(
  lazy(() => import('@/pages/Admin/Transactions/Reconciliation'))
);

const BankTransactionsPage = Loader(
  lazy(() => import('@/pages/Admin/Transactions/BankTransactions'))
);

// Invoices

const PendingRefundsListPage = Loader(
  lazy(() => import('@/pages/Admin/Invoices/PendingRefunds'))
);
const AdminInvoicesPage = Loader(
  lazy(() => import('@/pages/Admin/Invoices/List'))
);
// Loans

const LoansPage = Loader(lazy(() => import('@/pages/Loans')));
const LoanDetail = Loader(lazy(() => import('@/pages/LoanDetails')));
const AdminLoanDetail = Loader(
  lazy(() => import('@/pages/Admin/AdminLoanDetails'))
);
const InvestorLoansPage = Loader(lazy(() => import('@/pages/InvestorLoans')));

// Investment

const InvestmentPoliciesPage = Loader(
  lazy(() => import('@/pages/Admin/InvestmentPolicies/List'))
);

const AddEditPolicyPage = Loader(
  lazy(() => import('@/pages/Admin/InvestmentPolicies/AddEdit'))
);

const ViewInvestmentPolicyPage = Loader(
  lazy(() => import('@/pages/Admin/InvestmentPolicies/View'))
);

const InvestmentsPage = Loader(
  lazy(() => import('@/pages/Admin/Investments/List'))
);

const ViewInvestmentPage = Loader(
  lazy(() => import('@/pages/Admin/Investments/View'))
);

const AdminInstallmentsListPage = Loader(
  lazy(() => import('@/pages/Admin/Installments'))
);

const AdminLoansListPage = Loader(lazy(() => import('@/pages/Admin/Loans')));

// Provider

const ProvidersPage = Loader(lazy(() => import('@/pages/Admin/Provider/List')));

const AddEditProviderPage = Loader(
  lazy(() => import('@/pages/Admin/Provider/AddEdit'))
);

const ViewProviderPage = Loader(
  lazy(() => import('@/pages/Admin/Provider/View'))
);

const ProviderCategoriesPage = Loader(
  lazy(() => import('@/pages/Admin/Provider/Categories/List'))
);

const AddEditProviderCategoryPage = Loader(
  lazy(() => import('@/pages/Admin/Provider/Categories/AddEdit'))
);

const ViewCategoryPage = Loader(
  lazy(() => import('@/pages/Admin/Provider/Categories/View'))
);

const UnpaidInstallmentsPage = Loader(
  lazy(() => import('@/pages/ProviderInstallments'))
);

// Collateral

const CollateralsListPage = Loader(
  lazy(() => import('@/pages/Admin/Collateral/List'))
);

const CreditTransferHistoryPage = Loader(
  lazy(() => import('@/pages/Admin/Collateral/TransferHistory'))
);

// Club

const AdminClubPage = Loader(lazy(() => import('@/pages/Admin/Club')));

// User

const ProfilePage = Loader(lazy(() => import('@/pages/Profile')));

const ProfileWizardPage = Loader(
  lazy(() => import('@/pages/Profile/ProfileWizard'))
);

const EditProfilePage = Loader(
  lazy(() => import('@/components/ProfileCompletion/EditProfile'))
);

const WalletPage = Loader(lazy(() => import('@/pages/Wallet')));

// const CollateralPage = Loader(lazy(() => import('@/pages/Collateral')));

const CollateralConfigsPage = Loader(
  lazy(() => import('@/pages/Admin/CollateralConfigs'))
);

// const UserTransferHistoryPage = Loader(
//   lazy(() => import('@/pages/Collateral/UserTransferHistory'))
// );

const PaymentMethodsPage = Loader(
  lazy(() => import('@/pages/Wallet/PaymentMethods'))
);

const UserTransactionsListPage = Loader(
  lazy(() => import('@/pages/Wallet/Transactions'))
);

const InstallmentList = Loader(lazy(() => import('@/pages/Installment/List')));

const InvoiceList = Loader(lazy(() => import('@/pages/Invoice/List')));

const AddInvoice = Loader(lazy(() => import('@/pages/Invoice/Add')));

const ProviderInvoices = Loader(lazy(() => import('@/pages/ProviderInvoices')));

const InvoiceDetailsPage = Loader(lazy(() => import('@/pages/InvoiceDetails')));

// Invest Levels

const InvestLevelList = Loader(
  lazy(() => import('@/pages/Admin/InvestLevel/List'))
);

const InvestmentConfigs = Loader(
  lazy(() => import('@/pages/Admin/InvestmentConfigs'))
);

const WalletSetting = Loader(lazy(() => import('@/pages/Admin/WalletSetting')));

// Gateway

const GatewayLogin = Loader(lazy(() => import('@/pages/Gateway/Login')));

const GatewayPolicies = Loader(lazy(() => import('@/pages/Gateway/Policies')));

const GatewayLoanRequest = Loader(
  lazy(() => import('@/pages/Gateway/LoanRequest'))
);

const GatewayPayments = Loader(lazy(() => import('@/pages/Gateway/Payments')));

const GatewayPaymentMethods = Loader(
  lazy(() => import('@/pages/Gateway/PaymentMethods'))
);

const GatewayReceipt = Loader(lazy(() => import('@/pages/Gateway/Receipt')));

// Club

const UserClubPage = Loader(lazy(() => import('@/pages/Club')));

// Notifications

const NotificationSettingPage = Loader(
  lazy(() => import('@/pages/Admin/Notification/Settings'))
);

const SendNotificationPage = Loader(
  lazy(() => import('@/pages/Admin/Notification/Send'))
);

// Routes
const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to={constants.REDIRECT_AFTER_LOGIN} replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: 'auth',
        element: <AuthLayout />,
        children: [
          {
            path: '',
            element: <Navigate to="login" replace />
          },
          {
            path: 'register',
            element: <RegisterPage />
          },
          {
            path: 'login',
            element: <LoginPage />
          },
          {
            path: 'forgot-password',
            element: <ForgotPasswordPage />
          },
          {
            path: 'change-password',
            element: <ForceChangePasswordPage />
          }
        ]
      },
      {
        path: '',
        element: <SidebarLayout />,
        children: [
          {
            path: 'account',
            children: [
              {
                path: '',
                element: <ProfilePage />
              },
              {
                path: 'wizard',
                element: <ProfileWizardPage />
              },
              {
                path: 'edit',
                element: <EditProfilePage />
              }
            ]
          },
          {
            path: 'dashboard',
            children: [
              {
                path: '',
                element: <DashboardPage />
              }
            ]
          },
          //   Wallet
          {
            path: 'wallet',
            children: [
              {
                path: '',
                element: <Navigate to="my-wallet" replace />
              },
              {
                path: 'my-wallet',
                element: <WalletPage />
              },
              {
                path: 'transactions',
                element: <UserTransactionsListPage />
              },
              {
                path: 'payment-methods',
                element: <PaymentMethodsPage />
              }
            ]
          },
          {
            path: 'investment',
            children: [
              {
                path: '',
                element: <Navigate to="investment-policy" replace />
              },
              {
                path: 'investment-policy/:id',
                element: <InvestmentPolicyDetailsPage />
              },
              {
                path: 'investments_policies',
                element: <UserInvestmentsPolicies />
              },
              {
                path: 'suggested_invoices',
                element: <UserPendingInvoices />
              },
              {
                path: 'pending_invoice/:id',
                element: <UserPendingInvoiceDetailsPage />
              },
              {
                path: 'loans',
                element: <InvestorLoansPage />
              }
            ]
          },
          {
            path: 'transaction',
            children: [
              {
                path: '',
                element: <Navigate to="list" replace />
              },
              {
                path: 'list',
                element: <AdminTransactionsListPage />
              },
              {
                path: 'withdraws',
                element: <WithdrawConfirmationPage />
              }
            ]
          },
          {
            path: 'invoice',
            children: [
              {
                path: '',
                element: <Navigate to="list" replace />
              },
              {
                path: 'list',
                element: <InvoiceList />
              },
              {
                path: 'new',
                element: <AddInvoice />
              },
              {
                path: 'provider',
                element: <ProviderInvoices />
              },
              {
                path: 'details/:id',
                element: <InvoiceDetailsPage />
              }
            ]
          },
          {
            path: 'loans',
            children: [
              {
                path: '',
                element: <Navigate to="my-loans" replace />
              },
              {
                path: 'my-loans',
                element: <LoansPage />
              },
              {
                path: ':id',
                element: <LoanDetail />
              }
            ]
          },
          {
            path: 'installment',
            children: [
              {
                path: '',
                element: <Navigate to="list" replace />
              },
              {
                path: 'provider',
                element: <UnpaidInstallmentsPage />
              },
              {
                path: 'list',
                element: <InstallmentList />
              }
            ]
          },
          // {
          //   path: 'collaterals',
          //   children: [
          //     {
          //       path: '',
          //       element: <Navigate to="list" replace />
          //     },
          //     {
          //       path: 'list',
          //       element: <CollateralPage />
          //     },
          //     {
          //       path: 'transfer_history',
          //       element: <UserTransferHistoryPage />
          //     },
          //     {
          //       path: '*',
          //       element: <Navigate to="list" replace />
          //     }
          //   ]
          // },
          {
            path: 'club',
            children: [
              {
                path: '',
                element: <UserClubPage />
              },
              {
                path: '*',
                element: <Navigate to="/" replace />
              }
            ]
          },
          {
            path: 'admin',
            children: [
              {
                path: '',
                element: <Navigate to="/account" replace />
              },
              {
                path: 'user',
                children: [
                  {
                    path: '',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'list',
                    element: <UserListPage />
                  },
                  // {
                  //   path: 'new',
                  //   element: <EditAndNewUserPage />
                  // },
                  {
                    path: 'edit',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'edit/:id',
                    element: <EditAndNewUserPage />
                  },
                  {
                    path: 'view',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'view/:id',
                    element: <ViewUserPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="list" replace />
                  }
                ]
              },
              {
                path: 'role',
                children: [
                  {
                    path: '',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'list',
                    element: <RolesListPage />
                  },
                  {
                    path: 'permissions',
                    element: <Navigate to="role/list" replace />
                  },
                  {
                    path: ':id/permissions',
                    element: <PermissionsListPage />
                  },
                  {
                    path: ':id/:name/permissions',
                    element: <PermissionsListPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="list" replace />
                  }
                ]
              },
              {
                path: 'group',
                children: [
                  {
                    path: '',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'list',
                    element: <GroupsListPage />
                  },
                  {
                    path: 'new',
                    element: <AddEditGroupPage />
                  },
                  {
                    path: 'edit',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'edit/:id',
                    element: <AddEditGroupPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="list" replace />
                  }
                ]
              },
              {
                path: 'country',
                children: [
                  {
                    path: '',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'list',
                    element: <CountriesListPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="list" replace />
                  }
                ]
              },
              {
                path: 'currency',
                children: [
                  {
                    path: '',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'list',
                    element: <CurrenciesListPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="list" replace />
                  }
                ]
              },
              {
                path: 'collateral_configs',
                element: <CollateralConfigsPage />
              },
              {
                path: 'investment_configs',
                element: <InvestmentConfigs />
              },
              {
                path: 'wallet_setting',
                element: <WalletSetting />
              },
              {
                path: 'federated',
                children: [
                  {
                    path: '',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'list',
                    element: <FederatedListPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="list" replace />
                  }
                ]
              },
              {
                path: 'transaction',
                children: [
                  {
                    path: '',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'list',
                    element: <AdminTransactionsListPage />
                  },
                  {
                    path: 'withdraws',
                    element: <WithdrawConfirmationPage />
                  },
                  {
                    path: 'reconciliation',
                    element: <ReconciliationPage />
                  },
                  {
                    path: 'bank_transactions',
                    element: <BankTransactionsPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="list" replace />
                  }
                ]
              },
              {
                path: 'invoice',
                children: [
                  {
                    path: '',
                    element: <AdminInvoicesPage />
                  },
                  {
                    path: 'pending-refunds',
                    element: <PendingRefundsListPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="list" replace />
                  }
                ]
              },
              {
                path: 'investment',
                children: [
                  {
                    path: '',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'list',
                    element: <InvestmentsPage />
                  },
                  {
                    path: 'view',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'view/:id',
                    element: <ViewInvestmentPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="list" replace />
                  }
                ]
              },
              {
                path: 'policy',
                children: [
                  {
                    path: '',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'list',
                    element: <InvestmentPoliciesPage />
                  },
                  {
                    path: 'new',
                    element: <AddEditPolicyPage />
                  },
                  {
                    path: 'edit',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'edit/:id',
                    element: <AddEditPolicyPage />
                  },
                  {
                    path: 'view',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'view/:id',
                    element: <ViewInvestmentPolicyPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="list" replace />
                  }
                ]
              },
              {
                path: 'invest_level',
                children: [
                  {
                    path: '',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'list',
                    element: <InvestLevelList />
                  },
                  {
                    path: '*',
                    element: <Navigate to="list" replace />
                  }
                ]
              },
              {
                path: 'loans',
                children: [
                  {
                    path: '',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'list',
                    element: <AdminLoansListPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: ':id',
                    element: <AdminLoanDetail />
                  }
                ]
              },
              {
                path: 'installments',
                children: [
                  {
                    path: '',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'list',
                    element: <AdminInstallmentsListPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="list" replace />
                  }
                ]
              },
              {
                path: 'provider',
                children: [
                  {
                    path: '',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'list',
                    element: <ProvidersPage />
                  },
                  {
                    path: 'new',
                    element: <AddEditProviderPage />
                  },
                  {
                    path: 'view',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'view/:id',
                    element: <ViewProviderPage />
                  },
                  {
                    path: 'edit',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'edit/:id',
                    element: <AddEditProviderPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'category',
                    children: [
                      {
                        path: '',
                        element: <Navigate to="list" replace />
                      },
                      {
                        path: 'list',
                        element: <ProviderCategoriesPage />
                      },
                      {
                        path: 'new',
                        element: <AddEditProviderCategoryPage />
                      },
                      {
                        path: 'edit',
                        element: <Navigate to="list" replace />
                      },
                      {
                        path: 'edit/:id',
                        element: <AddEditProviderCategoryPage />
                      },
                      {
                        path: 'view',
                        element: <Navigate to="list" replace />
                      },
                      {
                        path: 'view/:id',
                        element: <ViewCategoryPage />
                      },
                      {
                        path: '*',
                        element: <Navigate to="list" replace />
                      }
                    ]
                  }
                ]
              },
              {
                path: 'collateral',
                children: [
                  {
                    path: '',
                    element: <Navigate to="list" replace />
                  },
                  {
                    path: 'list',
                    element: <CollateralsListPage />
                  },
                  {
                    path: 'transfer_history',
                    element: <CreditTransferHistoryPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="list" replace />
                  }
                ]
              },
              {
                path: 'notification',
                children: [
                  {
                    path: '',
                    element: <Navigate to="send" replace />
                  },
                  {
                    path: 'send',
                    element: <SendNotificationPage />
                  },
                  {
                    path: 'settings',
                    element: <NotificationSettingPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="/" replace />
                  }
                ]
              },
              {
                path: 'club',
                children: [
                  {
                    path: '',
                    element: <AdminClubPage />
                  },
                  {
                    path: '*',
                    element: <Navigate to="/" replace />
                  }
                ]
              },
              {
                path: 'settings',
                children: [
                  {
                    path: '',
                    element: <Navigate to="cache" replace />
                  },
                  {
                    path: 'cache',
                    element: <ClearSystemCachePage />
                  }
                  // {
                  //   path: 'auth_config',
                  //   element: <AuthConfigPage />
                  // }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'gateway',
    element: <GatewayLayout />,
    children: [
      {
        path: 'login/:token',
        element: <GatewayLogin />
      },
      {
        path: 'login',
        element: <GatewayLogin />
      },
      {
        path: 'policies',
        element: <GatewayPolicies />
      },
      {
        path: 'loan_request',
        element: <GatewayLoanRequest />
      },
      {
        path: 'payments',
        element: <GatewayPayments />
      },
      {
        path: 'pay',
        element: <GatewayPaymentMethods />
      },
      {
        path: 'receipt',
        element: <GatewayReceipt />
      }
    ]
  }
];

export default routes;
