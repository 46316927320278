import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translation from './en/translation.json';

export const resources = {
  en: {
    translation
  }
};

i18next.use(initReactI18next).init({
  debug: false,
  resources,
  defaultNS: 'translation',
  interpolation: {
    escapeValue: false // react already safes from xss
  },
  react: { useSuspense: true },
  compatibilityJSON: 'v3',
  returnNull: true,
  fallbackLng: 'en'
});

export default i18next;
