import { PeopleTwoTone } from '@mui/icons-material';
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { t } from 'i18next';
import { useEffect, useMemo } from 'react';

import { UserPermissionType } from '@/@types/stub/authorization_message';
import { useAppDispatch } from '@/hooks/redux';
import useApi from '@/hooks/useApi';
import { setSelectedRole } from '@/redux/slices/authSlice';
import { GetUserProfileService } from '@/services/UserService';
import HandleError from '@/utils/HandleError';

import Link from '../Link';

interface IProps {
  onRedirect: () => void;
  modalMode?: boolean;
}

interface ISelectRole {
  name: string;
  value: UserPermissionType;
  hasRole: boolean;
}

// Custom Components
const RoleItem = styled(Stack)(({ theme }) => ({
  width: '100%',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  border: `2px solid #FFB05A`,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.common.black,
  cursor: 'pointer',
  fontWeight: 500,
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  transition: 'all .5s',
  ':hover': {
    backgroundColor: theme.palette.primary.main,
    '& .MuiTypography-root': {
      color: '#fff'
    },
    '& .MuiSvgIcon-root': {
      fill: '#fff'
    }
  },
  '& .MuiTypography-root': {
    transition: 'all .5s',
    color: theme.palette.primary.main
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row'
  }
}));

function SelectRole(props: IProps) {
  // Props
  const { onRedirect, modalMode = false } = props;

  // Redux
  const dispatch = useAppDispatch();

  // API
  const GetProfileAPI = useApi(GetUserProfileService);

  // Roles
  const roles: ISelectRole[] = useMemo(
    () => [
      {
        name: t('common.admin'),
        value: UserPermissionType.Admin,
        hasRole: Boolean(GetProfileAPI.data?.data?.data?.is_admin)
      },
      {
        name: t('common.provider'),
        value: UserPermissionType.provider,
        hasRole: Boolean(GetProfileAPI.data?.data?.data?.is_provider)
      },
      {
        name: t('common.investor'),
        value: UserPermissionType.Investor,
        hasRole: Boolean(GetProfileAPI.data?.data?.data?.is_investor)
      },
      {
        name: t('common.borrower'),
        value: UserPermissionType.Borrower,
        hasRole: Boolean(GetProfileAPI.data?.data?.data?.is_borrower)
      }
    ],
    [GetProfileAPI.data]
  );

  // Active Roles Count
  const activeCount: number = useMemo(
    () => roles.filter((obj) => obj.hasRole)?.length,
    [roles]
  );

  // Function
  const GetProfile = async () => {
    try {
      await GetProfileAPI.request();
    } catch (error) {
      HandleError(error);
    }
  };

  useEffect(() => {
    GetProfile();
  }, []);

  useEffect(() => {
    if (
      (GetProfileAPI.data?.data?.data?.email ||
        GetProfileAPI.data?.data?.data?.phone_number) &&
      !modalMode
    ) {
      if (activeCount === 1) {
        const activeRole: ISelectRole = roles.find((obj) => obj.hasRole);
        dispatch(setSelectedRole(activeRole.value));
        onRedirect();
      } else if (activeCount === 0) {
        dispatch(setSelectedRole(null));
        onRedirect();
      }
    }
  }, [roles]);

  return (
    <Stack justifyContent="center" alignItems="center" height="100%" mt={3}>
      {GetProfileAPI.loading ? (
        <CircularProgress />
      ) : (
        <>
          {!modalMode && (
            <Typography fontSize="18px" textAlign="center" variant="h2">
              Continue as
            </Typography>
          )}

          <Grid
            container
            mt={modalMode ? 0 : 2}
            spacing={3}
            justifyContent="center"
          >
            {activeCount === 0 ? (
              <Grid item xs={12}>
                <Alert severity="info">{t('common.no_role_msg')}</Alert>
                <Link to="/account">
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    sx={{ fontWeight: 'normal' }}
                  >
                    {t('common.go_to_profile')}
                  </Button>
                </Link>
              </Grid>
            ) : (
              roles.map((role) => {
                if (role?.hasRole) {
                  return (
                    <RoleContainer
                      onClick={() => {
                        dispatch(setSelectedRole(role?.value));
                        onRedirect();
                      }}
                      title={role?.name}
                      key={role?.name}
                    />
                  );
                }
              })
            )}
          </Grid>
        </>
      )}
    </Stack>
  );
}

export default SelectRole;

const RoleContainer = ({ title, onClick }) => {
  return (
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      <RoleItem onClick={onClick}>
        <PeopleTwoTone color="primary" />
        <Typography>{title}</Typography>
      </RoleItem>
    </Grid>
  );
};
