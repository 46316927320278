/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Struct } from "./google/protobuf/struct";

export const protobufPackage = "authorization";

export enum UserPermissionType {
  Borrower = 0,
  Investor = 1,
  provider = 2,
  Admin = 3,
  UNRECOGNIZED = -1,
}

export function userPermissionTypeFromJSON(object: any): UserPermissionType {
  switch (object) {
    case 0:
    case "Borrower":
      return UserPermissionType.Borrower;
    case 1:
    case "Investor":
      return UserPermissionType.Investor;
    case 2:
    case "provider":
      return UserPermissionType.provider;
    case 3:
    case "Admin":
      return UserPermissionType.Admin;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserPermissionType.UNRECOGNIZED;
  }
}

export function userPermissionTypeToJSON(object: UserPermissionType): string {
  switch (object) {
    case UserPermissionType.Borrower:
      return "Borrower";
    case UserPermissionType.Investor:
      return "Investor";
    case UserPermissionType.provider:
      return "provider";
    case UserPermissionType.Admin:
      return "Admin";
    case UserPermissionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum IncreaseInvitationPointKey {
  FirstEftDepositPoint = 0,
  FirstLoanPoint = 1,
  FirstInvest = 2,
  OtherInvest = 3,
  UNRECOGNIZED = -1,
}

export function increaseInvitationPointKeyFromJSON(object: any): IncreaseInvitationPointKey {
  switch (object) {
    case 0:
    case "FirstEftDepositPoint":
      return IncreaseInvitationPointKey.FirstEftDepositPoint;
    case 1:
    case "FirstLoanPoint":
      return IncreaseInvitationPointKey.FirstLoanPoint;
    case 2:
    case "FirstInvest":
      return IncreaseInvitationPointKey.FirstInvest;
    case 3:
    case "OtherInvest":
      return IncreaseInvitationPointKey.OtherInvest;
    case -1:
    case "UNRECOGNIZED":
    default:
      return IncreaseInvitationPointKey.UNRECOGNIZED;
  }
}

export function increaseInvitationPointKeyToJSON(object: IncreaseInvitationPointKey): string {
  switch (object) {
    case IncreaseInvitationPointKey.FirstEftDepositPoint:
      return "FirstEftDepositPoint";
    case IncreaseInvitationPointKey.FirstLoanPoint:
      return "FirstLoanPoint";
    case IncreaseInvitationPointKey.FirstInvest:
      return "FirstInvest";
    case IncreaseInvitationPointKey.OtherInvest:
      return "OtherInvest";
    case IncreaseInvitationPointKey.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface InternalInitRoutesReq {
  routes: InternalRoute[];
}

export interface GetUserBySessionIdReq {
  session_id: string;
}

export interface InternalRoute {
  path: string;
  method: string;
  handler: string;
}

export interface InternalGetUserTimeZoneReq {
  user_id: number;
}

export interface InternalGetUserTimeZoneRes {
  user_id: number;
  time_zone_name: string;
  time_zone_offset: string;
}

/** Auth */
export interface User {
  user_id: number;
  username: string;
  roles: number[];
  is_active: boolean;
  sub: string;
  invitation_point: number;
}

export interface InternalIAM {
  user: User | undefined;
  access_token: string;
}

export interface CheckIAMReq {
  access_token: string;
  route: string;
  method: string;
}

export interface GetTokenByRefreshTokenRes {
  access_token: string;
}

export interface GetAllUsersReq {
  phone_number?: string | undefined;
  email?: string | undefined;
}

export interface GetAllUsersRes {
  users: GetAllUsers[];
}

export interface GetAllUsers {
  user_id: number;
  first_name: string;
  last_name: string;
}

/** Role Permission */
export interface Role {
  id: number;
  name: string;
  description: string;
  created_at: string;
}

export interface Permission {
  id: number;
  name: string;
  code: string;
  route: string;
  method: string;
}

export interface AdminGetUserPermissionReq {
  user_id: number;
}

export interface GetUserPermissionReq {
  iam: InternalIAM | undefined;
  user_type?: UserPermissionType | undefined;
}

export interface GetUserSpecialRolesReq {
  iam: InternalIAM | undefined;
}

export interface AdminGetUserSpecialRolesReq {
  user_id: number;
}

export interface GetUserSpecialRolesRes {
  roles: Role[];
}

export interface AdminGetUserRolesReq {
  user_id: number;
}

export interface AdminGetUserRolesRes {
  roles: Role[];
}

export interface AdminUpdateUserRolesReq {
  user_id: number;
  role_ids: number[];
}

export interface GetUserPermissionRes {
  permissions: Permission[];
  roles: Role[];
}

export interface AddRoleToUserReq {
  user_id: number;
  role_id: number;
}

export interface UpdatePermissionReq {
  id: number;
  name: string;
}

export interface AdminCreateRoleReq {
  name: string;
  description: string;
}

export interface AdminUpdateRoleReq {
  id: number;
  name: string;
  description: string;
}

export interface AdminDeleteRoleReq {
  role_id: number;
}

export interface AdminGetAllRolesWithFilterReq {
  type?: string | undefined;
}

export interface AdminGetAllRolesRes {
  roles: Role[];
}

export interface AdminGetAllPermissionsRes {
  permission: adminAllPermission[];
}

export interface adminAllPermissionMethod {
  id: number;
  method: string;
}

export interface adminAllPermission {
  name: string;
  route: string;
  methods: adminAllPermissionMethod[];
}

export interface AdminAssignRolePermissionReq {
  role_id: number;
  permissions_id: number[];
}

export interface AdminGetPermissionsOfRoleReq {
  role_id: number;
}

export interface AdminGetPermissionsOfRoleRes {
  permissions: Permission[];
}

/** SignUp */
export interface SignUpReq {
  country_id: number;
  phone_number: string;
  password: string;
}

export interface ConfirmSignUpReq {
  phone_number: string;
  password: string;
  otp_code: string;
}

export interface ResendConfirmationCodeReq {
  phone_number: string;
}

/** SignIn */
export interface SignInReq {
  phone_number: string;
  password: string;
  temporary_login: boolean;
}

export interface SignInRes {
  access_token: string;
  refresh_token: string;
  unconfirmed: boolean;
  mfa: boolean;
  profile_completion_necessary: boolean;
  address_completion_necessary: boolean;
  session: string;
  forceChangePassword: boolean;
  challengeName: string;
  sso_session_id?: string | undefined;
}

export interface RespondToAuthChallengeReq {
  username: string;
  code: string;
  session: string;
  challengeName: string;
}

/** FederatedSignIn */
export interface FederatedItem {
  id: number;
  provider: string;
  url: string;
  platform: string[];
  isActive: boolean;
  logout_url: string;
}

export interface GetFederatedListRes {
  list: FederatedItem[];
}

export interface ValidateFederatedCodeReq {
  code: string;
}

/** ForgotPassword */
export interface ForgotPasswordReq {
  phone_number: string;
}

export interface ChangeUserPasswordReq {
  iam: InternalIAM | undefined;
  current_password: string;
  new_password: string;
}

export interface ConfirmForgotPasswordReq {
  phone_number: string;
  confirmation_code: string;
  newPassword: string;
}

/** UpdateProfile */
export interface BasicUserInfo {
  userId: number;
  first_name: string;
  last_name: string;
}

export interface GetAllUserProfile {
  first_name: string;
  last_name: string;
  country: string;
  email: string;
  phone_number: string;
}

export interface ProfileRes {
  first_name: string;
  last_name: string;
  birthdate: string;
  suffix?: string | undefined;
  email?: string | undefined;
  phone_number: string;
  email_verified: boolean;
  phone_number_verified: boolean;
  is_federated: boolean;
  invitation_point: number;
  sin?: string | undefined;
  profile_image?: string | undefined;
  profile_verification: ProfileVerificationRes | undefined;
  can_be_provider: boolean;
  can_be_investor: boolean;
  can_be_borrower: boolean;
  is_provider: boolean;
  is_investor: boolean;
  is_borrower: boolean;
  is_admin: boolean;
  user_id: number;
  total_invitation_point: number;
}

export interface ProfileVerificationRes {
  birthdate_status: string;
  phone_number_status: string;
  name_status: string;
  id_number_status: string;
}

export interface ProfileReq {
  first_name: string;
  last_name: string;
  birthdate: string;
  suffix?: string | undefined;
  email?: string | undefined;
  email_verified?: boolean | undefined;
  phone_number_verified?: boolean | undefined;
  sin?: string | undefined;
  profile_image?: string | undefined;
}

export interface UpdateProfileReq {
  iam: InternalIAM | undefined;
  first_name: string;
  last_name: string;
  birthdate: string;
  suffix?: string | undefined;
  isRegister?: boolean | undefined;
  sin: string;
  profile_image: string;
}

export interface UpdatePhoneNumberReq {
  iam: InternalIAM | undefined;
  phone_number: string;
}

export interface VerifyPhoneNumberReq {
  iam: InternalIAM | undefined;
  code: string;
  phone_number: string;
}

export interface UpdateEmailReq {
  iam: InternalIAM | undefined;
  email: string;
}

export interface VerifyEmailReq {
  iam: InternalIAM | undefined;
  code: string;
  email: string;
}

export interface AddressRes {
  home_address_1: string;
  home_address_2?: string | undefined;
  city: string;
  state: State | undefined;
  zip: string;
  country?: CountryForAddress | undefined;
  verification_status: AddressVerificationRes | undefined;
}

export interface AddressVerificationRes {
  address_status: string;
}

export interface UpdateAddressReq {
  iam: InternalIAM | undefined;
  home_address_1: string;
  home_address_2?: string | undefined;
  city: string;
  state: string;
  zip: string;
  country_id?: number | undefined;
}

export interface SetUserMFAPreferenceReq {
  iam: InternalIAM | undefined;
  enable_sms?: boolean | undefined;
  preferred_sms?: boolean | undefined;
  enable_software_token?: boolean | undefined;
  preferred_software_token?: boolean | undefined;
}

export interface GetUserMFASettingReq {
  iam: InternalIAM | undefined;
}

export interface GetUserMFASettingRes {
  enable_sms: boolean;
  preferred_sms: boolean;
  enable_software_token: boolean;
  preferred_software_token: boolean;
}

export interface AssociateSoftwareTokenReq {
  iam: InternalIAM | undefined;
}

export interface AssociateSoftwareTokenRes {
  secret_code: string;
}

export interface VerifySoftwareTokenReq {
  iam: InternalIAM | undefined;
  code: string;
}

export interface VerifySoftwareTokenRes {
  success: boolean;
}

export interface UserCompletionStatusRes {
  profile_completion_necessary: boolean;
  address_completion_necessary: boolean;
  unconfirmed: boolean;
}

export interface SignOutReq {
  refresh_token: string;
}

/** Admin */
export interface AdminGetListUsersReq {
  page_index?: number | undefined;
  page_size?: number | undefined;
  email?: string | undefined;
  country_id?: number | undefined;
  phone_number?: string | undefined;
  first_name?: string | undefined;
  last_name?: string | undefined;
  email_verified?: boolean | undefined;
  phone_number_verified?: boolean | undefined;
  full_name?: string | undefined;
}

export interface AdminUsersRes {
  users: GetAllUserInfo[];
  total_counts: number;
}

export interface GetAllUserInfo {
  user_id: number;
  username: string;
  profile_info: GetAllUserProfile | undefined;
  create_date: string;
  user_last_modified_date: string;
  enable: boolean;
  user_status: string;
  role_ids: number[];
  invitation_point: number;
}

export interface UserInfo {
  user_id: number;
  username: string;
  profile_info: ProfileRes | undefined;
  address_info: AddressRes | undefined;
  create_date: string;
  user_last_modified_date: string;
  enable: boolean;
  user_status: string;
  mFA_options_type: AdminMFAOptionsType[];
  role_ids: number[];
  user_sub: string;
  invitation_point: number;
}

export interface AdminUpdateUserProfileReq {
  username: string;
  profile: ProfileRes | undefined;
  address: UpdateAddressReq | undefined;
  role_ids: number[];
}

export interface AdminMFAOptionsType {
  delivery_medium: string;
  attribute_name: string;
}

export interface AdminGetByUserNameReq {
  username: string;
}

export interface AdminGetUserReq {
  username?: string | undefined;
  id?: number | undefined;
}

export interface AdminDisableUserReq {
  username: string;
}

export interface AdminEnableUserReq {
  username: string;
}

export interface AdminConfirmUserSignUpReq {
  username: string;
}

export interface AdminCreateUserReq {
  phone_number: string;
  temporary_password: string;
  profile: ProfileReq | undefined;
  address: UpdateAddressReq | undefined;
}

export interface AdminCreateUserRes {
  username: string;
  first_name: string;
  last_name: string;
  birthday_date: string;
  email?: string | undefined;
  suffix?: string | undefined;
  create_date: string;
  user_last_modified_date: string;
  enable: boolean;
  user_status: string;
  country?: string | undefined;
}

export interface AdminCreateGroupReq {
  name: string;
  description?: string | undefined;
}

export interface AdminCreateGroupRes {
  name: string;
  description: string;
  create_date: string;
  last_modified_date: string;
}

export interface AdminUpdateGroupReq {
  name: string;
  description?: string | undefined;
}

export interface AdminUpdateGroupRes {
  name: string;
  description: string;
  create_date: string;
  last_modified_date: string;
}

export interface AdminGetGroupReq {
  name: string;
}

export interface AdminGetGroupRes {
  name: string;
  description: string;
  create_date: string;
  last_modified_date: string;
}

export interface AdminGetListGroupsReq {
  limit: number;
  next_token?: string | undefined;
}

export interface AdminGetListGroupsRes {
  groups: AdminGroup[];
  next_token?: string | undefined;
}

export interface AdminGroup {
  name?: string | undefined;
  description?: string | undefined;
  create_date?: string | undefined;
  last_modified_date?: string | undefined;
}

export interface AdminDeleteGroupReq {
  name: string;
}

export interface AdminAddUserToGroupReq {
  username: string;
  group_name: string;
}

export interface AdminRemoveUserFromGroupReq {
  username: string;
  group_name: string;
}

export interface AdminResetUserPasswordReq {
  username: string;
}

export interface AdminSetUserPasswordReq {
  username: string;
  password: string;
}

export interface AdminNewTokenByRefreshTokenReq {
  refreshToken: string;
}

export interface AdminGetTokenByRefreshTokenRes {
  access_token: string;
  refresh_token: string;
  mfa_needed: boolean;
}

export interface AdminGlobalSignOutReq {
  username: string;
}

export interface AdminSetUserMFAPreferenceReq {
  username: string;
  enable_sms?: boolean | undefined;
  enable_software_token?: boolean | undefined;
}

export interface AdminGetUserMFASettingReq {
  username: string;
}

/** Country */
export interface AdminCreateCountryReq {
  code: string;
}

export interface AdminUpdateCountryReq {
  id: number;
  enable: boolean;
}

export interface AdminDeleteCountryReq {
  id: number;
}

export interface AdminGetCountryByIdReq {
  id: number;
}

export interface AdminGetAllCountriesReq {
  enable?: boolean | undefined;
}

export interface GetAllCountriesRes {
  countries: Country[];
}

export interface GetTotalCountriesRes {
  countries: SystemCountry[];
}

export interface SystemCountry {
  name: string;
  code: string;
  flag: string;
  tel_number: string;
}

export interface Country {
  id: number;
  name: string;
  code: string;
  flag: string;
  create_at: string;
  tel_number: string;
  update_at?: string | undefined;
  enable: boolean;
}

export interface GetAllStatesOfCountryReq {
  country_code: string;
}

export interface GetAllStatesOfCountryRes {
  states: State[];
}

export interface State {
  name: string;
  code: string;
}

export interface CountryForAddress {
  id: number;
  name: string;
  code: string;
}

export interface GetAllCitiesOfCountryAndStateReq {
  country_code: string;
  state_code: string;
}

export interface GetAllCitiesOfCountryAndStateRes {
  cities: string[];
}

/** walletNeeded */
export interface GetUserInfoByIdReq {
  id: number;
}

export interface GetCountryByCodeReq {
  code: string;
}

/** userSsoSession */
export interface InternalCheckUserSsoSessionExpiredReq {
  sessionId: string;
}

export interface InternalCheckUserSsoSessionExpiredRes {
  exist: boolean;
}

/** group */
export interface AdminCreateUserGroupReq {
  title: string;
  description?: string | undefined;
  user_ids: number[];
}

export interface AdminUpdateUserGroupReq {
  id: number;
  title: string;
  description?: string | undefined;
}

export interface AdminAssignUserToGroupReq {
  group_id: number;
  user_id: number;
}

export interface AdminAssignListUsersToGroupReq {
  group_id: number;
  user_ids: number[];
}

export interface AdminDeleteUserFromGroupReq {
  group_id: number;
  user_id: number;
}

export interface AdminGetAllUserGroupsRes {
  userGroups: BasicUserGroup[];
}

export interface BasicUserGroup {
  id: number;
  title: string;
  description?: string | undefined;
  created_at: string;
  updated_at?: string | undefined;
  type: number;
}

export interface UserGroup {
  id: number;
  title: string;
  description?: string | undefined;
  users: BasicUserInfoForGroup[];
  created_at: string;
  updated_at?: string | undefined;
  type: number;
}

export interface BasicUserInfoForGroup {
  user_id: number;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
}

export interface InternalGetListGroupsByUserIdReq {
  user_id: number;
}

export interface InternalGetListGroupsByUserIdRes {
  group_ids: number[];
  list: BasicUserGroup[];
}

/** provider */
export interface ProviderGetUserProfileReq {
  phone_number: string;
}

export interface ProviderGetUserProfileRes {
  user_id?: number | undefined;
  first_name: string;
  last_name: string;
  birthdate: string;
  suffix?: string | undefined;
  email?: string | undefined;
  phone_number: string;
  email_verified: boolean;
  phone_number_verified: boolean;
}

export interface ProviderCreateUserReq {
  phone_number: string;
}

export interface InternalGetAllUserIdsByGroupIdRes {
  user_ids: number[];
}

export interface UserAssignInvitationCodeReq {
  iam: InternalIAM | undefined;
  invitation_code: string;
}

export interface InvitationPointConfigs {
  list: InvitationPointConfig[];
}

export interface InvitationPointConfig {
  id: number;
  key: string;
  desc: string;
  fixed_point: number;
  fixed_percentage: number;
  primary_percentage: number;
  father_L1_percentage: number;
  father_L2_percentage: number;
}

export interface UserInvitationList {
  l1: UserInvitationItem[];
  l2: UserInvitationItem[];
}

export interface UserInvitationItem {
  info: BasicUserInfo | undefined;
  created_at: string;
  children: UserInvitationItem[];
}

export interface UserInvitationCodeList {
  list: UserInvitationCodeItem[];
}

export interface UserInvitationCodeItem {
  id: number;
  invitation_code: string;
  status: number;
  created_at: string;
  l1_sum: number;
  l2_sum: number;
}

export interface UserDeleteInvitationCodeReq {
  iam: InternalIAM | undefined;
  invitation_id: number;
}

export interface UserGetInvitationListByInvitationCodeReq {
  iam: InternalIAM | undefined;
  invitation_id: number;
}

export interface AdminUpdateConfigMapsReq {
  list: AdminUpdateConfigMapReq[];
}

export interface AdminUpdateConfigMapReq {
  key: string;
  value: string;
}

export interface AdminGetAllConfigMapsRes {
  config_maps: ConfigMap[];
}

export interface ConfigMap {
  id: number;
  key: string;
  description: string;
  value: string;
  unit: string;
}

export interface UserPointExchangeReq {
  iam: InternalIAM | undefined;
  point: number;
}

export interface IncreasePointReq {
  user_id: number;
  key: IncreaseInvitationPointKey;
  amount: number;
}

/** Marketer exchnange rate */
export interface AdminCreateMarketerExchangeRateReq {
  user_id: number;
  exchange_rate: number;
  status: number;
}

export interface AdminUpdateMarketerExchangeRateReq {
  id: number;
  exchange_rate: number;
  status: number;
}

export interface MarketerExchangeRate {
  id: number;
  user: BasicUserInfo | undefined;
  exchange_rate: number;
  status: number;
}

export interface AdminGetAllMarketerExchangeRatesRes {
  list: MarketerExchangeRate[];
}

export interface UserVerificationListReq {
  iam: InternalIAM | undefined;
}

export interface UserVerificationListRes {
  list: UserVerificationItem[];
}

export interface UserVerificationItem {
  id: number;
  tracking_code: string;
  response_string: string;
  provider: number;
  type: number;
  verified: boolean;
  updated_at: string;
  created_at: string;
  request_string: string;
}

export interface UserVerificationReq {
  iam: InternalIAM | undefined;
}

export interface UserVerificationRes {
  id: number;
  provider: number;
  updated_at: string;
  created_at: string;
  status: number;
  shareable_url: string;
  profile_verification: ProfileVerificationRes | undefined;
  address_status: AddressVerificationRes | undefined;
  document_verification: DocumentVerification | undefined;
}

export interface DocumentVerification {
  dirver_licance: DocumentVerificationDirverLicanse | undefined;
}

export interface DocumentVerificationDirverLicanse {
  status: string;
  birthdate_status: string;
  expiration_date_status: string;
  issuing_country_status: string;
  name_status: string;
}

export interface SubmitUserVerificationReq {
  iam: InternalIAM | undefined;
}

export interface UsereSubmitSpecialRoleReq {
  iam: InternalIAM | undefined;
  special_role: number;
}

export interface PlaidIdentityVerificationWebhook {
  Data: { [key: string]: any } | undefined;
}

export interface UserUpdateExtraInformationReq {
  iam: InternalIAM | undefined;
  employment_title: string;
  employment_length: string;
  home_ownership: string;
  annual_income: string;
  gender: string;
  marital_status: string;
  dependents: string;
  educational_degree: string;
}

export interface UserExtraInformationRes {
  user_id: number;
  employment_title: string;
  employment_length: string;
  home_ownership: string;
  annual_income: string;
  gender: string;
  marital_status: string;
  dependents: string;
  educational_degree: string;
}

/** Analytics */
export interface AdminAnalyticsGetTotalUsersCountRes {
  total_user_count: number;
  total_borrower_count: number;
  total_investor_count: number;
  total_provider_count: number;
}

function createBaseInternalInitRoutesReq(): InternalInitRoutesReq {
  return { routes: [] };
}

export const InternalInitRoutesReq = {
  encode(message: InternalInitRoutesReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.routes) {
      InternalRoute.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InternalInitRoutesReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInternalInitRoutesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.routes.push(InternalRoute.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InternalInitRoutesReq {
    return { routes: Array.isArray(object?.routes) ? object.routes.map((e: any) => InternalRoute.fromJSON(e)) : [] };
  },

  toJSON(message: InternalInitRoutesReq): unknown {
    const obj: any = {};
    if (message.routes) {
      obj.routes = message.routes.map((e) => e ? InternalRoute.toJSON(e) : undefined);
    } else {
      obj.routes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InternalInitRoutesReq>, I>>(base?: I): InternalInitRoutesReq {
    return InternalInitRoutesReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InternalInitRoutesReq>, I>>(object: I): InternalInitRoutesReq {
    const message = createBaseInternalInitRoutesReq();
    message.routes = object.routes?.map((e) => InternalRoute.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetUserBySessionIdReq(): GetUserBySessionIdReq {
  return { session_id: "" };
}

export const GetUserBySessionIdReq = {
  encode(message: GetUserBySessionIdReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.session_id !== "") {
      writer.uint32(10).string(message.session_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserBySessionIdReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserBySessionIdReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.session_id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserBySessionIdReq {
    return { session_id: isSet(object.session_id) ? String(object.session_id) : "" };
  },

  toJSON(message: GetUserBySessionIdReq): unknown {
    const obj: any = {};
    message.session_id !== undefined && (obj.session_id = message.session_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserBySessionIdReq>, I>>(base?: I): GetUserBySessionIdReq {
    return GetUserBySessionIdReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetUserBySessionIdReq>, I>>(object: I): GetUserBySessionIdReq {
    const message = createBaseGetUserBySessionIdReq();
    message.session_id = object.session_id ?? "";
    return message;
  },
};

function createBaseInternalRoute(): InternalRoute {
  return { path: "", method: "", handler: "" };
}

export const InternalRoute = {
  encode(message: InternalRoute, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.path !== "") {
      writer.uint32(10).string(message.path);
    }
    if (message.method !== "") {
      writer.uint32(18).string(message.method);
    }
    if (message.handler !== "") {
      writer.uint32(26).string(message.handler);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InternalRoute {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInternalRoute();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.path = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.method = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.handler = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InternalRoute {
    return {
      path: isSet(object.path) ? String(object.path) : "",
      method: isSet(object.method) ? String(object.method) : "",
      handler: isSet(object.handler) ? String(object.handler) : "",
    };
  },

  toJSON(message: InternalRoute): unknown {
    const obj: any = {};
    message.path !== undefined && (obj.path = message.path);
    message.method !== undefined && (obj.method = message.method);
    message.handler !== undefined && (obj.handler = message.handler);
    return obj;
  },

  create<I extends Exact<DeepPartial<InternalRoute>, I>>(base?: I): InternalRoute {
    return InternalRoute.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InternalRoute>, I>>(object: I): InternalRoute {
    const message = createBaseInternalRoute();
    message.path = object.path ?? "";
    message.method = object.method ?? "";
    message.handler = object.handler ?? "";
    return message;
  },
};

function createBaseInternalGetUserTimeZoneReq(): InternalGetUserTimeZoneReq {
  return { user_id: 0 };
}

export const InternalGetUserTimeZoneReq = {
  encode(message: InternalGetUserTimeZoneReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InternalGetUserTimeZoneReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInternalGetUserTimeZoneReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InternalGetUserTimeZoneReq {
    return { user_id: isSet(object.user_id) ? Number(object.user_id) : 0 };
  },

  toJSON(message: InternalGetUserTimeZoneReq): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    return obj;
  },

  create<I extends Exact<DeepPartial<InternalGetUserTimeZoneReq>, I>>(base?: I): InternalGetUserTimeZoneReq {
    return InternalGetUserTimeZoneReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InternalGetUserTimeZoneReq>, I>>(object: I): InternalGetUserTimeZoneReq {
    const message = createBaseInternalGetUserTimeZoneReq();
    message.user_id = object.user_id ?? 0;
    return message;
  },
};

function createBaseInternalGetUserTimeZoneRes(): InternalGetUserTimeZoneRes {
  return { user_id: 0, time_zone_name: "", time_zone_offset: "" };
}

export const InternalGetUserTimeZoneRes = {
  encode(message: InternalGetUserTimeZoneRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    if (message.time_zone_name !== "") {
      writer.uint32(18).string(message.time_zone_name);
    }
    if (message.time_zone_offset !== "") {
      writer.uint32(26).string(message.time_zone_offset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InternalGetUserTimeZoneRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInternalGetUserTimeZoneRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.time_zone_name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.time_zone_offset = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InternalGetUserTimeZoneRes {
    return {
      user_id: isSet(object.user_id) ? Number(object.user_id) : 0,
      time_zone_name: isSet(object.time_zone_name) ? String(object.time_zone_name) : "",
      time_zone_offset: isSet(object.time_zone_offset) ? String(object.time_zone_offset) : "",
    };
  },

  toJSON(message: InternalGetUserTimeZoneRes): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    message.time_zone_name !== undefined && (obj.time_zone_name = message.time_zone_name);
    message.time_zone_offset !== undefined && (obj.time_zone_offset = message.time_zone_offset);
    return obj;
  },

  create<I extends Exact<DeepPartial<InternalGetUserTimeZoneRes>, I>>(base?: I): InternalGetUserTimeZoneRes {
    return InternalGetUserTimeZoneRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InternalGetUserTimeZoneRes>, I>>(object: I): InternalGetUserTimeZoneRes {
    const message = createBaseInternalGetUserTimeZoneRes();
    message.user_id = object.user_id ?? 0;
    message.time_zone_name = object.time_zone_name ?? "";
    message.time_zone_offset = object.time_zone_offset ?? "";
    return message;
  },
};

function createBaseUser(): User {
  return { user_id: 0, username: "", roles: [], is_active: false, sub: "", invitation_point: 0 };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    if (message.username !== "") {
      writer.uint32(18).string(message.username);
    }
    writer.uint32(26).fork();
    for (const v of message.roles) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.is_active === true) {
      writer.uint32(32).bool(message.is_active);
    }
    if (message.sub !== "") {
      writer.uint32(42).string(message.sub);
    }
    if (message.invitation_point !== 0) {
      writer.uint32(48).int64(message.invitation_point);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.username = reader.string();
          continue;
        case 3:
          if (tag === 24) {
            message.roles.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.is_active = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sub = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.invitation_point = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      user_id: isSet(object.user_id) ? Number(object.user_id) : 0,
      username: isSet(object.username) ? String(object.username) : "",
      roles: Array.isArray(object?.roles) ? object.roles.map((e: any) => Number(e)) : [],
      is_active: isSet(object.is_active) ? Boolean(object.is_active) : false,
      sub: isSet(object.sub) ? String(object.sub) : "",
      invitation_point: isSet(object.invitation_point) ? Number(object.invitation_point) : 0,
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    message.username !== undefined && (obj.username = message.username);
    if (message.roles) {
      obj.roles = message.roles.map((e) => Math.round(e));
    } else {
      obj.roles = [];
    }
    message.is_active !== undefined && (obj.is_active = message.is_active);
    message.sub !== undefined && (obj.sub = message.sub);
    message.invitation_point !== undefined && (obj.invitation_point = Math.round(message.invitation_point));
    return obj;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.user_id = object.user_id ?? 0;
    message.username = object.username ?? "";
    message.roles = object.roles?.map((e) => e) || [];
    message.is_active = object.is_active ?? false;
    message.sub = object.sub ?? "";
    message.invitation_point = object.invitation_point ?? 0;
    return message;
  },
};

function createBaseInternalIAM(): InternalIAM {
  return { user: undefined, access_token: "" };
}

export const InternalIAM = {
  encode(message: InternalIAM, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.access_token !== "") {
      writer.uint32(18).string(message.access_token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InternalIAM {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInternalIAM();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.access_token = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InternalIAM {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      access_token: isSet(object.access_token) ? String(object.access_token) : "",
    };
  },

  toJSON(message: InternalIAM): unknown {
    const obj: any = {};
    message.user !== undefined && (obj.user = message.user ? User.toJSON(message.user) : undefined);
    message.access_token !== undefined && (obj.access_token = message.access_token);
    return obj;
  },

  create<I extends Exact<DeepPartial<InternalIAM>, I>>(base?: I): InternalIAM {
    return InternalIAM.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InternalIAM>, I>>(object: I): InternalIAM {
    const message = createBaseInternalIAM();
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.access_token = object.access_token ?? "";
    return message;
  },
};

function createBaseCheckIAMReq(): CheckIAMReq {
  return { access_token: "", route: "", method: "" };
}

export const CheckIAMReq = {
  encode(message: CheckIAMReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.access_token !== "") {
      writer.uint32(10).string(message.access_token);
    }
    if (message.route !== "") {
      writer.uint32(18).string(message.route);
    }
    if (message.method !== "") {
      writer.uint32(26).string(message.method);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CheckIAMReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckIAMReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.access_token = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.route = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.method = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CheckIAMReq {
    return {
      access_token: isSet(object.access_token) ? String(object.access_token) : "",
      route: isSet(object.route) ? String(object.route) : "",
      method: isSet(object.method) ? String(object.method) : "",
    };
  },

  toJSON(message: CheckIAMReq): unknown {
    const obj: any = {};
    message.access_token !== undefined && (obj.access_token = message.access_token);
    message.route !== undefined && (obj.route = message.route);
    message.method !== undefined && (obj.method = message.method);
    return obj;
  },

  create<I extends Exact<DeepPartial<CheckIAMReq>, I>>(base?: I): CheckIAMReq {
    return CheckIAMReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CheckIAMReq>, I>>(object: I): CheckIAMReq {
    const message = createBaseCheckIAMReq();
    message.access_token = object.access_token ?? "";
    message.route = object.route ?? "";
    message.method = object.method ?? "";
    return message;
  },
};

function createBaseGetTokenByRefreshTokenRes(): GetTokenByRefreshTokenRes {
  return { access_token: "" };
}

export const GetTokenByRefreshTokenRes = {
  encode(message: GetTokenByRefreshTokenRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.access_token !== "") {
      writer.uint32(10).string(message.access_token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTokenByRefreshTokenRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTokenByRefreshTokenRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.access_token = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTokenByRefreshTokenRes {
    return { access_token: isSet(object.access_token) ? String(object.access_token) : "" };
  },

  toJSON(message: GetTokenByRefreshTokenRes): unknown {
    const obj: any = {};
    message.access_token !== undefined && (obj.access_token = message.access_token);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTokenByRefreshTokenRes>, I>>(base?: I): GetTokenByRefreshTokenRes {
    return GetTokenByRefreshTokenRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetTokenByRefreshTokenRes>, I>>(object: I): GetTokenByRefreshTokenRes {
    const message = createBaseGetTokenByRefreshTokenRes();
    message.access_token = object.access_token ?? "";
    return message;
  },
};

function createBaseGetAllUsersReq(): GetAllUsersReq {
  return { phone_number: undefined, email: undefined };
}

export const GetAllUsersReq = {
  encode(message: GetAllUsersReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.phone_number !== undefined) {
      writer.uint32(10).string(message.phone_number);
    }
    if (message.email !== undefined) {
      writer.uint32(18).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllUsersReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllUsersReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phone_number = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllUsersReq {
    return {
      phone_number: isSet(object.phone_number) ? String(object.phone_number) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
    };
  },

  toJSON(message: GetAllUsersReq): unknown {
    const obj: any = {};
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    message.email !== undefined && (obj.email = message.email);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllUsersReq>, I>>(base?: I): GetAllUsersReq {
    return GetAllUsersReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAllUsersReq>, I>>(object: I): GetAllUsersReq {
    const message = createBaseGetAllUsersReq();
    message.phone_number = object.phone_number ?? undefined;
    message.email = object.email ?? undefined;
    return message;
  },
};

function createBaseGetAllUsersRes(): GetAllUsersRes {
  return { users: [] };
}

export const GetAllUsersRes = {
  encode(message: GetAllUsersRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      GetAllUsers.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllUsersRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllUsersRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(GetAllUsers.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllUsersRes {
    return { users: Array.isArray(object?.users) ? object.users.map((e: any) => GetAllUsers.fromJSON(e)) : [] };
  },

  toJSON(message: GetAllUsersRes): unknown {
    const obj: any = {};
    if (message.users) {
      obj.users = message.users.map((e) => e ? GetAllUsers.toJSON(e) : undefined);
    } else {
      obj.users = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllUsersRes>, I>>(base?: I): GetAllUsersRes {
    return GetAllUsersRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAllUsersRes>, I>>(object: I): GetAllUsersRes {
    const message = createBaseGetAllUsersRes();
    message.users = object.users?.map((e) => GetAllUsers.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetAllUsers(): GetAllUsers {
  return { user_id: 0, first_name: "", last_name: "" };
}

export const GetAllUsers = {
  encode(message: GetAllUsers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    if (message.first_name !== "") {
      writer.uint32(18).string(message.first_name);
    }
    if (message.last_name !== "") {
      writer.uint32(26).string(message.last_name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllUsers {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllUsers();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.first_name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.last_name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllUsers {
    return {
      user_id: isSet(object.user_id) ? Number(object.user_id) : 0,
      first_name: isSet(object.first_name) ? String(object.first_name) : "",
      last_name: isSet(object.last_name) ? String(object.last_name) : "",
    };
  },

  toJSON(message: GetAllUsers): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    message.first_name !== undefined && (obj.first_name = message.first_name);
    message.last_name !== undefined && (obj.last_name = message.last_name);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllUsers>, I>>(base?: I): GetAllUsers {
    return GetAllUsers.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAllUsers>, I>>(object: I): GetAllUsers {
    const message = createBaseGetAllUsers();
    message.user_id = object.user_id ?? 0;
    message.first_name = object.first_name ?? "";
    message.last_name = object.last_name ?? "";
    return message;
  },
};

function createBaseRole(): Role {
  return { id: 0, name: "", description: "", created_at: "" };
}

export const Role = {
  encode(message: Role, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.created_at !== "") {
      writer.uint32(34).string(message.created_at);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Role {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.created_at = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Role {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      created_at: isSet(object.created_at) ? String(object.created_at) : "",
    };
  },

  toJSON(message: Role): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.created_at !== undefined && (obj.created_at = message.created_at);
    return obj;
  },

  create<I extends Exact<DeepPartial<Role>, I>>(base?: I): Role {
    return Role.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Role>, I>>(object: I): Role {
    const message = createBaseRole();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.created_at = object.created_at ?? "";
    return message;
  },
};

function createBasePermission(): Permission {
  return { id: 0, name: "", code: "", route: "", method: "" };
}

export const Permission = {
  encode(message: Permission, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.code !== "") {
      writer.uint32(26).string(message.code);
    }
    if (message.route !== "") {
      writer.uint32(34).string(message.route);
    }
    if (message.method !== "") {
      writer.uint32(42).string(message.method);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Permission {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePermission();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.code = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.route = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.method = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Permission {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      name: isSet(object.name) ? String(object.name) : "",
      code: isSet(object.code) ? String(object.code) : "",
      route: isSet(object.route) ? String(object.route) : "",
      method: isSet(object.method) ? String(object.method) : "",
    };
  },

  toJSON(message: Permission): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.name !== undefined && (obj.name = message.name);
    message.code !== undefined && (obj.code = message.code);
    message.route !== undefined && (obj.route = message.route);
    message.method !== undefined && (obj.method = message.method);
    return obj;
  },

  create<I extends Exact<DeepPartial<Permission>, I>>(base?: I): Permission {
    return Permission.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Permission>, I>>(object: I): Permission {
    const message = createBasePermission();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.code = object.code ?? "";
    message.route = object.route ?? "";
    message.method = object.method ?? "";
    return message;
  },
};

function createBaseAdminGetUserPermissionReq(): AdminGetUserPermissionReq {
  return { user_id: 0 };
}

export const AdminGetUserPermissionReq = {
  encode(message: AdminGetUserPermissionReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetUserPermissionReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetUserPermissionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetUserPermissionReq {
    return { user_id: isSet(object.user_id) ? Number(object.user_id) : 0 };
  },

  toJSON(message: AdminGetUserPermissionReq): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetUserPermissionReq>, I>>(base?: I): AdminGetUserPermissionReq {
    return AdminGetUserPermissionReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetUserPermissionReq>, I>>(object: I): AdminGetUserPermissionReq {
    const message = createBaseAdminGetUserPermissionReq();
    message.user_id = object.user_id ?? 0;
    return message;
  },
};

function createBaseGetUserPermissionReq(): GetUserPermissionReq {
  return { iam: undefined, user_type: undefined };
}

export const GetUserPermissionReq = {
  encode(message: GetUserPermissionReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.user_type !== undefined) {
      writer.uint32(16).int32(message.user_type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserPermissionReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserPermissionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.user_type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserPermissionReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      user_type: isSet(object.user_type) ? userPermissionTypeFromJSON(object.user_type) : undefined,
    };
  },

  toJSON(message: GetUserPermissionReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.user_type !== undefined &&
      (obj.user_type = message.user_type !== undefined ? userPermissionTypeToJSON(message.user_type) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserPermissionReq>, I>>(base?: I): GetUserPermissionReq {
    return GetUserPermissionReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetUserPermissionReq>, I>>(object: I): GetUserPermissionReq {
    const message = createBaseGetUserPermissionReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.user_type = object.user_type ?? undefined;
    return message;
  },
};

function createBaseGetUserSpecialRolesReq(): GetUserSpecialRolesReq {
  return { iam: undefined };
}

export const GetUserSpecialRolesReq = {
  encode(message: GetUserSpecialRolesReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserSpecialRolesReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserSpecialRolesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserSpecialRolesReq {
    return { iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined };
  },

  toJSON(message: GetUserSpecialRolesReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserSpecialRolesReq>, I>>(base?: I): GetUserSpecialRolesReq {
    return GetUserSpecialRolesReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetUserSpecialRolesReq>, I>>(object: I): GetUserSpecialRolesReq {
    const message = createBaseGetUserSpecialRolesReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    return message;
  },
};

function createBaseAdminGetUserSpecialRolesReq(): AdminGetUserSpecialRolesReq {
  return { user_id: 0 };
}

export const AdminGetUserSpecialRolesReq = {
  encode(message: AdminGetUserSpecialRolesReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetUserSpecialRolesReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetUserSpecialRolesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetUserSpecialRolesReq {
    return { user_id: isSet(object.user_id) ? Number(object.user_id) : 0 };
  },

  toJSON(message: AdminGetUserSpecialRolesReq): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetUserSpecialRolesReq>, I>>(base?: I): AdminGetUserSpecialRolesReq {
    return AdminGetUserSpecialRolesReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetUserSpecialRolesReq>, I>>(object: I): AdminGetUserSpecialRolesReq {
    const message = createBaseAdminGetUserSpecialRolesReq();
    message.user_id = object.user_id ?? 0;
    return message;
  },
};

function createBaseGetUserSpecialRolesRes(): GetUserSpecialRolesRes {
  return { roles: [] };
}

export const GetUserSpecialRolesRes = {
  encode(message: GetUserSpecialRolesRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.roles) {
      Role.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserSpecialRolesRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserSpecialRolesRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roles.push(Role.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserSpecialRolesRes {
    return { roles: Array.isArray(object?.roles) ? object.roles.map((e: any) => Role.fromJSON(e)) : [] };
  },

  toJSON(message: GetUserSpecialRolesRes): unknown {
    const obj: any = {};
    if (message.roles) {
      obj.roles = message.roles.map((e) => e ? Role.toJSON(e) : undefined);
    } else {
      obj.roles = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserSpecialRolesRes>, I>>(base?: I): GetUserSpecialRolesRes {
    return GetUserSpecialRolesRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetUserSpecialRolesRes>, I>>(object: I): GetUserSpecialRolesRes {
    const message = createBaseGetUserSpecialRolesRes();
    message.roles = object.roles?.map((e) => Role.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAdminGetUserRolesReq(): AdminGetUserRolesReq {
  return { user_id: 0 };
}

export const AdminGetUserRolesReq = {
  encode(message: AdminGetUserRolesReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetUserRolesReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetUserRolesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetUserRolesReq {
    return { user_id: isSet(object.user_id) ? Number(object.user_id) : 0 };
  },

  toJSON(message: AdminGetUserRolesReq): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetUserRolesReq>, I>>(base?: I): AdminGetUserRolesReq {
    return AdminGetUserRolesReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetUserRolesReq>, I>>(object: I): AdminGetUserRolesReq {
    const message = createBaseAdminGetUserRolesReq();
    message.user_id = object.user_id ?? 0;
    return message;
  },
};

function createBaseAdminGetUserRolesRes(): AdminGetUserRolesRes {
  return { roles: [] };
}

export const AdminGetUserRolesRes = {
  encode(message: AdminGetUserRolesRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.roles) {
      Role.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetUserRolesRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetUserRolesRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roles.push(Role.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetUserRolesRes {
    return { roles: Array.isArray(object?.roles) ? object.roles.map((e: any) => Role.fromJSON(e)) : [] };
  },

  toJSON(message: AdminGetUserRolesRes): unknown {
    const obj: any = {};
    if (message.roles) {
      obj.roles = message.roles.map((e) => e ? Role.toJSON(e) : undefined);
    } else {
      obj.roles = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetUserRolesRes>, I>>(base?: I): AdminGetUserRolesRes {
    return AdminGetUserRolesRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetUserRolesRes>, I>>(object: I): AdminGetUserRolesRes {
    const message = createBaseAdminGetUserRolesRes();
    message.roles = object.roles?.map((e) => Role.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAdminUpdateUserRolesReq(): AdminUpdateUserRolesReq {
  return { user_id: 0, role_ids: [] };
}

export const AdminUpdateUserRolesReq = {
  encode(message: AdminUpdateUserRolesReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    writer.uint32(18).fork();
    for (const v of message.role_ids) {
      writer.int64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminUpdateUserRolesReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminUpdateUserRolesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag === 16) {
            message.role_ids.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.role_ids.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminUpdateUserRolesReq {
    return {
      user_id: isSet(object.user_id) ? Number(object.user_id) : 0,
      role_ids: Array.isArray(object?.role_ids) ? object.role_ids.map((e: any) => Number(e)) : [],
    };
  },

  toJSON(message: AdminUpdateUserRolesReq): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    if (message.role_ids) {
      obj.role_ids = message.role_ids.map((e) => Math.round(e));
    } else {
      obj.role_ids = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminUpdateUserRolesReq>, I>>(base?: I): AdminUpdateUserRolesReq {
    return AdminUpdateUserRolesReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminUpdateUserRolesReq>, I>>(object: I): AdminUpdateUserRolesReq {
    const message = createBaseAdminUpdateUserRolesReq();
    message.user_id = object.user_id ?? 0;
    message.role_ids = object.role_ids?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetUserPermissionRes(): GetUserPermissionRes {
  return { permissions: [], roles: [] };
}

export const GetUserPermissionRes = {
  encode(message: GetUserPermissionRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.permissions) {
      Permission.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.roles) {
      Role.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserPermissionRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserPermissionRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.permissions.push(Permission.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roles.push(Role.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserPermissionRes {
    return {
      permissions: Array.isArray(object?.permissions) ? object.permissions.map((e: any) => Permission.fromJSON(e)) : [],
      roles: Array.isArray(object?.roles) ? object.roles.map((e: any) => Role.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetUserPermissionRes): unknown {
    const obj: any = {};
    if (message.permissions) {
      obj.permissions = message.permissions.map((e) => e ? Permission.toJSON(e) : undefined);
    } else {
      obj.permissions = [];
    }
    if (message.roles) {
      obj.roles = message.roles.map((e) => e ? Role.toJSON(e) : undefined);
    } else {
      obj.roles = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserPermissionRes>, I>>(base?: I): GetUserPermissionRes {
    return GetUserPermissionRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetUserPermissionRes>, I>>(object: I): GetUserPermissionRes {
    const message = createBaseGetUserPermissionRes();
    message.permissions = object.permissions?.map((e) => Permission.fromPartial(e)) || [];
    message.roles = object.roles?.map((e) => Role.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAddRoleToUserReq(): AddRoleToUserReq {
  return { user_id: 0, role_id: 0 };
}

export const AddRoleToUserReq = {
  encode(message: AddRoleToUserReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    if (message.role_id !== 0) {
      writer.uint32(16).int64(message.role_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddRoleToUserReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddRoleToUserReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.role_id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddRoleToUserReq {
    return {
      user_id: isSet(object.user_id) ? Number(object.user_id) : 0,
      role_id: isSet(object.role_id) ? Number(object.role_id) : 0,
    };
  },

  toJSON(message: AddRoleToUserReq): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    message.role_id !== undefined && (obj.role_id = Math.round(message.role_id));
    return obj;
  },

  create<I extends Exact<DeepPartial<AddRoleToUserReq>, I>>(base?: I): AddRoleToUserReq {
    return AddRoleToUserReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AddRoleToUserReq>, I>>(object: I): AddRoleToUserReq {
    const message = createBaseAddRoleToUserReq();
    message.user_id = object.user_id ?? 0;
    message.role_id = object.role_id ?? 0;
    return message;
  },
};

function createBaseUpdatePermissionReq(): UpdatePermissionReq {
  return { id: 0, name: "" };
}

export const UpdatePermissionReq = {
  encode(message: UpdatePermissionReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdatePermissionReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdatePermissionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdatePermissionReq {
    return { id: isSet(object.id) ? Number(object.id) : 0, name: isSet(object.name) ? String(object.name) : "" };
  },

  toJSON(message: UpdatePermissionReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdatePermissionReq>, I>>(base?: I): UpdatePermissionReq {
    return UpdatePermissionReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdatePermissionReq>, I>>(object: I): UpdatePermissionReq {
    const message = createBaseUpdatePermissionReq();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseAdminCreateRoleReq(): AdminCreateRoleReq {
  return { name: "", description: "" };
}

export const AdminCreateRoleReq = {
  encode(message: AdminCreateRoleReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminCreateRoleReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminCreateRoleReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminCreateRoleReq {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
    };
  },

  toJSON(message: AdminCreateRoleReq): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminCreateRoleReq>, I>>(base?: I): AdminCreateRoleReq {
    return AdminCreateRoleReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminCreateRoleReq>, I>>(object: I): AdminCreateRoleReq {
    const message = createBaseAdminCreateRoleReq();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseAdminUpdateRoleReq(): AdminUpdateRoleReq {
  return { id: 0, name: "", description: "" };
}

export const AdminUpdateRoleReq = {
  encode(message: AdminUpdateRoleReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminUpdateRoleReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminUpdateRoleReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminUpdateRoleReq {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
    };
  },

  toJSON(message: AdminUpdateRoleReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminUpdateRoleReq>, I>>(base?: I): AdminUpdateRoleReq {
    return AdminUpdateRoleReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminUpdateRoleReq>, I>>(object: I): AdminUpdateRoleReq {
    const message = createBaseAdminUpdateRoleReq();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseAdminDeleteRoleReq(): AdminDeleteRoleReq {
  return { role_id: 0 };
}

export const AdminDeleteRoleReq = {
  encode(message: AdminDeleteRoleReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.role_id !== 0) {
      writer.uint32(8).int32(message.role_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminDeleteRoleReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminDeleteRoleReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.role_id = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminDeleteRoleReq {
    return { role_id: isSet(object.role_id) ? Number(object.role_id) : 0 };
  },

  toJSON(message: AdminDeleteRoleReq): unknown {
    const obj: any = {};
    message.role_id !== undefined && (obj.role_id = Math.round(message.role_id));
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminDeleteRoleReq>, I>>(base?: I): AdminDeleteRoleReq {
    return AdminDeleteRoleReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminDeleteRoleReq>, I>>(object: I): AdminDeleteRoleReq {
    const message = createBaseAdminDeleteRoleReq();
    message.role_id = object.role_id ?? 0;
    return message;
  },
};

function createBaseAdminGetAllRolesWithFilterReq(): AdminGetAllRolesWithFilterReq {
  return { type: undefined };
}

export const AdminGetAllRolesWithFilterReq = {
  encode(message: AdminGetAllRolesWithFilterReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== undefined) {
      writer.uint32(10).string(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetAllRolesWithFilterReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetAllRolesWithFilterReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetAllRolesWithFilterReq {
    return { type: isSet(object.type) ? String(object.type) : undefined };
  },

  toJSON(message: AdminGetAllRolesWithFilterReq): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = message.type);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetAllRolesWithFilterReq>, I>>(base?: I): AdminGetAllRolesWithFilterReq {
    return AdminGetAllRolesWithFilterReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetAllRolesWithFilterReq>, I>>(
    object: I,
  ): AdminGetAllRolesWithFilterReq {
    const message = createBaseAdminGetAllRolesWithFilterReq();
    message.type = object.type ?? undefined;
    return message;
  },
};

function createBaseAdminGetAllRolesRes(): AdminGetAllRolesRes {
  return { roles: [] };
}

export const AdminGetAllRolesRes = {
  encode(message: AdminGetAllRolesRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.roles) {
      Role.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetAllRolesRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetAllRolesRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roles.push(Role.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetAllRolesRes {
    return { roles: Array.isArray(object?.roles) ? object.roles.map((e: any) => Role.fromJSON(e)) : [] };
  },

  toJSON(message: AdminGetAllRolesRes): unknown {
    const obj: any = {};
    if (message.roles) {
      obj.roles = message.roles.map((e) => e ? Role.toJSON(e) : undefined);
    } else {
      obj.roles = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetAllRolesRes>, I>>(base?: I): AdminGetAllRolesRes {
    return AdminGetAllRolesRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetAllRolesRes>, I>>(object: I): AdminGetAllRolesRes {
    const message = createBaseAdminGetAllRolesRes();
    message.roles = object.roles?.map((e) => Role.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAdminGetAllPermissionsRes(): AdminGetAllPermissionsRes {
  return { permission: [] };
}

export const AdminGetAllPermissionsRes = {
  encode(message: AdminGetAllPermissionsRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.permission) {
      adminAllPermission.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetAllPermissionsRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetAllPermissionsRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.permission.push(adminAllPermission.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetAllPermissionsRes {
    return {
      permission: Array.isArray(object?.permission)
        ? object.permission.map((e: any) => adminAllPermission.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AdminGetAllPermissionsRes): unknown {
    const obj: any = {};
    if (message.permission) {
      obj.permission = message.permission.map((e) => e ? adminAllPermission.toJSON(e) : undefined);
    } else {
      obj.permission = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetAllPermissionsRes>, I>>(base?: I): AdminGetAllPermissionsRes {
    return AdminGetAllPermissionsRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetAllPermissionsRes>, I>>(object: I): AdminGetAllPermissionsRes {
    const message = createBaseAdminGetAllPermissionsRes();
    message.permission = object.permission?.map((e) => adminAllPermission.fromPartial(e)) || [];
    return message;
  },
};

function createBaseadminAllPermissionMethod(): adminAllPermissionMethod {
  return { id: 0, method: "" };
}

export const adminAllPermissionMethod = {
  encode(message: adminAllPermissionMethod, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.method !== "") {
      writer.uint32(18).string(message.method);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): adminAllPermissionMethod {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseadminAllPermissionMethod();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.method = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): adminAllPermissionMethod {
    return { id: isSet(object.id) ? Number(object.id) : 0, method: isSet(object.method) ? String(object.method) : "" };
  },

  toJSON(message: adminAllPermissionMethod): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.method !== undefined && (obj.method = message.method);
    return obj;
  },

  create<I extends Exact<DeepPartial<adminAllPermissionMethod>, I>>(base?: I): adminAllPermissionMethod {
    return adminAllPermissionMethod.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<adminAllPermissionMethod>, I>>(object: I): adminAllPermissionMethod {
    const message = createBaseadminAllPermissionMethod();
    message.id = object.id ?? 0;
    message.method = object.method ?? "";
    return message;
  },
};

function createBaseadminAllPermission(): adminAllPermission {
  return { name: "", route: "", methods: [] };
}

export const adminAllPermission = {
  encode(message: adminAllPermission, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.route !== "") {
      writer.uint32(18).string(message.route);
    }
    for (const v of message.methods) {
      adminAllPermissionMethod.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): adminAllPermission {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseadminAllPermission();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.route = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.methods.push(adminAllPermissionMethod.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): adminAllPermission {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      route: isSet(object.route) ? String(object.route) : "",
      methods: Array.isArray(object?.methods)
        ? object.methods.map((e: any) => adminAllPermissionMethod.fromJSON(e))
        : [],
    };
  },

  toJSON(message: adminAllPermission): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.route !== undefined && (obj.route = message.route);
    if (message.methods) {
      obj.methods = message.methods.map((e) => e ? adminAllPermissionMethod.toJSON(e) : undefined);
    } else {
      obj.methods = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<adminAllPermission>, I>>(base?: I): adminAllPermission {
    return adminAllPermission.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<adminAllPermission>, I>>(object: I): adminAllPermission {
    const message = createBaseadminAllPermission();
    message.name = object.name ?? "";
    message.route = object.route ?? "";
    message.methods = object.methods?.map((e) => adminAllPermissionMethod.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAdminAssignRolePermissionReq(): AdminAssignRolePermissionReq {
  return { role_id: 0, permissions_id: [] };
}

export const AdminAssignRolePermissionReq = {
  encode(message: AdminAssignRolePermissionReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.role_id !== 0) {
      writer.uint32(8).int32(message.role_id);
    }
    writer.uint32(18).fork();
    for (const v of message.permissions_id) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminAssignRolePermissionReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminAssignRolePermissionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.role_id = reader.int32();
          continue;
        case 2:
          if (tag === 16) {
            message.permissions_id.push(reader.int32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.permissions_id.push(reader.int32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminAssignRolePermissionReq {
    return {
      role_id: isSet(object.role_id) ? Number(object.role_id) : 0,
      permissions_id: Array.isArray(object?.permissions_id) ? object.permissions_id.map((e: any) => Number(e)) : [],
    };
  },

  toJSON(message: AdminAssignRolePermissionReq): unknown {
    const obj: any = {};
    message.role_id !== undefined && (obj.role_id = Math.round(message.role_id));
    if (message.permissions_id) {
      obj.permissions_id = message.permissions_id.map((e) => Math.round(e));
    } else {
      obj.permissions_id = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminAssignRolePermissionReq>, I>>(base?: I): AdminAssignRolePermissionReq {
    return AdminAssignRolePermissionReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminAssignRolePermissionReq>, I>>(object: I): AdminAssignRolePermissionReq {
    const message = createBaseAdminAssignRolePermissionReq();
    message.role_id = object.role_id ?? 0;
    message.permissions_id = object.permissions_id?.map((e) => e) || [];
    return message;
  },
};

function createBaseAdminGetPermissionsOfRoleReq(): AdminGetPermissionsOfRoleReq {
  return { role_id: 0 };
}

export const AdminGetPermissionsOfRoleReq = {
  encode(message: AdminGetPermissionsOfRoleReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.role_id !== 0) {
      writer.uint32(8).int32(message.role_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetPermissionsOfRoleReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetPermissionsOfRoleReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.role_id = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetPermissionsOfRoleReq {
    return { role_id: isSet(object.role_id) ? Number(object.role_id) : 0 };
  },

  toJSON(message: AdminGetPermissionsOfRoleReq): unknown {
    const obj: any = {};
    message.role_id !== undefined && (obj.role_id = Math.round(message.role_id));
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetPermissionsOfRoleReq>, I>>(base?: I): AdminGetPermissionsOfRoleReq {
    return AdminGetPermissionsOfRoleReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetPermissionsOfRoleReq>, I>>(object: I): AdminGetPermissionsOfRoleReq {
    const message = createBaseAdminGetPermissionsOfRoleReq();
    message.role_id = object.role_id ?? 0;
    return message;
  },
};

function createBaseAdminGetPermissionsOfRoleRes(): AdminGetPermissionsOfRoleRes {
  return { permissions: [] };
}

export const AdminGetPermissionsOfRoleRes = {
  encode(message: AdminGetPermissionsOfRoleRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.permissions) {
      Permission.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetPermissionsOfRoleRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetPermissionsOfRoleRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.permissions.push(Permission.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetPermissionsOfRoleRes {
    return {
      permissions: Array.isArray(object?.permissions) ? object.permissions.map((e: any) => Permission.fromJSON(e)) : [],
    };
  },

  toJSON(message: AdminGetPermissionsOfRoleRes): unknown {
    const obj: any = {};
    if (message.permissions) {
      obj.permissions = message.permissions.map((e) => e ? Permission.toJSON(e) : undefined);
    } else {
      obj.permissions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetPermissionsOfRoleRes>, I>>(base?: I): AdminGetPermissionsOfRoleRes {
    return AdminGetPermissionsOfRoleRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetPermissionsOfRoleRes>, I>>(object: I): AdminGetPermissionsOfRoleRes {
    const message = createBaseAdminGetPermissionsOfRoleRes();
    message.permissions = object.permissions?.map((e) => Permission.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSignUpReq(): SignUpReq {
  return { country_id: 0, phone_number: "", password: "" };
}

export const SignUpReq = {
  encode(message: SignUpReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.country_id !== 0) {
      writer.uint32(8).int32(message.country_id);
    }
    if (message.phone_number !== "") {
      writer.uint32(18).string(message.phone_number);
    }
    if (message.password !== "") {
      writer.uint32(26).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignUpReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUpReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.country_id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.phone_number = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignUpReq {
    return {
      country_id: isSet(object.country_id) ? Number(object.country_id) : 0,
      phone_number: isSet(object.phone_number) ? String(object.phone_number) : "",
      password: isSet(object.password) ? String(object.password) : "",
    };
  },

  toJSON(message: SignUpReq): unknown {
    const obj: any = {};
    message.country_id !== undefined && (obj.country_id = Math.round(message.country_id));
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    message.password !== undefined && (obj.password = message.password);
    return obj;
  },

  create<I extends Exact<DeepPartial<SignUpReq>, I>>(base?: I): SignUpReq {
    return SignUpReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SignUpReq>, I>>(object: I): SignUpReq {
    const message = createBaseSignUpReq();
    message.country_id = object.country_id ?? 0;
    message.phone_number = object.phone_number ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBaseConfirmSignUpReq(): ConfirmSignUpReq {
  return { phone_number: "", password: "", otp_code: "" };
}

export const ConfirmSignUpReq = {
  encode(message: ConfirmSignUpReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.phone_number !== "") {
      writer.uint32(10).string(message.phone_number);
    }
    if (message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    if (message.otp_code !== "") {
      writer.uint32(26).string(message.otp_code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConfirmSignUpReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfirmSignUpReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phone_number = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.password = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.otp_code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfirmSignUpReq {
    return {
      phone_number: isSet(object.phone_number) ? String(object.phone_number) : "",
      password: isSet(object.password) ? String(object.password) : "",
      otp_code: isSet(object.otp_code) ? String(object.otp_code) : "",
    };
  },

  toJSON(message: ConfirmSignUpReq): unknown {
    const obj: any = {};
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    message.password !== undefined && (obj.password = message.password);
    message.otp_code !== undefined && (obj.otp_code = message.otp_code);
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfirmSignUpReq>, I>>(base?: I): ConfirmSignUpReq {
    return ConfirmSignUpReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConfirmSignUpReq>, I>>(object: I): ConfirmSignUpReq {
    const message = createBaseConfirmSignUpReq();
    message.phone_number = object.phone_number ?? "";
    message.password = object.password ?? "";
    message.otp_code = object.otp_code ?? "";
    return message;
  },
};

function createBaseResendConfirmationCodeReq(): ResendConfirmationCodeReq {
  return { phone_number: "" };
}

export const ResendConfirmationCodeReq = {
  encode(message: ResendConfirmationCodeReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.phone_number !== "") {
      writer.uint32(10).string(message.phone_number);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResendConfirmationCodeReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResendConfirmationCodeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phone_number = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ResendConfirmationCodeReq {
    return { phone_number: isSet(object.phone_number) ? String(object.phone_number) : "" };
  },

  toJSON(message: ResendConfirmationCodeReq): unknown {
    const obj: any = {};
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    return obj;
  },

  create<I extends Exact<DeepPartial<ResendConfirmationCodeReq>, I>>(base?: I): ResendConfirmationCodeReq {
    return ResendConfirmationCodeReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ResendConfirmationCodeReq>, I>>(object: I): ResendConfirmationCodeReq {
    const message = createBaseResendConfirmationCodeReq();
    message.phone_number = object.phone_number ?? "";
    return message;
  },
};

function createBaseSignInReq(): SignInReq {
  return { phone_number: "", password: "", temporary_login: false };
}

export const SignInReq = {
  encode(message: SignInReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.phone_number !== "") {
      writer.uint32(10).string(message.phone_number);
    }
    if (message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    if (message.temporary_login === true) {
      writer.uint32(24).bool(message.temporary_login);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignInReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignInReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phone_number = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.password = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.temporary_login = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignInReq {
    return {
      phone_number: isSet(object.phone_number) ? String(object.phone_number) : "",
      password: isSet(object.password) ? String(object.password) : "",
      temporary_login: isSet(object.temporary_login) ? Boolean(object.temporary_login) : false,
    };
  },

  toJSON(message: SignInReq): unknown {
    const obj: any = {};
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    message.password !== undefined && (obj.password = message.password);
    message.temporary_login !== undefined && (obj.temporary_login = message.temporary_login);
    return obj;
  },

  create<I extends Exact<DeepPartial<SignInReq>, I>>(base?: I): SignInReq {
    return SignInReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SignInReq>, I>>(object: I): SignInReq {
    const message = createBaseSignInReq();
    message.phone_number = object.phone_number ?? "";
    message.password = object.password ?? "";
    message.temporary_login = object.temporary_login ?? false;
    return message;
  },
};

function createBaseSignInRes(): SignInRes {
  return {
    access_token: "",
    refresh_token: "",
    unconfirmed: false,
    mfa: false,
    profile_completion_necessary: false,
    address_completion_necessary: false,
    session: "",
    forceChangePassword: false,
    challengeName: "",
    sso_session_id: undefined,
  };
}

export const SignInRes = {
  encode(message: SignInRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.access_token !== "") {
      writer.uint32(10).string(message.access_token);
    }
    if (message.refresh_token !== "") {
      writer.uint32(18).string(message.refresh_token);
    }
    if (message.unconfirmed === true) {
      writer.uint32(24).bool(message.unconfirmed);
    }
    if (message.mfa === true) {
      writer.uint32(32).bool(message.mfa);
    }
    if (message.profile_completion_necessary === true) {
      writer.uint32(40).bool(message.profile_completion_necessary);
    }
    if (message.address_completion_necessary === true) {
      writer.uint32(48).bool(message.address_completion_necessary);
    }
    if (message.session !== "") {
      writer.uint32(58).string(message.session);
    }
    if (message.forceChangePassword === true) {
      writer.uint32(64).bool(message.forceChangePassword);
    }
    if (message.challengeName !== "") {
      writer.uint32(74).string(message.challengeName);
    }
    if (message.sso_session_id !== undefined) {
      writer.uint32(82).string(message.sso_session_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignInRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignInRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.access_token = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.refresh_token = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.unconfirmed = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.mfa = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.profile_completion_necessary = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.address_completion_necessary = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.session = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.forceChangePassword = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.challengeName = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.sso_session_id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignInRes {
    return {
      access_token: isSet(object.access_token) ? String(object.access_token) : "",
      refresh_token: isSet(object.refresh_token) ? String(object.refresh_token) : "",
      unconfirmed: isSet(object.unconfirmed) ? Boolean(object.unconfirmed) : false,
      mfa: isSet(object.mfa) ? Boolean(object.mfa) : false,
      profile_completion_necessary: isSet(object.profile_completion_necessary)
        ? Boolean(object.profile_completion_necessary)
        : false,
      address_completion_necessary: isSet(object.address_completion_necessary)
        ? Boolean(object.address_completion_necessary)
        : false,
      session: isSet(object.session) ? String(object.session) : "",
      forceChangePassword: isSet(object.forceChangePassword) ? Boolean(object.forceChangePassword) : false,
      challengeName: isSet(object.challengeName) ? String(object.challengeName) : "",
      sso_session_id: isSet(object.sso_session_id) ? String(object.sso_session_id) : undefined,
    };
  },

  toJSON(message: SignInRes): unknown {
    const obj: any = {};
    message.access_token !== undefined && (obj.access_token = message.access_token);
    message.refresh_token !== undefined && (obj.refresh_token = message.refresh_token);
    message.unconfirmed !== undefined && (obj.unconfirmed = message.unconfirmed);
    message.mfa !== undefined && (obj.mfa = message.mfa);
    message.profile_completion_necessary !== undefined &&
      (obj.profile_completion_necessary = message.profile_completion_necessary);
    message.address_completion_necessary !== undefined &&
      (obj.address_completion_necessary = message.address_completion_necessary);
    message.session !== undefined && (obj.session = message.session);
    message.forceChangePassword !== undefined && (obj.forceChangePassword = message.forceChangePassword);
    message.challengeName !== undefined && (obj.challengeName = message.challengeName);
    message.sso_session_id !== undefined && (obj.sso_session_id = message.sso_session_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<SignInRes>, I>>(base?: I): SignInRes {
    return SignInRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SignInRes>, I>>(object: I): SignInRes {
    const message = createBaseSignInRes();
    message.access_token = object.access_token ?? "";
    message.refresh_token = object.refresh_token ?? "";
    message.unconfirmed = object.unconfirmed ?? false;
    message.mfa = object.mfa ?? false;
    message.profile_completion_necessary = object.profile_completion_necessary ?? false;
    message.address_completion_necessary = object.address_completion_necessary ?? false;
    message.session = object.session ?? "";
    message.forceChangePassword = object.forceChangePassword ?? false;
    message.challengeName = object.challengeName ?? "";
    message.sso_session_id = object.sso_session_id ?? undefined;
    return message;
  },
};

function createBaseRespondToAuthChallengeReq(): RespondToAuthChallengeReq {
  return { username: "", code: "", session: "", challengeName: "" };
}

export const RespondToAuthChallengeReq = {
  encode(message: RespondToAuthChallengeReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    if (message.code !== "") {
      writer.uint32(18).string(message.code);
    }
    if (message.session !== "") {
      writer.uint32(26).string(message.session);
    }
    if (message.challengeName !== "") {
      writer.uint32(34).string(message.challengeName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RespondToAuthChallengeReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRespondToAuthChallengeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.session = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.challengeName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RespondToAuthChallengeReq {
    return {
      username: isSet(object.username) ? String(object.username) : "",
      code: isSet(object.code) ? String(object.code) : "",
      session: isSet(object.session) ? String(object.session) : "",
      challengeName: isSet(object.challengeName) ? String(object.challengeName) : "",
    };
  },

  toJSON(message: RespondToAuthChallengeReq): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    message.code !== undefined && (obj.code = message.code);
    message.session !== undefined && (obj.session = message.session);
    message.challengeName !== undefined && (obj.challengeName = message.challengeName);
    return obj;
  },

  create<I extends Exact<DeepPartial<RespondToAuthChallengeReq>, I>>(base?: I): RespondToAuthChallengeReq {
    return RespondToAuthChallengeReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RespondToAuthChallengeReq>, I>>(object: I): RespondToAuthChallengeReq {
    const message = createBaseRespondToAuthChallengeReq();
    message.username = object.username ?? "";
    message.code = object.code ?? "";
    message.session = object.session ?? "";
    message.challengeName = object.challengeName ?? "";
    return message;
  },
};

function createBaseFederatedItem(): FederatedItem {
  return { id: 0, provider: "", url: "", platform: [], isActive: false, logout_url: "" };
}

export const FederatedItem = {
  encode(message: FederatedItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.provider !== "") {
      writer.uint32(18).string(message.provider);
    }
    if (message.url !== "") {
      writer.uint32(26).string(message.url);
    }
    for (const v of message.platform) {
      writer.uint32(34).string(v!);
    }
    if (message.isActive === true) {
      writer.uint32(40).bool(message.isActive);
    }
    if (message.logout_url !== "") {
      writer.uint32(50).string(message.logout_url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FederatedItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFederatedItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.provider = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.url = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.platform.push(reader.string());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isActive = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.logout_url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FederatedItem {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      provider: isSet(object.provider) ? String(object.provider) : "",
      url: isSet(object.url) ? String(object.url) : "",
      platform: Array.isArray(object?.platform) ? object.platform.map((e: any) => String(e)) : [],
      isActive: isSet(object.isActive) ? Boolean(object.isActive) : false,
      logout_url: isSet(object.logout_url) ? String(object.logout_url) : "",
    };
  },

  toJSON(message: FederatedItem): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.provider !== undefined && (obj.provider = message.provider);
    message.url !== undefined && (obj.url = message.url);
    if (message.platform) {
      obj.platform = message.platform.map((e) => e);
    } else {
      obj.platform = [];
    }
    message.isActive !== undefined && (obj.isActive = message.isActive);
    message.logout_url !== undefined && (obj.logout_url = message.logout_url);
    return obj;
  },

  create<I extends Exact<DeepPartial<FederatedItem>, I>>(base?: I): FederatedItem {
    return FederatedItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FederatedItem>, I>>(object: I): FederatedItem {
    const message = createBaseFederatedItem();
    message.id = object.id ?? 0;
    message.provider = object.provider ?? "";
    message.url = object.url ?? "";
    message.platform = object.platform?.map((e) => e) || [];
    message.isActive = object.isActive ?? false;
    message.logout_url = object.logout_url ?? "";
    return message;
  },
};

function createBaseGetFederatedListRes(): GetFederatedListRes {
  return { list: [] };
}

export const GetFederatedListRes = {
  encode(message: GetFederatedListRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.list) {
      FederatedItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFederatedListRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFederatedListRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.list.push(FederatedItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFederatedListRes {
    return { list: Array.isArray(object?.list) ? object.list.map((e: any) => FederatedItem.fromJSON(e)) : [] };
  },

  toJSON(message: GetFederatedListRes): unknown {
    const obj: any = {};
    if (message.list) {
      obj.list = message.list.map((e) => e ? FederatedItem.toJSON(e) : undefined);
    } else {
      obj.list = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFederatedListRes>, I>>(base?: I): GetFederatedListRes {
    return GetFederatedListRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetFederatedListRes>, I>>(object: I): GetFederatedListRes {
    const message = createBaseGetFederatedListRes();
    message.list = object.list?.map((e) => FederatedItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseValidateFederatedCodeReq(): ValidateFederatedCodeReq {
  return { code: "" };
}

export const ValidateFederatedCodeReq = {
  encode(message: ValidateFederatedCodeReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValidateFederatedCodeReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValidateFederatedCodeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ValidateFederatedCodeReq {
    return { code: isSet(object.code) ? String(object.code) : "" };
  },

  toJSON(message: ValidateFederatedCodeReq): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    return obj;
  },

  create<I extends Exact<DeepPartial<ValidateFederatedCodeReq>, I>>(base?: I): ValidateFederatedCodeReq {
    return ValidateFederatedCodeReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ValidateFederatedCodeReq>, I>>(object: I): ValidateFederatedCodeReq {
    const message = createBaseValidateFederatedCodeReq();
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseForgotPasswordReq(): ForgotPasswordReq {
  return { phone_number: "" };
}

export const ForgotPasswordReq = {
  encode(message: ForgotPasswordReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.phone_number !== "") {
      writer.uint32(10).string(message.phone_number);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ForgotPasswordReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseForgotPasswordReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phone_number = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ForgotPasswordReq {
    return { phone_number: isSet(object.phone_number) ? String(object.phone_number) : "" };
  },

  toJSON(message: ForgotPasswordReq): unknown {
    const obj: any = {};
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    return obj;
  },

  create<I extends Exact<DeepPartial<ForgotPasswordReq>, I>>(base?: I): ForgotPasswordReq {
    return ForgotPasswordReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ForgotPasswordReq>, I>>(object: I): ForgotPasswordReq {
    const message = createBaseForgotPasswordReq();
    message.phone_number = object.phone_number ?? "";
    return message;
  },
};

function createBaseChangeUserPasswordReq(): ChangeUserPasswordReq {
  return { iam: undefined, current_password: "", new_password: "" };
}

export const ChangeUserPasswordReq = {
  encode(message: ChangeUserPasswordReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.current_password !== "") {
      writer.uint32(18).string(message.current_password);
    }
    if (message.new_password !== "") {
      writer.uint32(26).string(message.new_password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChangeUserPasswordReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChangeUserPasswordReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.current_password = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.new_password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChangeUserPasswordReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      current_password: isSet(object.current_password) ? String(object.current_password) : "",
      new_password: isSet(object.new_password) ? String(object.new_password) : "",
    };
  },

  toJSON(message: ChangeUserPasswordReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.current_password !== undefined && (obj.current_password = message.current_password);
    message.new_password !== undefined && (obj.new_password = message.new_password);
    return obj;
  },

  create<I extends Exact<DeepPartial<ChangeUserPasswordReq>, I>>(base?: I): ChangeUserPasswordReq {
    return ChangeUserPasswordReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ChangeUserPasswordReq>, I>>(object: I): ChangeUserPasswordReq {
    const message = createBaseChangeUserPasswordReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.current_password = object.current_password ?? "";
    message.new_password = object.new_password ?? "";
    return message;
  },
};

function createBaseConfirmForgotPasswordReq(): ConfirmForgotPasswordReq {
  return { phone_number: "", confirmation_code: "", newPassword: "" };
}

export const ConfirmForgotPasswordReq = {
  encode(message: ConfirmForgotPasswordReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.phone_number !== "") {
      writer.uint32(10).string(message.phone_number);
    }
    if (message.confirmation_code !== "") {
      writer.uint32(18).string(message.confirmation_code);
    }
    if (message.newPassword !== "") {
      writer.uint32(26).string(message.newPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConfirmForgotPasswordReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfirmForgotPasswordReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phone_number = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.confirmation_code = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.newPassword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfirmForgotPasswordReq {
    return {
      phone_number: isSet(object.phone_number) ? String(object.phone_number) : "",
      confirmation_code: isSet(object.confirmation_code) ? String(object.confirmation_code) : "",
      newPassword: isSet(object.newPassword) ? String(object.newPassword) : "",
    };
  },

  toJSON(message: ConfirmForgotPasswordReq): unknown {
    const obj: any = {};
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    message.confirmation_code !== undefined && (obj.confirmation_code = message.confirmation_code);
    message.newPassword !== undefined && (obj.newPassword = message.newPassword);
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfirmForgotPasswordReq>, I>>(base?: I): ConfirmForgotPasswordReq {
    return ConfirmForgotPasswordReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConfirmForgotPasswordReq>, I>>(object: I): ConfirmForgotPasswordReq {
    const message = createBaseConfirmForgotPasswordReq();
    message.phone_number = object.phone_number ?? "";
    message.confirmation_code = object.confirmation_code ?? "";
    message.newPassword = object.newPassword ?? "";
    return message;
  },
};

function createBaseBasicUserInfo(): BasicUserInfo {
  return { userId: 0, first_name: "", last_name: "" };
}

export const BasicUserInfo = {
  encode(message: BasicUserInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int64(message.userId);
    }
    if (message.first_name !== "") {
      writer.uint32(18).string(message.first_name);
    }
    if (message.last_name !== "") {
      writer.uint32(26).string(message.last_name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BasicUserInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBasicUserInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.first_name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.last_name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BasicUserInfo {
    return {
      userId: isSet(object.userId) ? Number(object.userId) : 0,
      first_name: isSet(object.first_name) ? String(object.first_name) : "",
      last_name: isSet(object.last_name) ? String(object.last_name) : "",
    };
  },

  toJSON(message: BasicUserInfo): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = Math.round(message.userId));
    message.first_name !== undefined && (obj.first_name = message.first_name);
    message.last_name !== undefined && (obj.last_name = message.last_name);
    return obj;
  },

  create<I extends Exact<DeepPartial<BasicUserInfo>, I>>(base?: I): BasicUserInfo {
    return BasicUserInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BasicUserInfo>, I>>(object: I): BasicUserInfo {
    const message = createBaseBasicUserInfo();
    message.userId = object.userId ?? 0;
    message.first_name = object.first_name ?? "";
    message.last_name = object.last_name ?? "";
    return message;
  },
};

function createBaseGetAllUserProfile(): GetAllUserProfile {
  return { first_name: "", last_name: "", country: "", email: "", phone_number: "" };
}

export const GetAllUserProfile = {
  encode(message: GetAllUserProfile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.first_name !== "") {
      writer.uint32(10).string(message.first_name);
    }
    if (message.last_name !== "") {
      writer.uint32(18).string(message.last_name);
    }
    if (message.country !== "") {
      writer.uint32(26).string(message.country);
    }
    if (message.email !== "") {
      writer.uint32(34).string(message.email);
    }
    if (message.phone_number !== "") {
      writer.uint32(42).string(message.phone_number);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllUserProfile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllUserProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.first_name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.last_name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.country = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.email = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.phone_number = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllUserProfile {
    return {
      first_name: isSet(object.first_name) ? String(object.first_name) : "",
      last_name: isSet(object.last_name) ? String(object.last_name) : "",
      country: isSet(object.country) ? String(object.country) : "",
      email: isSet(object.email) ? String(object.email) : "",
      phone_number: isSet(object.phone_number) ? String(object.phone_number) : "",
    };
  },

  toJSON(message: GetAllUserProfile): unknown {
    const obj: any = {};
    message.first_name !== undefined && (obj.first_name = message.first_name);
    message.last_name !== undefined && (obj.last_name = message.last_name);
    message.country !== undefined && (obj.country = message.country);
    message.email !== undefined && (obj.email = message.email);
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllUserProfile>, I>>(base?: I): GetAllUserProfile {
    return GetAllUserProfile.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAllUserProfile>, I>>(object: I): GetAllUserProfile {
    const message = createBaseGetAllUserProfile();
    message.first_name = object.first_name ?? "";
    message.last_name = object.last_name ?? "";
    message.country = object.country ?? "";
    message.email = object.email ?? "";
    message.phone_number = object.phone_number ?? "";
    return message;
  },
};

function createBaseProfileRes(): ProfileRes {
  return {
    first_name: "",
    last_name: "",
    birthdate: "",
    suffix: undefined,
    email: undefined,
    phone_number: "",
    email_verified: false,
    phone_number_verified: false,
    is_federated: false,
    invitation_point: 0,
    sin: undefined,
    profile_image: undefined,
    profile_verification: undefined,
    can_be_provider: false,
    can_be_investor: false,
    can_be_borrower: false,
    is_provider: false,
    is_investor: false,
    is_borrower: false,
    is_admin: false,
    user_id: 0,
    total_invitation_point: 0,
  };
}

export const ProfileRes = {
  encode(message: ProfileRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.first_name !== "") {
      writer.uint32(10).string(message.first_name);
    }
    if (message.last_name !== "") {
      writer.uint32(18).string(message.last_name);
    }
    if (message.birthdate !== "") {
      writer.uint32(26).string(message.birthdate);
    }
    if (message.suffix !== undefined) {
      writer.uint32(34).string(message.suffix);
    }
    if (message.email !== undefined) {
      writer.uint32(42).string(message.email);
    }
    if (message.phone_number !== "") {
      writer.uint32(50).string(message.phone_number);
    }
    if (message.email_verified === true) {
      writer.uint32(56).bool(message.email_verified);
    }
    if (message.phone_number_verified === true) {
      writer.uint32(64).bool(message.phone_number_verified);
    }
    if (message.is_federated === true) {
      writer.uint32(72).bool(message.is_federated);
    }
    if (message.invitation_point !== 0) {
      writer.uint32(80).int64(message.invitation_point);
    }
    if (message.sin !== undefined) {
      writer.uint32(90).string(message.sin);
    }
    if (message.profile_image !== undefined) {
      writer.uint32(98).string(message.profile_image);
    }
    if (message.profile_verification !== undefined) {
      ProfileVerificationRes.encode(message.profile_verification, writer.uint32(106).fork()).ldelim();
    }
    if (message.can_be_provider === true) {
      writer.uint32(112).bool(message.can_be_provider);
    }
    if (message.can_be_investor === true) {
      writer.uint32(120).bool(message.can_be_investor);
    }
    if (message.can_be_borrower === true) {
      writer.uint32(128).bool(message.can_be_borrower);
    }
    if (message.is_provider === true) {
      writer.uint32(136).bool(message.is_provider);
    }
    if (message.is_investor === true) {
      writer.uint32(144).bool(message.is_investor);
    }
    if (message.is_borrower === true) {
      writer.uint32(152).bool(message.is_borrower);
    }
    if (message.is_admin === true) {
      writer.uint32(160).bool(message.is_admin);
    }
    if (message.user_id !== 0) {
      writer.uint32(168).int64(message.user_id);
    }
    if (message.total_invitation_point !== 0) {
      writer.uint32(176).int64(message.total_invitation_point);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProfileRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProfileRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.first_name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.last_name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.birthdate = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.suffix = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.email = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.phone_number = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.email_verified = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.phone_number_verified = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.is_federated = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.invitation_point = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.sin = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.profile_image = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.profile_verification = ProfileVerificationRes.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.can_be_provider = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.can_be_investor = reader.bool();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.can_be_borrower = reader.bool();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.is_provider = reader.bool();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.is_investor = reader.bool();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.is_borrower = reader.bool();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.is_admin = reader.bool();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.total_invitation_point = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProfileRes {
    return {
      first_name: isSet(object.first_name) ? String(object.first_name) : "",
      last_name: isSet(object.last_name) ? String(object.last_name) : "",
      birthdate: isSet(object.birthdate) ? String(object.birthdate) : "",
      suffix: isSet(object.suffix) ? String(object.suffix) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      phone_number: isSet(object.phone_number) ? String(object.phone_number) : "",
      email_verified: isSet(object.email_verified) ? Boolean(object.email_verified) : false,
      phone_number_verified: isSet(object.phone_number_verified) ? Boolean(object.phone_number_verified) : false,
      is_federated: isSet(object.is_federated) ? Boolean(object.is_federated) : false,
      invitation_point: isSet(object.invitation_point) ? Number(object.invitation_point) : 0,
      sin: isSet(object.sin) ? String(object.sin) : undefined,
      profile_image: isSet(object.profile_image) ? String(object.profile_image) : undefined,
      profile_verification: isSet(object.profile_verification)
        ? ProfileVerificationRes.fromJSON(object.profile_verification)
        : undefined,
      can_be_provider: isSet(object.can_be_provider) ? Boolean(object.can_be_provider) : false,
      can_be_investor: isSet(object.can_be_investor) ? Boolean(object.can_be_investor) : false,
      can_be_borrower: isSet(object.can_be_borrower) ? Boolean(object.can_be_borrower) : false,
      is_provider: isSet(object.is_provider) ? Boolean(object.is_provider) : false,
      is_investor: isSet(object.is_investor) ? Boolean(object.is_investor) : false,
      is_borrower: isSet(object.is_borrower) ? Boolean(object.is_borrower) : false,
      is_admin: isSet(object.is_admin) ? Boolean(object.is_admin) : false,
      user_id: isSet(object.user_id) ? Number(object.user_id) : 0,
      total_invitation_point: isSet(object.total_invitation_point) ? Number(object.total_invitation_point) : 0,
    };
  },

  toJSON(message: ProfileRes): unknown {
    const obj: any = {};
    message.first_name !== undefined && (obj.first_name = message.first_name);
    message.last_name !== undefined && (obj.last_name = message.last_name);
    message.birthdate !== undefined && (obj.birthdate = message.birthdate);
    message.suffix !== undefined && (obj.suffix = message.suffix);
    message.email !== undefined && (obj.email = message.email);
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    message.email_verified !== undefined && (obj.email_verified = message.email_verified);
    message.phone_number_verified !== undefined && (obj.phone_number_verified = message.phone_number_verified);
    message.is_federated !== undefined && (obj.is_federated = message.is_federated);
    message.invitation_point !== undefined && (obj.invitation_point = Math.round(message.invitation_point));
    message.sin !== undefined && (obj.sin = message.sin);
    message.profile_image !== undefined && (obj.profile_image = message.profile_image);
    message.profile_verification !== undefined && (obj.profile_verification = message.profile_verification
      ? ProfileVerificationRes.toJSON(message.profile_verification)
      : undefined);
    message.can_be_provider !== undefined && (obj.can_be_provider = message.can_be_provider);
    message.can_be_investor !== undefined && (obj.can_be_investor = message.can_be_investor);
    message.can_be_borrower !== undefined && (obj.can_be_borrower = message.can_be_borrower);
    message.is_provider !== undefined && (obj.is_provider = message.is_provider);
    message.is_investor !== undefined && (obj.is_investor = message.is_investor);
    message.is_borrower !== undefined && (obj.is_borrower = message.is_borrower);
    message.is_admin !== undefined && (obj.is_admin = message.is_admin);
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    message.total_invitation_point !== undefined &&
      (obj.total_invitation_point = Math.round(message.total_invitation_point));
    return obj;
  },

  create<I extends Exact<DeepPartial<ProfileRes>, I>>(base?: I): ProfileRes {
    return ProfileRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProfileRes>, I>>(object: I): ProfileRes {
    const message = createBaseProfileRes();
    message.first_name = object.first_name ?? "";
    message.last_name = object.last_name ?? "";
    message.birthdate = object.birthdate ?? "";
    message.suffix = object.suffix ?? undefined;
    message.email = object.email ?? undefined;
    message.phone_number = object.phone_number ?? "";
    message.email_verified = object.email_verified ?? false;
    message.phone_number_verified = object.phone_number_verified ?? false;
    message.is_federated = object.is_federated ?? false;
    message.invitation_point = object.invitation_point ?? 0;
    message.sin = object.sin ?? undefined;
    message.profile_image = object.profile_image ?? undefined;
    message.profile_verification = (object.profile_verification !== undefined && object.profile_verification !== null)
      ? ProfileVerificationRes.fromPartial(object.profile_verification)
      : undefined;
    message.can_be_provider = object.can_be_provider ?? false;
    message.can_be_investor = object.can_be_investor ?? false;
    message.can_be_borrower = object.can_be_borrower ?? false;
    message.is_provider = object.is_provider ?? false;
    message.is_investor = object.is_investor ?? false;
    message.is_borrower = object.is_borrower ?? false;
    message.is_admin = object.is_admin ?? false;
    message.user_id = object.user_id ?? 0;
    message.total_invitation_point = object.total_invitation_point ?? 0;
    return message;
  },
};

function createBaseProfileVerificationRes(): ProfileVerificationRes {
  return { birthdate_status: "", phone_number_status: "", name_status: "", id_number_status: "" };
}

export const ProfileVerificationRes = {
  encode(message: ProfileVerificationRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.birthdate_status !== "") {
      writer.uint32(10).string(message.birthdate_status);
    }
    if (message.phone_number_status !== "") {
      writer.uint32(18).string(message.phone_number_status);
    }
    if (message.name_status !== "") {
      writer.uint32(26).string(message.name_status);
    }
    if (message.id_number_status !== "") {
      writer.uint32(34).string(message.id_number_status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProfileVerificationRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProfileVerificationRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.birthdate_status = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.phone_number_status = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name_status = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.id_number_status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProfileVerificationRes {
    return {
      birthdate_status: isSet(object.birthdate_status) ? String(object.birthdate_status) : "",
      phone_number_status: isSet(object.phone_number_status) ? String(object.phone_number_status) : "",
      name_status: isSet(object.name_status) ? String(object.name_status) : "",
      id_number_status: isSet(object.id_number_status) ? String(object.id_number_status) : "",
    };
  },

  toJSON(message: ProfileVerificationRes): unknown {
    const obj: any = {};
    message.birthdate_status !== undefined && (obj.birthdate_status = message.birthdate_status);
    message.phone_number_status !== undefined && (obj.phone_number_status = message.phone_number_status);
    message.name_status !== undefined && (obj.name_status = message.name_status);
    message.id_number_status !== undefined && (obj.id_number_status = message.id_number_status);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProfileVerificationRes>, I>>(base?: I): ProfileVerificationRes {
    return ProfileVerificationRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProfileVerificationRes>, I>>(object: I): ProfileVerificationRes {
    const message = createBaseProfileVerificationRes();
    message.birthdate_status = object.birthdate_status ?? "";
    message.phone_number_status = object.phone_number_status ?? "";
    message.name_status = object.name_status ?? "";
    message.id_number_status = object.id_number_status ?? "";
    return message;
  },
};

function createBaseProfileReq(): ProfileReq {
  return {
    first_name: "",
    last_name: "",
    birthdate: "",
    suffix: undefined,
    email: undefined,
    email_verified: undefined,
    phone_number_verified: undefined,
    sin: undefined,
    profile_image: undefined,
  };
}

export const ProfileReq = {
  encode(message: ProfileReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.first_name !== "") {
      writer.uint32(10).string(message.first_name);
    }
    if (message.last_name !== "") {
      writer.uint32(18).string(message.last_name);
    }
    if (message.birthdate !== "") {
      writer.uint32(26).string(message.birthdate);
    }
    if (message.suffix !== undefined) {
      writer.uint32(34).string(message.suffix);
    }
    if (message.email !== undefined) {
      writer.uint32(42).string(message.email);
    }
    if (message.email_verified !== undefined) {
      writer.uint32(48).bool(message.email_verified);
    }
    if (message.phone_number_verified !== undefined) {
      writer.uint32(56).bool(message.phone_number_verified);
    }
    if (message.sin !== undefined) {
      writer.uint32(66).string(message.sin);
    }
    if (message.profile_image !== undefined) {
      writer.uint32(74).string(message.profile_image);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProfileReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProfileReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.first_name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.last_name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.birthdate = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.suffix = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.email = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.email_verified = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.phone_number_verified = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.sin = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.profile_image = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProfileReq {
    return {
      first_name: isSet(object.first_name) ? String(object.first_name) : "",
      last_name: isSet(object.last_name) ? String(object.last_name) : "",
      birthdate: isSet(object.birthdate) ? String(object.birthdate) : "",
      suffix: isSet(object.suffix) ? String(object.suffix) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      email_verified: isSet(object.email_verified) ? Boolean(object.email_verified) : undefined,
      phone_number_verified: isSet(object.phone_number_verified) ? Boolean(object.phone_number_verified) : undefined,
      sin: isSet(object.sin) ? String(object.sin) : undefined,
      profile_image: isSet(object.profile_image) ? String(object.profile_image) : undefined,
    };
  },

  toJSON(message: ProfileReq): unknown {
    const obj: any = {};
    message.first_name !== undefined && (obj.first_name = message.first_name);
    message.last_name !== undefined && (obj.last_name = message.last_name);
    message.birthdate !== undefined && (obj.birthdate = message.birthdate);
    message.suffix !== undefined && (obj.suffix = message.suffix);
    message.email !== undefined && (obj.email = message.email);
    message.email_verified !== undefined && (obj.email_verified = message.email_verified);
    message.phone_number_verified !== undefined && (obj.phone_number_verified = message.phone_number_verified);
    message.sin !== undefined && (obj.sin = message.sin);
    message.profile_image !== undefined && (obj.profile_image = message.profile_image);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProfileReq>, I>>(base?: I): ProfileReq {
    return ProfileReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProfileReq>, I>>(object: I): ProfileReq {
    const message = createBaseProfileReq();
    message.first_name = object.first_name ?? "";
    message.last_name = object.last_name ?? "";
    message.birthdate = object.birthdate ?? "";
    message.suffix = object.suffix ?? undefined;
    message.email = object.email ?? undefined;
    message.email_verified = object.email_verified ?? undefined;
    message.phone_number_verified = object.phone_number_verified ?? undefined;
    message.sin = object.sin ?? undefined;
    message.profile_image = object.profile_image ?? undefined;
    return message;
  },
};

function createBaseUpdateProfileReq(): UpdateProfileReq {
  return {
    iam: undefined,
    first_name: "",
    last_name: "",
    birthdate: "",
    suffix: undefined,
    isRegister: undefined,
    sin: "",
    profile_image: "",
  };
}

export const UpdateProfileReq = {
  encode(message: UpdateProfileReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.first_name !== "") {
      writer.uint32(18).string(message.first_name);
    }
    if (message.last_name !== "") {
      writer.uint32(26).string(message.last_name);
    }
    if (message.birthdate !== "") {
      writer.uint32(34).string(message.birthdate);
    }
    if (message.suffix !== undefined) {
      writer.uint32(42).string(message.suffix);
    }
    if (message.isRegister !== undefined) {
      writer.uint32(48).bool(message.isRegister);
    }
    if (message.sin !== "") {
      writer.uint32(58).string(message.sin);
    }
    if (message.profile_image !== "") {
      writer.uint32(66).string(message.profile_image);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateProfileReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateProfileReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.first_name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.last_name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.birthdate = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.suffix = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isRegister = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.sin = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.profile_image = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateProfileReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      first_name: isSet(object.first_name) ? String(object.first_name) : "",
      last_name: isSet(object.last_name) ? String(object.last_name) : "",
      birthdate: isSet(object.birthdate) ? String(object.birthdate) : "",
      suffix: isSet(object.suffix) ? String(object.suffix) : undefined,
      isRegister: isSet(object.isRegister) ? Boolean(object.isRegister) : undefined,
      sin: isSet(object.sin) ? String(object.sin) : "",
      profile_image: isSet(object.profile_image) ? String(object.profile_image) : "",
    };
  },

  toJSON(message: UpdateProfileReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.first_name !== undefined && (obj.first_name = message.first_name);
    message.last_name !== undefined && (obj.last_name = message.last_name);
    message.birthdate !== undefined && (obj.birthdate = message.birthdate);
    message.suffix !== undefined && (obj.suffix = message.suffix);
    message.isRegister !== undefined && (obj.isRegister = message.isRegister);
    message.sin !== undefined && (obj.sin = message.sin);
    message.profile_image !== undefined && (obj.profile_image = message.profile_image);
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateProfileReq>, I>>(base?: I): UpdateProfileReq {
    return UpdateProfileReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateProfileReq>, I>>(object: I): UpdateProfileReq {
    const message = createBaseUpdateProfileReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.first_name = object.first_name ?? "";
    message.last_name = object.last_name ?? "";
    message.birthdate = object.birthdate ?? "";
    message.suffix = object.suffix ?? undefined;
    message.isRegister = object.isRegister ?? undefined;
    message.sin = object.sin ?? "";
    message.profile_image = object.profile_image ?? "";
    return message;
  },
};

function createBaseUpdatePhoneNumberReq(): UpdatePhoneNumberReq {
  return { iam: undefined, phone_number: "" };
}

export const UpdatePhoneNumberReq = {
  encode(message: UpdatePhoneNumberReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.phone_number !== "") {
      writer.uint32(18).string(message.phone_number);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdatePhoneNumberReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdatePhoneNumberReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.phone_number = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdatePhoneNumberReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      phone_number: isSet(object.phone_number) ? String(object.phone_number) : "",
    };
  },

  toJSON(message: UpdatePhoneNumberReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdatePhoneNumberReq>, I>>(base?: I): UpdatePhoneNumberReq {
    return UpdatePhoneNumberReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdatePhoneNumberReq>, I>>(object: I): UpdatePhoneNumberReq {
    const message = createBaseUpdatePhoneNumberReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.phone_number = object.phone_number ?? "";
    return message;
  },
};

function createBaseVerifyPhoneNumberReq(): VerifyPhoneNumberReq {
  return { iam: undefined, code: "", phone_number: "" };
}

export const VerifyPhoneNumberReq = {
  encode(message: VerifyPhoneNumberReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.code !== "") {
      writer.uint32(18).string(message.code);
    }
    if (message.phone_number !== "") {
      writer.uint32(26).string(message.phone_number);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifyPhoneNumberReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifyPhoneNumberReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.phone_number = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VerifyPhoneNumberReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      code: isSet(object.code) ? String(object.code) : "",
      phone_number: isSet(object.phone_number) ? String(object.phone_number) : "",
    };
  },

  toJSON(message: VerifyPhoneNumberReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.code !== undefined && (obj.code = message.code);
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    return obj;
  },

  create<I extends Exact<DeepPartial<VerifyPhoneNumberReq>, I>>(base?: I): VerifyPhoneNumberReq {
    return VerifyPhoneNumberReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VerifyPhoneNumberReq>, I>>(object: I): VerifyPhoneNumberReq {
    const message = createBaseVerifyPhoneNumberReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.code = object.code ?? "";
    message.phone_number = object.phone_number ?? "";
    return message;
  },
};

function createBaseUpdateEmailReq(): UpdateEmailReq {
  return { iam: undefined, email: "" };
}

export const UpdateEmailReq = {
  encode(message: UpdateEmailReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEmailReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmailReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEmailReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      email: isSet(object.email) ? String(object.email) : "",
    };
  },

  toJSON(message: UpdateEmailReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.email !== undefined && (obj.email = message.email);
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEmailReq>, I>>(base?: I): UpdateEmailReq {
    return UpdateEmailReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateEmailReq>, I>>(object: I): UpdateEmailReq {
    const message = createBaseUpdateEmailReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.email = object.email ?? "";
    return message;
  },
};

function createBaseVerifyEmailReq(): VerifyEmailReq {
  return { iam: undefined, code: "", email: "" };
}

export const VerifyEmailReq = {
  encode(message: VerifyEmailReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.code !== "") {
      writer.uint32(18).string(message.code);
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifyEmailReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifyEmailReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VerifyEmailReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      code: isSet(object.code) ? String(object.code) : "",
      email: isSet(object.email) ? String(object.email) : "",
    };
  },

  toJSON(message: VerifyEmailReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.code !== undefined && (obj.code = message.code);
    message.email !== undefined && (obj.email = message.email);
    return obj;
  },

  create<I extends Exact<DeepPartial<VerifyEmailReq>, I>>(base?: I): VerifyEmailReq {
    return VerifyEmailReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VerifyEmailReq>, I>>(object: I): VerifyEmailReq {
    const message = createBaseVerifyEmailReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.code = object.code ?? "";
    message.email = object.email ?? "";
    return message;
  },
};

function createBaseAddressRes(): AddressRes {
  return {
    home_address_1: "",
    home_address_2: undefined,
    city: "",
    state: undefined,
    zip: "",
    country: undefined,
    verification_status: undefined,
  };
}

export const AddressRes = {
  encode(message: AddressRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.home_address_1 !== "") {
      writer.uint32(10).string(message.home_address_1);
    }
    if (message.home_address_2 !== undefined) {
      writer.uint32(18).string(message.home_address_2);
    }
    if (message.city !== "") {
      writer.uint32(26).string(message.city);
    }
    if (message.state !== undefined) {
      State.encode(message.state, writer.uint32(34).fork()).ldelim();
    }
    if (message.zip !== "") {
      writer.uint32(42).string(message.zip);
    }
    if (message.country !== undefined) {
      CountryForAddress.encode(message.country, writer.uint32(50).fork()).ldelim();
    }
    if (message.verification_status !== undefined) {
      AddressVerificationRes.encode(message.verification_status, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddressRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddressRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.home_address_1 = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.home_address_2 = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.city = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.state = State.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.zip = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.country = CountryForAddress.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.verification_status = AddressVerificationRes.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddressRes {
    return {
      home_address_1: isSet(object.home_address_1) ? String(object.home_address_1) : "",
      home_address_2: isSet(object.home_address_2) ? String(object.home_address_2) : undefined,
      city: isSet(object.city) ? String(object.city) : "",
      state: isSet(object.state) ? State.fromJSON(object.state) : undefined,
      zip: isSet(object.zip) ? String(object.zip) : "",
      country: isSet(object.country) ? CountryForAddress.fromJSON(object.country) : undefined,
      verification_status: isSet(object.verification_status)
        ? AddressVerificationRes.fromJSON(object.verification_status)
        : undefined,
    };
  },

  toJSON(message: AddressRes): unknown {
    const obj: any = {};
    message.home_address_1 !== undefined && (obj.home_address_1 = message.home_address_1);
    message.home_address_2 !== undefined && (obj.home_address_2 = message.home_address_2);
    message.city !== undefined && (obj.city = message.city);
    message.state !== undefined && (obj.state = message.state ? State.toJSON(message.state) : undefined);
    message.zip !== undefined && (obj.zip = message.zip);
    message.country !== undefined &&
      (obj.country = message.country ? CountryForAddress.toJSON(message.country) : undefined);
    message.verification_status !== undefined && (obj.verification_status = message.verification_status
      ? AddressVerificationRes.toJSON(message.verification_status)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AddressRes>, I>>(base?: I): AddressRes {
    return AddressRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AddressRes>, I>>(object: I): AddressRes {
    const message = createBaseAddressRes();
    message.home_address_1 = object.home_address_1 ?? "";
    message.home_address_2 = object.home_address_2 ?? undefined;
    message.city = object.city ?? "";
    message.state = (object.state !== undefined && object.state !== null) ? State.fromPartial(object.state) : undefined;
    message.zip = object.zip ?? "";
    message.country = (object.country !== undefined && object.country !== null)
      ? CountryForAddress.fromPartial(object.country)
      : undefined;
    message.verification_status = (object.verification_status !== undefined && object.verification_status !== null)
      ? AddressVerificationRes.fromPartial(object.verification_status)
      : undefined;
    return message;
  },
};

function createBaseAddressVerificationRes(): AddressVerificationRes {
  return { address_status: "" };
}

export const AddressVerificationRes = {
  encode(message: AddressVerificationRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address_status !== "") {
      writer.uint32(10).string(message.address_status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddressVerificationRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddressVerificationRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address_status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddressVerificationRes {
    return { address_status: isSet(object.address_status) ? String(object.address_status) : "" };
  },

  toJSON(message: AddressVerificationRes): unknown {
    const obj: any = {};
    message.address_status !== undefined && (obj.address_status = message.address_status);
    return obj;
  },

  create<I extends Exact<DeepPartial<AddressVerificationRes>, I>>(base?: I): AddressVerificationRes {
    return AddressVerificationRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AddressVerificationRes>, I>>(object: I): AddressVerificationRes {
    const message = createBaseAddressVerificationRes();
    message.address_status = object.address_status ?? "";
    return message;
  },
};

function createBaseUpdateAddressReq(): UpdateAddressReq {
  return {
    iam: undefined,
    home_address_1: "",
    home_address_2: undefined,
    city: "",
    state: "",
    zip: "",
    country_id: undefined,
  };
}

export const UpdateAddressReq = {
  encode(message: UpdateAddressReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.home_address_1 !== "") {
      writer.uint32(18).string(message.home_address_1);
    }
    if (message.home_address_2 !== undefined) {
      writer.uint32(26).string(message.home_address_2);
    }
    if (message.city !== "") {
      writer.uint32(34).string(message.city);
    }
    if (message.state !== "") {
      writer.uint32(42).string(message.state);
    }
    if (message.zip !== "") {
      writer.uint32(50).string(message.zip);
    }
    if (message.country_id !== undefined) {
      writer.uint32(56).int64(message.country_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAddressReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAddressReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.home_address_1 = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.home_address_2 = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.city = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.state = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.zip = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.country_id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAddressReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      home_address_1: isSet(object.home_address_1) ? String(object.home_address_1) : "",
      home_address_2: isSet(object.home_address_2) ? String(object.home_address_2) : undefined,
      city: isSet(object.city) ? String(object.city) : "",
      state: isSet(object.state) ? String(object.state) : "",
      zip: isSet(object.zip) ? String(object.zip) : "",
      country_id: isSet(object.country_id) ? Number(object.country_id) : undefined,
    };
  },

  toJSON(message: UpdateAddressReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.home_address_1 !== undefined && (obj.home_address_1 = message.home_address_1);
    message.home_address_2 !== undefined && (obj.home_address_2 = message.home_address_2);
    message.city !== undefined && (obj.city = message.city);
    message.state !== undefined && (obj.state = message.state);
    message.zip !== undefined && (obj.zip = message.zip);
    message.country_id !== undefined && (obj.country_id = Math.round(message.country_id));
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAddressReq>, I>>(base?: I): UpdateAddressReq {
    return UpdateAddressReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateAddressReq>, I>>(object: I): UpdateAddressReq {
    const message = createBaseUpdateAddressReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.home_address_1 = object.home_address_1 ?? "";
    message.home_address_2 = object.home_address_2 ?? undefined;
    message.city = object.city ?? "";
    message.state = object.state ?? "";
    message.zip = object.zip ?? "";
    message.country_id = object.country_id ?? undefined;
    return message;
  },
};

function createBaseSetUserMFAPreferenceReq(): SetUserMFAPreferenceReq {
  return {
    iam: undefined,
    enable_sms: undefined,
    preferred_sms: undefined,
    enable_software_token: undefined,
    preferred_software_token: undefined,
  };
}

export const SetUserMFAPreferenceReq = {
  encode(message: SetUserMFAPreferenceReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.enable_sms !== undefined) {
      writer.uint32(16).bool(message.enable_sms);
    }
    if (message.preferred_sms !== undefined) {
      writer.uint32(24).bool(message.preferred_sms);
    }
    if (message.enable_software_token !== undefined) {
      writer.uint32(32).bool(message.enable_software_token);
    }
    if (message.preferred_software_token !== undefined) {
      writer.uint32(40).bool(message.preferred_software_token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetUserMFAPreferenceReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetUserMFAPreferenceReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enable_sms = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.preferred_sms = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enable_software_token = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.preferred_software_token = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetUserMFAPreferenceReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      enable_sms: isSet(object.enable_sms) ? Boolean(object.enable_sms) : undefined,
      preferred_sms: isSet(object.preferred_sms) ? Boolean(object.preferred_sms) : undefined,
      enable_software_token: isSet(object.enable_software_token) ? Boolean(object.enable_software_token) : undefined,
      preferred_software_token: isSet(object.preferred_software_token)
        ? Boolean(object.preferred_software_token)
        : undefined,
    };
  },

  toJSON(message: SetUserMFAPreferenceReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.enable_sms !== undefined && (obj.enable_sms = message.enable_sms);
    message.preferred_sms !== undefined && (obj.preferred_sms = message.preferred_sms);
    message.enable_software_token !== undefined && (obj.enable_software_token = message.enable_software_token);
    message.preferred_software_token !== undefined && (obj.preferred_software_token = message.preferred_software_token);
    return obj;
  },

  create<I extends Exact<DeepPartial<SetUserMFAPreferenceReq>, I>>(base?: I): SetUserMFAPreferenceReq {
    return SetUserMFAPreferenceReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetUserMFAPreferenceReq>, I>>(object: I): SetUserMFAPreferenceReq {
    const message = createBaseSetUserMFAPreferenceReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.enable_sms = object.enable_sms ?? undefined;
    message.preferred_sms = object.preferred_sms ?? undefined;
    message.enable_software_token = object.enable_software_token ?? undefined;
    message.preferred_software_token = object.preferred_software_token ?? undefined;
    return message;
  },
};

function createBaseGetUserMFASettingReq(): GetUserMFASettingReq {
  return { iam: undefined };
}

export const GetUserMFASettingReq = {
  encode(message: GetUserMFASettingReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserMFASettingReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserMFASettingReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserMFASettingReq {
    return { iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined };
  },

  toJSON(message: GetUserMFASettingReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserMFASettingReq>, I>>(base?: I): GetUserMFASettingReq {
    return GetUserMFASettingReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetUserMFASettingReq>, I>>(object: I): GetUserMFASettingReq {
    const message = createBaseGetUserMFASettingReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    return message;
  },
};

function createBaseGetUserMFASettingRes(): GetUserMFASettingRes {
  return { enable_sms: false, preferred_sms: false, enable_software_token: false, preferred_software_token: false };
}

export const GetUserMFASettingRes = {
  encode(message: GetUserMFASettingRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enable_sms === true) {
      writer.uint32(16).bool(message.enable_sms);
    }
    if (message.preferred_sms === true) {
      writer.uint32(24).bool(message.preferred_sms);
    }
    if (message.enable_software_token === true) {
      writer.uint32(32).bool(message.enable_software_token);
    }
    if (message.preferred_software_token === true) {
      writer.uint32(40).bool(message.preferred_software_token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserMFASettingRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserMFASettingRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enable_sms = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.preferred_sms = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enable_software_token = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.preferred_software_token = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserMFASettingRes {
    return {
      enable_sms: isSet(object.enable_sms) ? Boolean(object.enable_sms) : false,
      preferred_sms: isSet(object.preferred_sms) ? Boolean(object.preferred_sms) : false,
      enable_software_token: isSet(object.enable_software_token) ? Boolean(object.enable_software_token) : false,
      preferred_software_token: isSet(object.preferred_software_token)
        ? Boolean(object.preferred_software_token)
        : false,
    };
  },

  toJSON(message: GetUserMFASettingRes): unknown {
    const obj: any = {};
    message.enable_sms !== undefined && (obj.enable_sms = message.enable_sms);
    message.preferred_sms !== undefined && (obj.preferred_sms = message.preferred_sms);
    message.enable_software_token !== undefined && (obj.enable_software_token = message.enable_software_token);
    message.preferred_software_token !== undefined && (obj.preferred_software_token = message.preferred_software_token);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserMFASettingRes>, I>>(base?: I): GetUserMFASettingRes {
    return GetUserMFASettingRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetUserMFASettingRes>, I>>(object: I): GetUserMFASettingRes {
    const message = createBaseGetUserMFASettingRes();
    message.enable_sms = object.enable_sms ?? false;
    message.preferred_sms = object.preferred_sms ?? false;
    message.enable_software_token = object.enable_software_token ?? false;
    message.preferred_software_token = object.preferred_software_token ?? false;
    return message;
  },
};

function createBaseAssociateSoftwareTokenReq(): AssociateSoftwareTokenReq {
  return { iam: undefined };
}

export const AssociateSoftwareTokenReq = {
  encode(message: AssociateSoftwareTokenReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssociateSoftwareTokenReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssociateSoftwareTokenReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssociateSoftwareTokenReq {
    return { iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined };
  },

  toJSON(message: AssociateSoftwareTokenReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AssociateSoftwareTokenReq>, I>>(base?: I): AssociateSoftwareTokenReq {
    return AssociateSoftwareTokenReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AssociateSoftwareTokenReq>, I>>(object: I): AssociateSoftwareTokenReq {
    const message = createBaseAssociateSoftwareTokenReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    return message;
  },
};

function createBaseAssociateSoftwareTokenRes(): AssociateSoftwareTokenRes {
  return { secret_code: "" };
}

export const AssociateSoftwareTokenRes = {
  encode(message: AssociateSoftwareTokenRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.secret_code !== "") {
      writer.uint32(10).string(message.secret_code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssociateSoftwareTokenRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssociateSoftwareTokenRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.secret_code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssociateSoftwareTokenRes {
    return { secret_code: isSet(object.secret_code) ? String(object.secret_code) : "" };
  },

  toJSON(message: AssociateSoftwareTokenRes): unknown {
    const obj: any = {};
    message.secret_code !== undefined && (obj.secret_code = message.secret_code);
    return obj;
  },

  create<I extends Exact<DeepPartial<AssociateSoftwareTokenRes>, I>>(base?: I): AssociateSoftwareTokenRes {
    return AssociateSoftwareTokenRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AssociateSoftwareTokenRes>, I>>(object: I): AssociateSoftwareTokenRes {
    const message = createBaseAssociateSoftwareTokenRes();
    message.secret_code = object.secret_code ?? "";
    return message;
  },
};

function createBaseVerifySoftwareTokenReq(): VerifySoftwareTokenReq {
  return { iam: undefined, code: "" };
}

export const VerifySoftwareTokenReq = {
  encode(message: VerifySoftwareTokenReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.code !== "") {
      writer.uint32(26).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifySoftwareTokenReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifySoftwareTokenReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VerifySoftwareTokenReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      code: isSet(object.code) ? String(object.code) : "",
    };
  },

  toJSON(message: VerifySoftwareTokenReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.code !== undefined && (obj.code = message.code);
    return obj;
  },

  create<I extends Exact<DeepPartial<VerifySoftwareTokenReq>, I>>(base?: I): VerifySoftwareTokenReq {
    return VerifySoftwareTokenReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VerifySoftwareTokenReq>, I>>(object: I): VerifySoftwareTokenReq {
    const message = createBaseVerifySoftwareTokenReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseVerifySoftwareTokenRes(): VerifySoftwareTokenRes {
  return { success: false };
}

export const VerifySoftwareTokenRes = {
  encode(message: VerifySoftwareTokenRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.success === true) {
      writer.uint32(8).bool(message.success);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifySoftwareTokenRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifySoftwareTokenRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.success = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VerifySoftwareTokenRes {
    return { success: isSet(object.success) ? Boolean(object.success) : false };
  },

  toJSON(message: VerifySoftwareTokenRes): unknown {
    const obj: any = {};
    message.success !== undefined && (obj.success = message.success);
    return obj;
  },

  create<I extends Exact<DeepPartial<VerifySoftwareTokenRes>, I>>(base?: I): VerifySoftwareTokenRes {
    return VerifySoftwareTokenRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VerifySoftwareTokenRes>, I>>(object: I): VerifySoftwareTokenRes {
    const message = createBaseVerifySoftwareTokenRes();
    message.success = object.success ?? false;
    return message;
  },
};

function createBaseUserCompletionStatusRes(): UserCompletionStatusRes {
  return { profile_completion_necessary: false, address_completion_necessary: false, unconfirmed: false };
}

export const UserCompletionStatusRes = {
  encode(message: UserCompletionStatusRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.profile_completion_necessary === true) {
      writer.uint32(8).bool(message.profile_completion_necessary);
    }
    if (message.address_completion_necessary === true) {
      writer.uint32(16).bool(message.address_completion_necessary);
    }
    if (message.unconfirmed === true) {
      writer.uint32(24).bool(message.unconfirmed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserCompletionStatusRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserCompletionStatusRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.profile_completion_necessary = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.address_completion_necessary = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.unconfirmed = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserCompletionStatusRes {
    return {
      profile_completion_necessary: isSet(object.profile_completion_necessary)
        ? Boolean(object.profile_completion_necessary)
        : false,
      address_completion_necessary: isSet(object.address_completion_necessary)
        ? Boolean(object.address_completion_necessary)
        : false,
      unconfirmed: isSet(object.unconfirmed) ? Boolean(object.unconfirmed) : false,
    };
  },

  toJSON(message: UserCompletionStatusRes): unknown {
    const obj: any = {};
    message.profile_completion_necessary !== undefined &&
      (obj.profile_completion_necessary = message.profile_completion_necessary);
    message.address_completion_necessary !== undefined &&
      (obj.address_completion_necessary = message.address_completion_necessary);
    message.unconfirmed !== undefined && (obj.unconfirmed = message.unconfirmed);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserCompletionStatusRes>, I>>(base?: I): UserCompletionStatusRes {
    return UserCompletionStatusRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserCompletionStatusRes>, I>>(object: I): UserCompletionStatusRes {
    const message = createBaseUserCompletionStatusRes();
    message.profile_completion_necessary = object.profile_completion_necessary ?? false;
    message.address_completion_necessary = object.address_completion_necessary ?? false;
    message.unconfirmed = object.unconfirmed ?? false;
    return message;
  },
};

function createBaseSignOutReq(): SignOutReq {
  return { refresh_token: "" };
}

export const SignOutReq = {
  encode(message: SignOutReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.refresh_token !== "") {
      writer.uint32(10).string(message.refresh_token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignOutReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignOutReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.refresh_token = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SignOutReq {
    return { refresh_token: isSet(object.refresh_token) ? String(object.refresh_token) : "" };
  },

  toJSON(message: SignOutReq): unknown {
    const obj: any = {};
    message.refresh_token !== undefined && (obj.refresh_token = message.refresh_token);
    return obj;
  },

  create<I extends Exact<DeepPartial<SignOutReq>, I>>(base?: I): SignOutReq {
    return SignOutReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SignOutReq>, I>>(object: I): SignOutReq {
    const message = createBaseSignOutReq();
    message.refresh_token = object.refresh_token ?? "";
    return message;
  },
};

function createBaseAdminGetListUsersReq(): AdminGetListUsersReq {
  return {
    page_index: undefined,
    page_size: undefined,
    email: undefined,
    country_id: undefined,
    phone_number: undefined,
    first_name: undefined,
    last_name: undefined,
    email_verified: undefined,
    phone_number_verified: undefined,
    full_name: undefined,
  };
}

export const AdminGetListUsersReq = {
  encode(message: AdminGetListUsersReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.page_index !== undefined) {
      writer.uint32(8).int32(message.page_index);
    }
    if (message.page_size !== undefined) {
      writer.uint32(16).int32(message.page_size);
    }
    if (message.email !== undefined) {
      writer.uint32(26).string(message.email);
    }
    if (message.country_id !== undefined) {
      writer.uint32(32).int32(message.country_id);
    }
    if (message.phone_number !== undefined) {
      writer.uint32(42).string(message.phone_number);
    }
    if (message.first_name !== undefined) {
      writer.uint32(50).string(message.first_name);
    }
    if (message.last_name !== undefined) {
      writer.uint32(58).string(message.last_name);
    }
    if (message.email_verified !== undefined) {
      writer.uint32(64).bool(message.email_verified);
    }
    if (message.phone_number_verified !== undefined) {
      writer.uint32(72).bool(message.phone_number_verified);
    }
    if (message.full_name !== undefined) {
      writer.uint32(82).string(message.full_name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetListUsersReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetListUsersReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.page_index = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.page_size = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.country_id = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.phone_number = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.first_name = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.last_name = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.email_verified = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.phone_number_verified = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.full_name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetListUsersReq {
    return {
      page_index: isSet(object.page_index) ? Number(object.page_index) : undefined,
      page_size: isSet(object.page_size) ? Number(object.page_size) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      country_id: isSet(object.country_id) ? Number(object.country_id) : undefined,
      phone_number: isSet(object.phone_number) ? String(object.phone_number) : undefined,
      first_name: isSet(object.first_name) ? String(object.first_name) : undefined,
      last_name: isSet(object.last_name) ? String(object.last_name) : undefined,
      email_verified: isSet(object.email_verified) ? Boolean(object.email_verified) : undefined,
      phone_number_verified: isSet(object.phone_number_verified) ? Boolean(object.phone_number_verified) : undefined,
      full_name: isSet(object.full_name) ? String(object.full_name) : undefined,
    };
  },

  toJSON(message: AdminGetListUsersReq): unknown {
    const obj: any = {};
    message.page_index !== undefined && (obj.page_index = Math.round(message.page_index));
    message.page_size !== undefined && (obj.page_size = Math.round(message.page_size));
    message.email !== undefined && (obj.email = message.email);
    message.country_id !== undefined && (obj.country_id = Math.round(message.country_id));
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    message.first_name !== undefined && (obj.first_name = message.first_name);
    message.last_name !== undefined && (obj.last_name = message.last_name);
    message.email_verified !== undefined && (obj.email_verified = message.email_verified);
    message.phone_number_verified !== undefined && (obj.phone_number_verified = message.phone_number_verified);
    message.full_name !== undefined && (obj.full_name = message.full_name);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetListUsersReq>, I>>(base?: I): AdminGetListUsersReq {
    return AdminGetListUsersReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetListUsersReq>, I>>(object: I): AdminGetListUsersReq {
    const message = createBaseAdminGetListUsersReq();
    message.page_index = object.page_index ?? undefined;
    message.page_size = object.page_size ?? undefined;
    message.email = object.email ?? undefined;
    message.country_id = object.country_id ?? undefined;
    message.phone_number = object.phone_number ?? undefined;
    message.first_name = object.first_name ?? undefined;
    message.last_name = object.last_name ?? undefined;
    message.email_verified = object.email_verified ?? undefined;
    message.phone_number_verified = object.phone_number_verified ?? undefined;
    message.full_name = object.full_name ?? undefined;
    return message;
  },
};

function createBaseAdminUsersRes(): AdminUsersRes {
  return { users: [], total_counts: 0 };
}

export const AdminUsersRes = {
  encode(message: AdminUsersRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      GetAllUserInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.total_counts !== 0) {
      writer.uint32(16).int64(message.total_counts);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminUsersRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminUsersRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(GetAllUserInfo.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.total_counts = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminUsersRes {
    return {
      users: Array.isArray(object?.users) ? object.users.map((e: any) => GetAllUserInfo.fromJSON(e)) : [],
      total_counts: isSet(object.total_counts) ? Number(object.total_counts) : 0,
    };
  },

  toJSON(message: AdminUsersRes): unknown {
    const obj: any = {};
    if (message.users) {
      obj.users = message.users.map((e) => e ? GetAllUserInfo.toJSON(e) : undefined);
    } else {
      obj.users = [];
    }
    message.total_counts !== undefined && (obj.total_counts = Math.round(message.total_counts));
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminUsersRes>, I>>(base?: I): AdminUsersRes {
    return AdminUsersRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminUsersRes>, I>>(object: I): AdminUsersRes {
    const message = createBaseAdminUsersRes();
    message.users = object.users?.map((e) => GetAllUserInfo.fromPartial(e)) || [];
    message.total_counts = object.total_counts ?? 0;
    return message;
  },
};

function createBaseGetAllUserInfo(): GetAllUserInfo {
  return {
    user_id: 0,
    username: "",
    profile_info: undefined,
    create_date: "",
    user_last_modified_date: "",
    enable: false,
    user_status: "",
    role_ids: [],
    invitation_point: 0,
  };
}

export const GetAllUserInfo = {
  encode(message: GetAllUserInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    if (message.username !== "") {
      writer.uint32(18).string(message.username);
    }
    if (message.profile_info !== undefined) {
      GetAllUserProfile.encode(message.profile_info, writer.uint32(26).fork()).ldelim();
    }
    if (message.create_date !== "") {
      writer.uint32(42).string(message.create_date);
    }
    if (message.user_last_modified_date !== "") {
      writer.uint32(50).string(message.user_last_modified_date);
    }
    if (message.enable === true) {
      writer.uint32(56).bool(message.enable);
    }
    if (message.user_status !== "") {
      writer.uint32(66).string(message.user_status);
    }
    writer.uint32(82).fork();
    for (const v of message.role_ids) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.invitation_point !== 0) {
      writer.uint32(88).int64(message.invitation_point);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllUserInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllUserInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.username = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.profile_info = GetAllUserProfile.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.create_date = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.user_last_modified_date = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.enable = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.user_status = reader.string();
          continue;
        case 10:
          if (tag === 80) {
            message.role_ids.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.role_ids.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.invitation_point = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllUserInfo {
    return {
      user_id: isSet(object.user_id) ? Number(object.user_id) : 0,
      username: isSet(object.username) ? String(object.username) : "",
      profile_info: isSet(object.profile_info) ? GetAllUserProfile.fromJSON(object.profile_info) : undefined,
      create_date: isSet(object.create_date) ? String(object.create_date) : "",
      user_last_modified_date: isSet(object.user_last_modified_date) ? String(object.user_last_modified_date) : "",
      enable: isSet(object.enable) ? Boolean(object.enable) : false,
      user_status: isSet(object.user_status) ? String(object.user_status) : "",
      role_ids: Array.isArray(object?.role_ids) ? object.role_ids.map((e: any) => Number(e)) : [],
      invitation_point: isSet(object.invitation_point) ? Number(object.invitation_point) : 0,
    };
  },

  toJSON(message: GetAllUserInfo): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    message.username !== undefined && (obj.username = message.username);
    message.profile_info !== undefined &&
      (obj.profile_info = message.profile_info ? GetAllUserProfile.toJSON(message.profile_info) : undefined);
    message.create_date !== undefined && (obj.create_date = message.create_date);
    message.user_last_modified_date !== undefined && (obj.user_last_modified_date = message.user_last_modified_date);
    message.enable !== undefined && (obj.enable = message.enable);
    message.user_status !== undefined && (obj.user_status = message.user_status);
    if (message.role_ids) {
      obj.role_ids = message.role_ids.map((e) => Math.round(e));
    } else {
      obj.role_ids = [];
    }
    message.invitation_point !== undefined && (obj.invitation_point = Math.round(message.invitation_point));
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllUserInfo>, I>>(base?: I): GetAllUserInfo {
    return GetAllUserInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAllUserInfo>, I>>(object: I): GetAllUserInfo {
    const message = createBaseGetAllUserInfo();
    message.user_id = object.user_id ?? 0;
    message.username = object.username ?? "";
    message.profile_info = (object.profile_info !== undefined && object.profile_info !== null)
      ? GetAllUserProfile.fromPartial(object.profile_info)
      : undefined;
    message.create_date = object.create_date ?? "";
    message.user_last_modified_date = object.user_last_modified_date ?? "";
    message.enable = object.enable ?? false;
    message.user_status = object.user_status ?? "";
    message.role_ids = object.role_ids?.map((e) => e) || [];
    message.invitation_point = object.invitation_point ?? 0;
    return message;
  },
};

function createBaseUserInfo(): UserInfo {
  return {
    user_id: 0,
    username: "",
    profile_info: undefined,
    address_info: undefined,
    create_date: "",
    user_last_modified_date: "",
    enable: false,
    user_status: "",
    mFA_options_type: [],
    role_ids: [],
    user_sub: "",
    invitation_point: 0,
  };
}

export const UserInfo = {
  encode(message: UserInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    if (message.username !== "") {
      writer.uint32(18).string(message.username);
    }
    if (message.profile_info !== undefined) {
      ProfileRes.encode(message.profile_info, writer.uint32(26).fork()).ldelim();
    }
    if (message.address_info !== undefined) {
      AddressRes.encode(message.address_info, writer.uint32(34).fork()).ldelim();
    }
    if (message.create_date !== "") {
      writer.uint32(42).string(message.create_date);
    }
    if (message.user_last_modified_date !== "") {
      writer.uint32(50).string(message.user_last_modified_date);
    }
    if (message.enable === true) {
      writer.uint32(56).bool(message.enable);
    }
    if (message.user_status !== "") {
      writer.uint32(66).string(message.user_status);
    }
    for (const v of message.mFA_options_type) {
      AdminMFAOptionsType.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    writer.uint32(82).fork();
    for (const v of message.role_ids) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.user_sub !== "") {
      writer.uint32(90).string(message.user_sub);
    }
    if (message.invitation_point !== 0) {
      writer.uint32(96).int64(message.invitation_point);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.username = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.profile_info = ProfileRes.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.address_info = AddressRes.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.create_date = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.user_last_modified_date = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.enable = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.user_status = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.mFA_options_type.push(AdminMFAOptionsType.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag === 80) {
            message.role_ids.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.role_ids.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.user_sub = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.invitation_point = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserInfo {
    return {
      user_id: isSet(object.user_id) ? Number(object.user_id) : 0,
      username: isSet(object.username) ? String(object.username) : "",
      profile_info: isSet(object.profile_info) ? ProfileRes.fromJSON(object.profile_info) : undefined,
      address_info: isSet(object.address_info) ? AddressRes.fromJSON(object.address_info) : undefined,
      create_date: isSet(object.create_date) ? String(object.create_date) : "",
      user_last_modified_date: isSet(object.user_last_modified_date) ? String(object.user_last_modified_date) : "",
      enable: isSet(object.enable) ? Boolean(object.enable) : false,
      user_status: isSet(object.user_status) ? String(object.user_status) : "",
      mFA_options_type: Array.isArray(object?.mFA_options_type)
        ? object.mFA_options_type.map((e: any) => AdminMFAOptionsType.fromJSON(e))
        : [],
      role_ids: Array.isArray(object?.role_ids) ? object.role_ids.map((e: any) => Number(e)) : [],
      user_sub: isSet(object.user_sub) ? String(object.user_sub) : "",
      invitation_point: isSet(object.invitation_point) ? Number(object.invitation_point) : 0,
    };
  },

  toJSON(message: UserInfo): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    message.username !== undefined && (obj.username = message.username);
    message.profile_info !== undefined &&
      (obj.profile_info = message.profile_info ? ProfileRes.toJSON(message.profile_info) : undefined);
    message.address_info !== undefined &&
      (obj.address_info = message.address_info ? AddressRes.toJSON(message.address_info) : undefined);
    message.create_date !== undefined && (obj.create_date = message.create_date);
    message.user_last_modified_date !== undefined && (obj.user_last_modified_date = message.user_last_modified_date);
    message.enable !== undefined && (obj.enable = message.enable);
    message.user_status !== undefined && (obj.user_status = message.user_status);
    if (message.mFA_options_type) {
      obj.mFA_options_type = message.mFA_options_type.map((e) => e ? AdminMFAOptionsType.toJSON(e) : undefined);
    } else {
      obj.mFA_options_type = [];
    }
    if (message.role_ids) {
      obj.role_ids = message.role_ids.map((e) => Math.round(e));
    } else {
      obj.role_ids = [];
    }
    message.user_sub !== undefined && (obj.user_sub = message.user_sub);
    message.invitation_point !== undefined && (obj.invitation_point = Math.round(message.invitation_point));
    return obj;
  },

  create<I extends Exact<DeepPartial<UserInfo>, I>>(base?: I): UserInfo {
    return UserInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserInfo>, I>>(object: I): UserInfo {
    const message = createBaseUserInfo();
    message.user_id = object.user_id ?? 0;
    message.username = object.username ?? "";
    message.profile_info = (object.profile_info !== undefined && object.profile_info !== null)
      ? ProfileRes.fromPartial(object.profile_info)
      : undefined;
    message.address_info = (object.address_info !== undefined && object.address_info !== null)
      ? AddressRes.fromPartial(object.address_info)
      : undefined;
    message.create_date = object.create_date ?? "";
    message.user_last_modified_date = object.user_last_modified_date ?? "";
    message.enable = object.enable ?? false;
    message.user_status = object.user_status ?? "";
    message.mFA_options_type = object.mFA_options_type?.map((e) => AdminMFAOptionsType.fromPartial(e)) || [];
    message.role_ids = object.role_ids?.map((e) => e) || [];
    message.user_sub = object.user_sub ?? "";
    message.invitation_point = object.invitation_point ?? 0;
    return message;
  },
};

function createBaseAdminUpdateUserProfileReq(): AdminUpdateUserProfileReq {
  return { username: "", profile: undefined, address: undefined, role_ids: [] };
}

export const AdminUpdateUserProfileReq = {
  encode(message: AdminUpdateUserProfileReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    if (message.profile !== undefined) {
      ProfileRes.encode(message.profile, writer.uint32(18).fork()).ldelim();
    }
    if (message.address !== undefined) {
      UpdateAddressReq.encode(message.address, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).fork();
    for (const v of message.role_ids) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminUpdateUserProfileReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminUpdateUserProfileReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.profile = ProfileRes.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.address = UpdateAddressReq.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag === 32) {
            message.role_ids.push(reader.int32());

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.role_ids.push(reader.int32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminUpdateUserProfileReq {
    return {
      username: isSet(object.username) ? String(object.username) : "",
      profile: isSet(object.profile) ? ProfileRes.fromJSON(object.profile) : undefined,
      address: isSet(object.address) ? UpdateAddressReq.fromJSON(object.address) : undefined,
      role_ids: Array.isArray(object?.role_ids) ? object.role_ids.map((e: any) => Number(e)) : [],
    };
  },

  toJSON(message: AdminUpdateUserProfileReq): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    message.profile !== undefined && (obj.profile = message.profile ? ProfileRes.toJSON(message.profile) : undefined);
    message.address !== undefined &&
      (obj.address = message.address ? UpdateAddressReq.toJSON(message.address) : undefined);
    if (message.role_ids) {
      obj.role_ids = message.role_ids.map((e) => Math.round(e));
    } else {
      obj.role_ids = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminUpdateUserProfileReq>, I>>(base?: I): AdminUpdateUserProfileReq {
    return AdminUpdateUserProfileReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminUpdateUserProfileReq>, I>>(object: I): AdminUpdateUserProfileReq {
    const message = createBaseAdminUpdateUserProfileReq();
    message.username = object.username ?? "";
    message.profile = (object.profile !== undefined && object.profile !== null)
      ? ProfileRes.fromPartial(object.profile)
      : undefined;
    message.address = (object.address !== undefined && object.address !== null)
      ? UpdateAddressReq.fromPartial(object.address)
      : undefined;
    message.role_ids = object.role_ids?.map((e) => e) || [];
    return message;
  },
};

function createBaseAdminMFAOptionsType(): AdminMFAOptionsType {
  return { delivery_medium: "", attribute_name: "" };
}

export const AdminMFAOptionsType = {
  encode(message: AdminMFAOptionsType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.delivery_medium !== "") {
      writer.uint32(10).string(message.delivery_medium);
    }
    if (message.attribute_name !== "") {
      writer.uint32(18).string(message.attribute_name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminMFAOptionsType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminMFAOptionsType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.delivery_medium = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.attribute_name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminMFAOptionsType {
    return {
      delivery_medium: isSet(object.delivery_medium) ? String(object.delivery_medium) : "",
      attribute_name: isSet(object.attribute_name) ? String(object.attribute_name) : "",
    };
  },

  toJSON(message: AdminMFAOptionsType): unknown {
    const obj: any = {};
    message.delivery_medium !== undefined && (obj.delivery_medium = message.delivery_medium);
    message.attribute_name !== undefined && (obj.attribute_name = message.attribute_name);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminMFAOptionsType>, I>>(base?: I): AdminMFAOptionsType {
    return AdminMFAOptionsType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminMFAOptionsType>, I>>(object: I): AdminMFAOptionsType {
    const message = createBaseAdminMFAOptionsType();
    message.delivery_medium = object.delivery_medium ?? "";
    message.attribute_name = object.attribute_name ?? "";
    return message;
  },
};

function createBaseAdminGetByUserNameReq(): AdminGetByUserNameReq {
  return { username: "" };
}

export const AdminGetByUserNameReq = {
  encode(message: AdminGetByUserNameReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetByUserNameReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetByUserNameReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetByUserNameReq {
    return { username: isSet(object.username) ? String(object.username) : "" };
  },

  toJSON(message: AdminGetByUserNameReq): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetByUserNameReq>, I>>(base?: I): AdminGetByUserNameReq {
    return AdminGetByUserNameReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetByUserNameReq>, I>>(object: I): AdminGetByUserNameReq {
    const message = createBaseAdminGetByUserNameReq();
    message.username = object.username ?? "";
    return message;
  },
};

function createBaseAdminGetUserReq(): AdminGetUserReq {
  return { username: undefined, id: undefined };
}

export const AdminGetUserReq = {
  encode(message: AdminGetUserReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== undefined) {
      writer.uint32(10).string(message.username);
    }
    if (message.id !== undefined) {
      writer.uint32(16).int64(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetUserReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetUserReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetUserReq {
    return {
      username: isSet(object.username) ? String(object.username) : undefined,
      id: isSet(object.id) ? Number(object.id) : undefined,
    };
  },

  toJSON(message: AdminGetUserReq): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    message.id !== undefined && (obj.id = Math.round(message.id));
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetUserReq>, I>>(base?: I): AdminGetUserReq {
    return AdminGetUserReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetUserReq>, I>>(object: I): AdminGetUserReq {
    const message = createBaseAdminGetUserReq();
    message.username = object.username ?? undefined;
    message.id = object.id ?? undefined;
    return message;
  },
};

function createBaseAdminDisableUserReq(): AdminDisableUserReq {
  return { username: "" };
}

export const AdminDisableUserReq = {
  encode(message: AdminDisableUserReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminDisableUserReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminDisableUserReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminDisableUserReq {
    return { username: isSet(object.username) ? String(object.username) : "" };
  },

  toJSON(message: AdminDisableUserReq): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminDisableUserReq>, I>>(base?: I): AdminDisableUserReq {
    return AdminDisableUserReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminDisableUserReq>, I>>(object: I): AdminDisableUserReq {
    const message = createBaseAdminDisableUserReq();
    message.username = object.username ?? "";
    return message;
  },
};

function createBaseAdminEnableUserReq(): AdminEnableUserReq {
  return { username: "" };
}

export const AdminEnableUserReq = {
  encode(message: AdminEnableUserReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminEnableUserReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminEnableUserReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminEnableUserReq {
    return { username: isSet(object.username) ? String(object.username) : "" };
  },

  toJSON(message: AdminEnableUserReq): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminEnableUserReq>, I>>(base?: I): AdminEnableUserReq {
    return AdminEnableUserReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminEnableUserReq>, I>>(object: I): AdminEnableUserReq {
    const message = createBaseAdminEnableUserReq();
    message.username = object.username ?? "";
    return message;
  },
};

function createBaseAdminConfirmUserSignUpReq(): AdminConfirmUserSignUpReq {
  return { username: "" };
}

export const AdminConfirmUserSignUpReq = {
  encode(message: AdminConfirmUserSignUpReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminConfirmUserSignUpReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminConfirmUserSignUpReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminConfirmUserSignUpReq {
    return { username: isSet(object.username) ? String(object.username) : "" };
  },

  toJSON(message: AdminConfirmUserSignUpReq): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminConfirmUserSignUpReq>, I>>(base?: I): AdminConfirmUserSignUpReq {
    return AdminConfirmUserSignUpReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminConfirmUserSignUpReq>, I>>(object: I): AdminConfirmUserSignUpReq {
    const message = createBaseAdminConfirmUserSignUpReq();
    message.username = object.username ?? "";
    return message;
  },
};

function createBaseAdminCreateUserReq(): AdminCreateUserReq {
  return { phone_number: "", temporary_password: "", profile: undefined, address: undefined };
}

export const AdminCreateUserReq = {
  encode(message: AdminCreateUserReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.phone_number !== "") {
      writer.uint32(10).string(message.phone_number);
    }
    if (message.temporary_password !== "") {
      writer.uint32(18).string(message.temporary_password);
    }
    if (message.profile !== undefined) {
      ProfileReq.encode(message.profile, writer.uint32(26).fork()).ldelim();
    }
    if (message.address !== undefined) {
      UpdateAddressReq.encode(message.address, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminCreateUserReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminCreateUserReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phone_number = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.temporary_password = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.profile = ProfileReq.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.address = UpdateAddressReq.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminCreateUserReq {
    return {
      phone_number: isSet(object.phone_number) ? String(object.phone_number) : "",
      temporary_password: isSet(object.temporary_password) ? String(object.temporary_password) : "",
      profile: isSet(object.profile) ? ProfileReq.fromJSON(object.profile) : undefined,
      address: isSet(object.address) ? UpdateAddressReq.fromJSON(object.address) : undefined,
    };
  },

  toJSON(message: AdminCreateUserReq): unknown {
    const obj: any = {};
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    message.temporary_password !== undefined && (obj.temporary_password = message.temporary_password);
    message.profile !== undefined && (obj.profile = message.profile ? ProfileReq.toJSON(message.profile) : undefined);
    message.address !== undefined &&
      (obj.address = message.address ? UpdateAddressReq.toJSON(message.address) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminCreateUserReq>, I>>(base?: I): AdminCreateUserReq {
    return AdminCreateUserReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminCreateUserReq>, I>>(object: I): AdminCreateUserReq {
    const message = createBaseAdminCreateUserReq();
    message.phone_number = object.phone_number ?? "";
    message.temporary_password = object.temporary_password ?? "";
    message.profile = (object.profile !== undefined && object.profile !== null)
      ? ProfileReq.fromPartial(object.profile)
      : undefined;
    message.address = (object.address !== undefined && object.address !== null)
      ? UpdateAddressReq.fromPartial(object.address)
      : undefined;
    return message;
  },
};

function createBaseAdminCreateUserRes(): AdminCreateUserRes {
  return {
    username: "",
    first_name: "",
    last_name: "",
    birthday_date: "",
    email: undefined,
    suffix: undefined,
    create_date: "",
    user_last_modified_date: "",
    enable: false,
    user_status: "",
    country: undefined,
  };
}

export const AdminCreateUserRes = {
  encode(message: AdminCreateUserRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    if (message.first_name !== "") {
      writer.uint32(18).string(message.first_name);
    }
    if (message.last_name !== "") {
      writer.uint32(26).string(message.last_name);
    }
    if (message.birthday_date !== "") {
      writer.uint32(34).string(message.birthday_date);
    }
    if (message.email !== undefined) {
      writer.uint32(42).string(message.email);
    }
    if (message.suffix !== undefined) {
      writer.uint32(50).string(message.suffix);
    }
    if (message.create_date !== "") {
      writer.uint32(58).string(message.create_date);
    }
    if (message.user_last_modified_date !== "") {
      writer.uint32(66).string(message.user_last_modified_date);
    }
    if (message.enable === true) {
      writer.uint32(72).bool(message.enable);
    }
    if (message.user_status !== "") {
      writer.uint32(82).string(message.user_status);
    }
    if (message.country !== undefined) {
      writer.uint32(90).string(message.country);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminCreateUserRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminCreateUserRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.first_name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.last_name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.birthday_date = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.email = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.suffix = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.create_date = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.user_last_modified_date = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.enable = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.user_status = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.country = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminCreateUserRes {
    return {
      username: isSet(object.username) ? String(object.username) : "",
      first_name: isSet(object.first_name) ? String(object.first_name) : "",
      last_name: isSet(object.last_name) ? String(object.last_name) : "",
      birthday_date: isSet(object.birthday_date) ? String(object.birthday_date) : "",
      email: isSet(object.email) ? String(object.email) : undefined,
      suffix: isSet(object.suffix) ? String(object.suffix) : undefined,
      create_date: isSet(object.create_date) ? String(object.create_date) : "",
      user_last_modified_date: isSet(object.user_last_modified_date) ? String(object.user_last_modified_date) : "",
      enable: isSet(object.enable) ? Boolean(object.enable) : false,
      user_status: isSet(object.user_status) ? String(object.user_status) : "",
      country: isSet(object.country) ? String(object.country) : undefined,
    };
  },

  toJSON(message: AdminCreateUserRes): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    message.first_name !== undefined && (obj.first_name = message.first_name);
    message.last_name !== undefined && (obj.last_name = message.last_name);
    message.birthday_date !== undefined && (obj.birthday_date = message.birthday_date);
    message.email !== undefined && (obj.email = message.email);
    message.suffix !== undefined && (obj.suffix = message.suffix);
    message.create_date !== undefined && (obj.create_date = message.create_date);
    message.user_last_modified_date !== undefined && (obj.user_last_modified_date = message.user_last_modified_date);
    message.enable !== undefined && (obj.enable = message.enable);
    message.user_status !== undefined && (obj.user_status = message.user_status);
    message.country !== undefined && (obj.country = message.country);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminCreateUserRes>, I>>(base?: I): AdminCreateUserRes {
    return AdminCreateUserRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminCreateUserRes>, I>>(object: I): AdminCreateUserRes {
    const message = createBaseAdminCreateUserRes();
    message.username = object.username ?? "";
    message.first_name = object.first_name ?? "";
    message.last_name = object.last_name ?? "";
    message.birthday_date = object.birthday_date ?? "";
    message.email = object.email ?? undefined;
    message.suffix = object.suffix ?? undefined;
    message.create_date = object.create_date ?? "";
    message.user_last_modified_date = object.user_last_modified_date ?? "";
    message.enable = object.enable ?? false;
    message.user_status = object.user_status ?? "";
    message.country = object.country ?? undefined;
    return message;
  },
};

function createBaseAdminCreateGroupReq(): AdminCreateGroupReq {
  return { name: "", description: undefined };
}

export const AdminCreateGroupReq = {
  encode(message: AdminCreateGroupReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== undefined) {
      writer.uint32(18).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminCreateGroupReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminCreateGroupReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminCreateGroupReq {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : undefined,
    };
  },

  toJSON(message: AdminCreateGroupReq): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminCreateGroupReq>, I>>(base?: I): AdminCreateGroupReq {
    return AdminCreateGroupReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminCreateGroupReq>, I>>(object: I): AdminCreateGroupReq {
    const message = createBaseAdminCreateGroupReq();
    message.name = object.name ?? "";
    message.description = object.description ?? undefined;
    return message;
  },
};

function createBaseAdminCreateGroupRes(): AdminCreateGroupRes {
  return { name: "", description: "", create_date: "", last_modified_date: "" };
}

export const AdminCreateGroupRes = {
  encode(message: AdminCreateGroupRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.create_date !== "") {
      writer.uint32(26).string(message.create_date);
    }
    if (message.last_modified_date !== "") {
      writer.uint32(34).string(message.last_modified_date);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminCreateGroupRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminCreateGroupRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.create_date = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.last_modified_date = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminCreateGroupRes {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      create_date: isSet(object.create_date) ? String(object.create_date) : "",
      last_modified_date: isSet(object.last_modified_date) ? String(object.last_modified_date) : "",
    };
  },

  toJSON(message: AdminCreateGroupRes): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.create_date !== undefined && (obj.create_date = message.create_date);
    message.last_modified_date !== undefined && (obj.last_modified_date = message.last_modified_date);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminCreateGroupRes>, I>>(base?: I): AdminCreateGroupRes {
    return AdminCreateGroupRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminCreateGroupRes>, I>>(object: I): AdminCreateGroupRes {
    const message = createBaseAdminCreateGroupRes();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.create_date = object.create_date ?? "";
    message.last_modified_date = object.last_modified_date ?? "";
    return message;
  },
};

function createBaseAdminUpdateGroupReq(): AdminUpdateGroupReq {
  return { name: "", description: undefined };
}

export const AdminUpdateGroupReq = {
  encode(message: AdminUpdateGroupReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== undefined) {
      writer.uint32(18).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminUpdateGroupReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminUpdateGroupReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminUpdateGroupReq {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : undefined,
    };
  },

  toJSON(message: AdminUpdateGroupReq): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminUpdateGroupReq>, I>>(base?: I): AdminUpdateGroupReq {
    return AdminUpdateGroupReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminUpdateGroupReq>, I>>(object: I): AdminUpdateGroupReq {
    const message = createBaseAdminUpdateGroupReq();
    message.name = object.name ?? "";
    message.description = object.description ?? undefined;
    return message;
  },
};

function createBaseAdminUpdateGroupRes(): AdminUpdateGroupRes {
  return { name: "", description: "", create_date: "", last_modified_date: "" };
}

export const AdminUpdateGroupRes = {
  encode(message: AdminUpdateGroupRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.create_date !== "") {
      writer.uint32(26).string(message.create_date);
    }
    if (message.last_modified_date !== "") {
      writer.uint32(34).string(message.last_modified_date);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminUpdateGroupRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminUpdateGroupRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.create_date = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.last_modified_date = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminUpdateGroupRes {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      create_date: isSet(object.create_date) ? String(object.create_date) : "",
      last_modified_date: isSet(object.last_modified_date) ? String(object.last_modified_date) : "",
    };
  },

  toJSON(message: AdminUpdateGroupRes): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.create_date !== undefined && (obj.create_date = message.create_date);
    message.last_modified_date !== undefined && (obj.last_modified_date = message.last_modified_date);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminUpdateGroupRes>, I>>(base?: I): AdminUpdateGroupRes {
    return AdminUpdateGroupRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminUpdateGroupRes>, I>>(object: I): AdminUpdateGroupRes {
    const message = createBaseAdminUpdateGroupRes();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.create_date = object.create_date ?? "";
    message.last_modified_date = object.last_modified_date ?? "";
    return message;
  },
};

function createBaseAdminGetGroupReq(): AdminGetGroupReq {
  return { name: "" };
}

export const AdminGetGroupReq = {
  encode(message: AdminGetGroupReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetGroupReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetGroupReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetGroupReq {
    return { name: isSet(object.name) ? String(object.name) : "" };
  },

  toJSON(message: AdminGetGroupReq): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetGroupReq>, I>>(base?: I): AdminGetGroupReq {
    return AdminGetGroupReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetGroupReq>, I>>(object: I): AdminGetGroupReq {
    const message = createBaseAdminGetGroupReq();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseAdminGetGroupRes(): AdminGetGroupRes {
  return { name: "", description: "", create_date: "", last_modified_date: "" };
}

export const AdminGetGroupRes = {
  encode(message: AdminGetGroupRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.create_date !== "") {
      writer.uint32(26).string(message.create_date);
    }
    if (message.last_modified_date !== "") {
      writer.uint32(34).string(message.last_modified_date);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetGroupRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetGroupRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.create_date = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.last_modified_date = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetGroupRes {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      create_date: isSet(object.create_date) ? String(object.create_date) : "",
      last_modified_date: isSet(object.last_modified_date) ? String(object.last_modified_date) : "",
    };
  },

  toJSON(message: AdminGetGroupRes): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.create_date !== undefined && (obj.create_date = message.create_date);
    message.last_modified_date !== undefined && (obj.last_modified_date = message.last_modified_date);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetGroupRes>, I>>(base?: I): AdminGetGroupRes {
    return AdminGetGroupRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetGroupRes>, I>>(object: I): AdminGetGroupRes {
    const message = createBaseAdminGetGroupRes();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.create_date = object.create_date ?? "";
    message.last_modified_date = object.last_modified_date ?? "";
    return message;
  },
};

function createBaseAdminGetListGroupsReq(): AdminGetListGroupsReq {
  return { limit: 0, next_token: undefined };
}

export const AdminGetListGroupsReq = {
  encode(message: AdminGetListGroupsReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.limit !== 0) {
      writer.uint32(8).int32(message.limit);
    }
    if (message.next_token !== undefined) {
      writer.uint32(18).string(message.next_token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetListGroupsReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetListGroupsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.limit = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.next_token = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetListGroupsReq {
    return {
      limit: isSet(object.limit) ? Number(object.limit) : 0,
      next_token: isSet(object.next_token) ? String(object.next_token) : undefined,
    };
  },

  toJSON(message: AdminGetListGroupsReq): unknown {
    const obj: any = {};
    message.limit !== undefined && (obj.limit = Math.round(message.limit));
    message.next_token !== undefined && (obj.next_token = message.next_token);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetListGroupsReq>, I>>(base?: I): AdminGetListGroupsReq {
    return AdminGetListGroupsReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetListGroupsReq>, I>>(object: I): AdminGetListGroupsReq {
    const message = createBaseAdminGetListGroupsReq();
    message.limit = object.limit ?? 0;
    message.next_token = object.next_token ?? undefined;
    return message;
  },
};

function createBaseAdminGetListGroupsRes(): AdminGetListGroupsRes {
  return { groups: [], next_token: undefined };
}

export const AdminGetListGroupsRes = {
  encode(message: AdminGetListGroupsRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.groups) {
      AdminGroup.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.next_token !== undefined) {
      writer.uint32(18).string(message.next_token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetListGroupsRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetListGroupsRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groups.push(AdminGroup.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.next_token = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetListGroupsRes {
    return {
      groups: Array.isArray(object?.groups) ? object.groups.map((e: any) => AdminGroup.fromJSON(e)) : [],
      next_token: isSet(object.next_token) ? String(object.next_token) : undefined,
    };
  },

  toJSON(message: AdminGetListGroupsRes): unknown {
    const obj: any = {};
    if (message.groups) {
      obj.groups = message.groups.map((e) => e ? AdminGroup.toJSON(e) : undefined);
    } else {
      obj.groups = [];
    }
    message.next_token !== undefined && (obj.next_token = message.next_token);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetListGroupsRes>, I>>(base?: I): AdminGetListGroupsRes {
    return AdminGetListGroupsRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetListGroupsRes>, I>>(object: I): AdminGetListGroupsRes {
    const message = createBaseAdminGetListGroupsRes();
    message.groups = object.groups?.map((e) => AdminGroup.fromPartial(e)) || [];
    message.next_token = object.next_token ?? undefined;
    return message;
  },
};

function createBaseAdminGroup(): AdminGroup {
  return { name: undefined, description: undefined, create_date: undefined, last_modified_date: undefined };
}

export const AdminGroup = {
  encode(message: AdminGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== undefined) {
      writer.uint32(18).string(message.description);
    }
    if (message.create_date !== undefined) {
      writer.uint32(26).string(message.create_date);
    }
    if (message.last_modified_date !== undefined) {
      writer.uint32(34).string(message.last_modified_date);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.create_date = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.last_modified_date = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGroup {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      description: isSet(object.description) ? String(object.description) : undefined,
      create_date: isSet(object.create_date) ? String(object.create_date) : undefined,
      last_modified_date: isSet(object.last_modified_date) ? String(object.last_modified_date) : undefined,
    };
  },

  toJSON(message: AdminGroup): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.create_date !== undefined && (obj.create_date = message.create_date);
    message.last_modified_date !== undefined && (obj.last_modified_date = message.last_modified_date);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGroup>, I>>(base?: I): AdminGroup {
    return AdminGroup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGroup>, I>>(object: I): AdminGroup {
    const message = createBaseAdminGroup();
    message.name = object.name ?? undefined;
    message.description = object.description ?? undefined;
    message.create_date = object.create_date ?? undefined;
    message.last_modified_date = object.last_modified_date ?? undefined;
    return message;
  },
};

function createBaseAdminDeleteGroupReq(): AdminDeleteGroupReq {
  return { name: "" };
}

export const AdminDeleteGroupReq = {
  encode(message: AdminDeleteGroupReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminDeleteGroupReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminDeleteGroupReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminDeleteGroupReq {
    return { name: isSet(object.name) ? String(object.name) : "" };
  },

  toJSON(message: AdminDeleteGroupReq): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminDeleteGroupReq>, I>>(base?: I): AdminDeleteGroupReq {
    return AdminDeleteGroupReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminDeleteGroupReq>, I>>(object: I): AdminDeleteGroupReq {
    const message = createBaseAdminDeleteGroupReq();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseAdminAddUserToGroupReq(): AdminAddUserToGroupReq {
  return { username: "", group_name: "" };
}

export const AdminAddUserToGroupReq = {
  encode(message: AdminAddUserToGroupReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    if (message.group_name !== "") {
      writer.uint32(18).string(message.group_name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminAddUserToGroupReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminAddUserToGroupReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.group_name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminAddUserToGroupReq {
    return {
      username: isSet(object.username) ? String(object.username) : "",
      group_name: isSet(object.group_name) ? String(object.group_name) : "",
    };
  },

  toJSON(message: AdminAddUserToGroupReq): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    message.group_name !== undefined && (obj.group_name = message.group_name);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminAddUserToGroupReq>, I>>(base?: I): AdminAddUserToGroupReq {
    return AdminAddUserToGroupReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminAddUserToGroupReq>, I>>(object: I): AdminAddUserToGroupReq {
    const message = createBaseAdminAddUserToGroupReq();
    message.username = object.username ?? "";
    message.group_name = object.group_name ?? "";
    return message;
  },
};

function createBaseAdminRemoveUserFromGroupReq(): AdminRemoveUserFromGroupReq {
  return { username: "", group_name: "" };
}

export const AdminRemoveUserFromGroupReq = {
  encode(message: AdminRemoveUserFromGroupReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    if (message.group_name !== "") {
      writer.uint32(18).string(message.group_name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminRemoveUserFromGroupReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminRemoveUserFromGroupReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.group_name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminRemoveUserFromGroupReq {
    return {
      username: isSet(object.username) ? String(object.username) : "",
      group_name: isSet(object.group_name) ? String(object.group_name) : "",
    };
  },

  toJSON(message: AdminRemoveUserFromGroupReq): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    message.group_name !== undefined && (obj.group_name = message.group_name);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminRemoveUserFromGroupReq>, I>>(base?: I): AdminRemoveUserFromGroupReq {
    return AdminRemoveUserFromGroupReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminRemoveUserFromGroupReq>, I>>(object: I): AdminRemoveUserFromGroupReq {
    const message = createBaseAdminRemoveUserFromGroupReq();
    message.username = object.username ?? "";
    message.group_name = object.group_name ?? "";
    return message;
  },
};

function createBaseAdminResetUserPasswordReq(): AdminResetUserPasswordReq {
  return { username: "" };
}

export const AdminResetUserPasswordReq = {
  encode(message: AdminResetUserPasswordReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminResetUserPasswordReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminResetUserPasswordReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminResetUserPasswordReq {
    return { username: isSet(object.username) ? String(object.username) : "" };
  },

  toJSON(message: AdminResetUserPasswordReq): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminResetUserPasswordReq>, I>>(base?: I): AdminResetUserPasswordReq {
    return AdminResetUserPasswordReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminResetUserPasswordReq>, I>>(object: I): AdminResetUserPasswordReq {
    const message = createBaseAdminResetUserPasswordReq();
    message.username = object.username ?? "";
    return message;
  },
};

function createBaseAdminSetUserPasswordReq(): AdminSetUserPasswordReq {
  return { username: "", password: "" };
}

export const AdminSetUserPasswordReq = {
  encode(message: AdminSetUserPasswordReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    if (message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminSetUserPasswordReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminSetUserPasswordReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminSetUserPasswordReq {
    return {
      username: isSet(object.username) ? String(object.username) : "",
      password: isSet(object.password) ? String(object.password) : "",
    };
  },

  toJSON(message: AdminSetUserPasswordReq): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    message.password !== undefined && (obj.password = message.password);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminSetUserPasswordReq>, I>>(base?: I): AdminSetUserPasswordReq {
    return AdminSetUserPasswordReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminSetUserPasswordReq>, I>>(object: I): AdminSetUserPasswordReq {
    const message = createBaseAdminSetUserPasswordReq();
    message.username = object.username ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBaseAdminNewTokenByRefreshTokenReq(): AdminNewTokenByRefreshTokenReq {
  return { refreshToken: "" };
}

export const AdminNewTokenByRefreshTokenReq = {
  encode(message: AdminNewTokenByRefreshTokenReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.refreshToken !== "") {
      writer.uint32(10).string(message.refreshToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminNewTokenByRefreshTokenReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminNewTokenByRefreshTokenReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.refreshToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminNewTokenByRefreshTokenReq {
    return { refreshToken: isSet(object.refreshToken) ? String(object.refreshToken) : "" };
  },

  toJSON(message: AdminNewTokenByRefreshTokenReq): unknown {
    const obj: any = {};
    message.refreshToken !== undefined && (obj.refreshToken = message.refreshToken);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminNewTokenByRefreshTokenReq>, I>>(base?: I): AdminNewTokenByRefreshTokenReq {
    return AdminNewTokenByRefreshTokenReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminNewTokenByRefreshTokenReq>, I>>(
    object: I,
  ): AdminNewTokenByRefreshTokenReq {
    const message = createBaseAdminNewTokenByRefreshTokenReq();
    message.refreshToken = object.refreshToken ?? "";
    return message;
  },
};

function createBaseAdminGetTokenByRefreshTokenRes(): AdminGetTokenByRefreshTokenRes {
  return { access_token: "", refresh_token: "", mfa_needed: false };
}

export const AdminGetTokenByRefreshTokenRes = {
  encode(message: AdminGetTokenByRefreshTokenRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.access_token !== "") {
      writer.uint32(10).string(message.access_token);
    }
    if (message.refresh_token !== "") {
      writer.uint32(18).string(message.refresh_token);
    }
    if (message.mfa_needed === true) {
      writer.uint32(24).bool(message.mfa_needed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetTokenByRefreshTokenRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetTokenByRefreshTokenRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.access_token = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.refresh_token = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.mfa_needed = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetTokenByRefreshTokenRes {
    return {
      access_token: isSet(object.access_token) ? String(object.access_token) : "",
      refresh_token: isSet(object.refresh_token) ? String(object.refresh_token) : "",
      mfa_needed: isSet(object.mfa_needed) ? Boolean(object.mfa_needed) : false,
    };
  },

  toJSON(message: AdminGetTokenByRefreshTokenRes): unknown {
    const obj: any = {};
    message.access_token !== undefined && (obj.access_token = message.access_token);
    message.refresh_token !== undefined && (obj.refresh_token = message.refresh_token);
    message.mfa_needed !== undefined && (obj.mfa_needed = message.mfa_needed);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetTokenByRefreshTokenRes>, I>>(base?: I): AdminGetTokenByRefreshTokenRes {
    return AdminGetTokenByRefreshTokenRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetTokenByRefreshTokenRes>, I>>(
    object: I,
  ): AdminGetTokenByRefreshTokenRes {
    const message = createBaseAdminGetTokenByRefreshTokenRes();
    message.access_token = object.access_token ?? "";
    message.refresh_token = object.refresh_token ?? "";
    message.mfa_needed = object.mfa_needed ?? false;
    return message;
  },
};

function createBaseAdminGlobalSignOutReq(): AdminGlobalSignOutReq {
  return { username: "" };
}

export const AdminGlobalSignOutReq = {
  encode(message: AdminGlobalSignOutReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGlobalSignOutReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGlobalSignOutReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGlobalSignOutReq {
    return { username: isSet(object.username) ? String(object.username) : "" };
  },

  toJSON(message: AdminGlobalSignOutReq): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGlobalSignOutReq>, I>>(base?: I): AdminGlobalSignOutReq {
    return AdminGlobalSignOutReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGlobalSignOutReq>, I>>(object: I): AdminGlobalSignOutReq {
    const message = createBaseAdminGlobalSignOutReq();
    message.username = object.username ?? "";
    return message;
  },
};

function createBaseAdminSetUserMFAPreferenceReq(): AdminSetUserMFAPreferenceReq {
  return { username: "", enable_sms: undefined, enable_software_token: undefined };
}

export const AdminSetUserMFAPreferenceReq = {
  encode(message: AdminSetUserMFAPreferenceReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    if (message.enable_sms !== undefined) {
      writer.uint32(16).bool(message.enable_sms);
    }
    if (message.enable_software_token !== undefined) {
      writer.uint32(32).bool(message.enable_software_token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminSetUserMFAPreferenceReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminSetUserMFAPreferenceReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enable_sms = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enable_software_token = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminSetUserMFAPreferenceReq {
    return {
      username: isSet(object.username) ? String(object.username) : "",
      enable_sms: isSet(object.enable_sms) ? Boolean(object.enable_sms) : undefined,
      enable_software_token: isSet(object.enable_software_token) ? Boolean(object.enable_software_token) : undefined,
    };
  },

  toJSON(message: AdminSetUserMFAPreferenceReq): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    message.enable_sms !== undefined && (obj.enable_sms = message.enable_sms);
    message.enable_software_token !== undefined && (obj.enable_software_token = message.enable_software_token);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminSetUserMFAPreferenceReq>, I>>(base?: I): AdminSetUserMFAPreferenceReq {
    return AdminSetUserMFAPreferenceReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminSetUserMFAPreferenceReq>, I>>(object: I): AdminSetUserMFAPreferenceReq {
    const message = createBaseAdminSetUserMFAPreferenceReq();
    message.username = object.username ?? "";
    message.enable_sms = object.enable_sms ?? undefined;
    message.enable_software_token = object.enable_software_token ?? undefined;
    return message;
  },
};

function createBaseAdminGetUserMFASettingReq(): AdminGetUserMFASettingReq {
  return { username: "" };
}

export const AdminGetUserMFASettingReq = {
  encode(message: AdminGetUserMFASettingReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetUserMFASettingReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetUserMFASettingReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetUserMFASettingReq {
    return { username: isSet(object.username) ? String(object.username) : "" };
  },

  toJSON(message: AdminGetUserMFASettingReq): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetUserMFASettingReq>, I>>(base?: I): AdminGetUserMFASettingReq {
    return AdminGetUserMFASettingReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetUserMFASettingReq>, I>>(object: I): AdminGetUserMFASettingReq {
    const message = createBaseAdminGetUserMFASettingReq();
    message.username = object.username ?? "";
    return message;
  },
};

function createBaseAdminCreateCountryReq(): AdminCreateCountryReq {
  return { code: "" };
}

export const AdminCreateCountryReq = {
  encode(message: AdminCreateCountryReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminCreateCountryReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminCreateCountryReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminCreateCountryReq {
    return { code: isSet(object.code) ? String(object.code) : "" };
  },

  toJSON(message: AdminCreateCountryReq): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminCreateCountryReq>, I>>(base?: I): AdminCreateCountryReq {
    return AdminCreateCountryReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminCreateCountryReq>, I>>(object: I): AdminCreateCountryReq {
    const message = createBaseAdminCreateCountryReq();
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseAdminUpdateCountryReq(): AdminUpdateCountryReq {
  return { id: 0, enable: false };
}

export const AdminUpdateCountryReq = {
  encode(message: AdminUpdateCountryReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.enable === true) {
      writer.uint32(16).bool(message.enable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminUpdateCountryReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminUpdateCountryReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enable = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminUpdateCountryReq {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      enable: isSet(object.enable) ? Boolean(object.enable) : false,
    };
  },

  toJSON(message: AdminUpdateCountryReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.enable !== undefined && (obj.enable = message.enable);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminUpdateCountryReq>, I>>(base?: I): AdminUpdateCountryReq {
    return AdminUpdateCountryReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminUpdateCountryReq>, I>>(object: I): AdminUpdateCountryReq {
    const message = createBaseAdminUpdateCountryReq();
    message.id = object.id ?? 0;
    message.enable = object.enable ?? false;
    return message;
  },
};

function createBaseAdminDeleteCountryReq(): AdminDeleteCountryReq {
  return { id: 0 };
}

export const AdminDeleteCountryReq = {
  encode(message: AdminDeleteCountryReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminDeleteCountryReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminDeleteCountryReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminDeleteCountryReq {
    return { id: isSet(object.id) ? Number(object.id) : 0 };
  },

  toJSON(message: AdminDeleteCountryReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminDeleteCountryReq>, I>>(base?: I): AdminDeleteCountryReq {
    return AdminDeleteCountryReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminDeleteCountryReq>, I>>(object: I): AdminDeleteCountryReq {
    const message = createBaseAdminDeleteCountryReq();
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseAdminGetCountryByIdReq(): AdminGetCountryByIdReq {
  return { id: 0 };
}

export const AdminGetCountryByIdReq = {
  encode(message: AdminGetCountryByIdReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetCountryByIdReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetCountryByIdReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetCountryByIdReq {
    return { id: isSet(object.id) ? Number(object.id) : 0 };
  },

  toJSON(message: AdminGetCountryByIdReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetCountryByIdReq>, I>>(base?: I): AdminGetCountryByIdReq {
    return AdminGetCountryByIdReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetCountryByIdReq>, I>>(object: I): AdminGetCountryByIdReq {
    const message = createBaseAdminGetCountryByIdReq();
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseAdminGetAllCountriesReq(): AdminGetAllCountriesReq {
  return { enable: undefined };
}

export const AdminGetAllCountriesReq = {
  encode(message: AdminGetAllCountriesReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enable !== undefined) {
      writer.uint32(8).bool(message.enable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetAllCountriesReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetAllCountriesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enable = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetAllCountriesReq {
    return { enable: isSet(object.enable) ? Boolean(object.enable) : undefined };
  },

  toJSON(message: AdminGetAllCountriesReq): unknown {
    const obj: any = {};
    message.enable !== undefined && (obj.enable = message.enable);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetAllCountriesReq>, I>>(base?: I): AdminGetAllCountriesReq {
    return AdminGetAllCountriesReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetAllCountriesReq>, I>>(object: I): AdminGetAllCountriesReq {
    const message = createBaseAdminGetAllCountriesReq();
    message.enable = object.enable ?? undefined;
    return message;
  },
};

function createBaseGetAllCountriesRes(): GetAllCountriesRes {
  return { countries: [] };
}

export const GetAllCountriesRes = {
  encode(message: GetAllCountriesRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.countries) {
      Country.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllCountriesRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllCountriesRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.countries.push(Country.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllCountriesRes {
    return { countries: Array.isArray(object?.countries) ? object.countries.map((e: any) => Country.fromJSON(e)) : [] };
  },

  toJSON(message: GetAllCountriesRes): unknown {
    const obj: any = {};
    if (message.countries) {
      obj.countries = message.countries.map((e) => e ? Country.toJSON(e) : undefined);
    } else {
      obj.countries = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllCountriesRes>, I>>(base?: I): GetAllCountriesRes {
    return GetAllCountriesRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAllCountriesRes>, I>>(object: I): GetAllCountriesRes {
    const message = createBaseGetAllCountriesRes();
    message.countries = object.countries?.map((e) => Country.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetTotalCountriesRes(): GetTotalCountriesRes {
  return { countries: [] };
}

export const GetTotalCountriesRes = {
  encode(message: GetTotalCountriesRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.countries) {
      SystemCountry.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTotalCountriesRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTotalCountriesRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.countries.push(SystemCountry.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTotalCountriesRes {
    return {
      countries: Array.isArray(object?.countries) ? object.countries.map((e: any) => SystemCountry.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetTotalCountriesRes): unknown {
    const obj: any = {};
    if (message.countries) {
      obj.countries = message.countries.map((e) => e ? SystemCountry.toJSON(e) : undefined);
    } else {
      obj.countries = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTotalCountriesRes>, I>>(base?: I): GetTotalCountriesRes {
    return GetTotalCountriesRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetTotalCountriesRes>, I>>(object: I): GetTotalCountriesRes {
    const message = createBaseGetTotalCountriesRes();
    message.countries = object.countries?.map((e) => SystemCountry.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSystemCountry(): SystemCountry {
  return { name: "", code: "", flag: "", tel_number: "" };
}

export const SystemCountry = {
  encode(message: SystemCountry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.code !== "") {
      writer.uint32(18).string(message.code);
    }
    if (message.flag !== "") {
      writer.uint32(26).string(message.flag);
    }
    if (message.tel_number !== "") {
      writer.uint32(34).string(message.tel_number);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SystemCountry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSystemCountry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.flag = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tel_number = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SystemCountry {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      code: isSet(object.code) ? String(object.code) : "",
      flag: isSet(object.flag) ? String(object.flag) : "",
      tel_number: isSet(object.tel_number) ? String(object.tel_number) : "",
    };
  },

  toJSON(message: SystemCountry): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.code !== undefined && (obj.code = message.code);
    message.flag !== undefined && (obj.flag = message.flag);
    message.tel_number !== undefined && (obj.tel_number = message.tel_number);
    return obj;
  },

  create<I extends Exact<DeepPartial<SystemCountry>, I>>(base?: I): SystemCountry {
    return SystemCountry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SystemCountry>, I>>(object: I): SystemCountry {
    const message = createBaseSystemCountry();
    message.name = object.name ?? "";
    message.code = object.code ?? "";
    message.flag = object.flag ?? "";
    message.tel_number = object.tel_number ?? "";
    return message;
  },
};

function createBaseCountry(): Country {
  return { id: 0, name: "", code: "", flag: "", create_at: "", tel_number: "", update_at: undefined, enable: false };
}

export const Country = {
  encode(message: Country, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.code !== "") {
      writer.uint32(26).string(message.code);
    }
    if (message.flag !== "") {
      writer.uint32(34).string(message.flag);
    }
    if (message.create_at !== "") {
      writer.uint32(42).string(message.create_at);
    }
    if (message.tel_number !== "") {
      writer.uint32(50).string(message.tel_number);
    }
    if (message.update_at !== undefined) {
      writer.uint32(58).string(message.update_at);
    }
    if (message.enable === true) {
      writer.uint32(64).bool(message.enable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Country {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.code = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.flag = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.create_at = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tel_number = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.update_at = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.enable = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Country {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      name: isSet(object.name) ? String(object.name) : "",
      code: isSet(object.code) ? String(object.code) : "",
      flag: isSet(object.flag) ? String(object.flag) : "",
      create_at: isSet(object.create_at) ? String(object.create_at) : "",
      tel_number: isSet(object.tel_number) ? String(object.tel_number) : "",
      update_at: isSet(object.update_at) ? String(object.update_at) : undefined,
      enable: isSet(object.enable) ? Boolean(object.enable) : false,
    };
  },

  toJSON(message: Country): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.name !== undefined && (obj.name = message.name);
    message.code !== undefined && (obj.code = message.code);
    message.flag !== undefined && (obj.flag = message.flag);
    message.create_at !== undefined && (obj.create_at = message.create_at);
    message.tel_number !== undefined && (obj.tel_number = message.tel_number);
    message.update_at !== undefined && (obj.update_at = message.update_at);
    message.enable !== undefined && (obj.enable = message.enable);
    return obj;
  },

  create<I extends Exact<DeepPartial<Country>, I>>(base?: I): Country {
    return Country.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Country>, I>>(object: I): Country {
    const message = createBaseCountry();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.code = object.code ?? "";
    message.flag = object.flag ?? "";
    message.create_at = object.create_at ?? "";
    message.tel_number = object.tel_number ?? "";
    message.update_at = object.update_at ?? undefined;
    message.enable = object.enable ?? false;
    return message;
  },
};

function createBaseGetAllStatesOfCountryReq(): GetAllStatesOfCountryReq {
  return { country_code: "" };
}

export const GetAllStatesOfCountryReq = {
  encode(message: GetAllStatesOfCountryReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.country_code !== "") {
      writer.uint32(10).string(message.country_code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllStatesOfCountryReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllStatesOfCountryReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.country_code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllStatesOfCountryReq {
    return { country_code: isSet(object.country_code) ? String(object.country_code) : "" };
  },

  toJSON(message: GetAllStatesOfCountryReq): unknown {
    const obj: any = {};
    message.country_code !== undefined && (obj.country_code = message.country_code);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllStatesOfCountryReq>, I>>(base?: I): GetAllStatesOfCountryReq {
    return GetAllStatesOfCountryReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAllStatesOfCountryReq>, I>>(object: I): GetAllStatesOfCountryReq {
    const message = createBaseGetAllStatesOfCountryReq();
    message.country_code = object.country_code ?? "";
    return message;
  },
};

function createBaseGetAllStatesOfCountryRes(): GetAllStatesOfCountryRes {
  return { states: [] };
}

export const GetAllStatesOfCountryRes = {
  encode(message: GetAllStatesOfCountryRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.states) {
      State.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllStatesOfCountryRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllStatesOfCountryRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.states.push(State.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllStatesOfCountryRes {
    return { states: Array.isArray(object?.states) ? object.states.map((e: any) => State.fromJSON(e)) : [] };
  },

  toJSON(message: GetAllStatesOfCountryRes): unknown {
    const obj: any = {};
    if (message.states) {
      obj.states = message.states.map((e) => e ? State.toJSON(e) : undefined);
    } else {
      obj.states = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllStatesOfCountryRes>, I>>(base?: I): GetAllStatesOfCountryRes {
    return GetAllStatesOfCountryRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAllStatesOfCountryRes>, I>>(object: I): GetAllStatesOfCountryRes {
    const message = createBaseGetAllStatesOfCountryRes();
    message.states = object.states?.map((e) => State.fromPartial(e)) || [];
    return message;
  },
};

function createBaseState(): State {
  return { name: "", code: "" };
}

export const State = {
  encode(message: State, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.code !== "") {
      writer.uint32(18).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): State {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): State {
    return { name: isSet(object.name) ? String(object.name) : "", code: isSet(object.code) ? String(object.code) : "" };
  },

  toJSON(message: State): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.code !== undefined && (obj.code = message.code);
    return obj;
  },

  create<I extends Exact<DeepPartial<State>, I>>(base?: I): State {
    return State.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<State>, I>>(object: I): State {
    const message = createBaseState();
    message.name = object.name ?? "";
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseCountryForAddress(): CountryForAddress {
  return { id: 0, name: "", code: "" };
}

export const CountryForAddress = {
  encode(message: CountryForAddress, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.code !== "") {
      writer.uint32(26).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CountryForAddress {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountryForAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountryForAddress {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      name: isSet(object.name) ? String(object.name) : "",
      code: isSet(object.code) ? String(object.code) : "",
    };
  },

  toJSON(message: CountryForAddress): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.name !== undefined && (obj.name = message.name);
    message.code !== undefined && (obj.code = message.code);
    return obj;
  },

  create<I extends Exact<DeepPartial<CountryForAddress>, I>>(base?: I): CountryForAddress {
    return CountryForAddress.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CountryForAddress>, I>>(object: I): CountryForAddress {
    const message = createBaseCountryForAddress();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseGetAllCitiesOfCountryAndStateReq(): GetAllCitiesOfCountryAndStateReq {
  return { country_code: "", state_code: "" };
}

export const GetAllCitiesOfCountryAndStateReq = {
  encode(message: GetAllCitiesOfCountryAndStateReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.country_code !== "") {
      writer.uint32(10).string(message.country_code);
    }
    if (message.state_code !== "") {
      writer.uint32(18).string(message.state_code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllCitiesOfCountryAndStateReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllCitiesOfCountryAndStateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.country_code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.state_code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllCitiesOfCountryAndStateReq {
    return {
      country_code: isSet(object.country_code) ? String(object.country_code) : "",
      state_code: isSet(object.state_code) ? String(object.state_code) : "",
    };
  },

  toJSON(message: GetAllCitiesOfCountryAndStateReq): unknown {
    const obj: any = {};
    message.country_code !== undefined && (obj.country_code = message.country_code);
    message.state_code !== undefined && (obj.state_code = message.state_code);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllCitiesOfCountryAndStateReq>, I>>(
    base?: I,
  ): GetAllCitiesOfCountryAndStateReq {
    return GetAllCitiesOfCountryAndStateReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAllCitiesOfCountryAndStateReq>, I>>(
    object: I,
  ): GetAllCitiesOfCountryAndStateReq {
    const message = createBaseGetAllCitiesOfCountryAndStateReq();
    message.country_code = object.country_code ?? "";
    message.state_code = object.state_code ?? "";
    return message;
  },
};

function createBaseGetAllCitiesOfCountryAndStateRes(): GetAllCitiesOfCountryAndStateRes {
  return { cities: [] };
}

export const GetAllCitiesOfCountryAndStateRes = {
  encode(message: GetAllCitiesOfCountryAndStateRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.cities) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllCitiesOfCountryAndStateRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllCitiesOfCountryAndStateRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cities.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllCitiesOfCountryAndStateRes {
    return { cities: Array.isArray(object?.cities) ? object.cities.map((e: any) => String(e)) : [] };
  },

  toJSON(message: GetAllCitiesOfCountryAndStateRes): unknown {
    const obj: any = {};
    if (message.cities) {
      obj.cities = message.cities.map((e) => e);
    } else {
      obj.cities = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllCitiesOfCountryAndStateRes>, I>>(
    base?: I,
  ): GetAllCitiesOfCountryAndStateRes {
    return GetAllCitiesOfCountryAndStateRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAllCitiesOfCountryAndStateRes>, I>>(
    object: I,
  ): GetAllCitiesOfCountryAndStateRes {
    const message = createBaseGetAllCitiesOfCountryAndStateRes();
    message.cities = object.cities?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetUserInfoByIdReq(): GetUserInfoByIdReq {
  return { id: 0 };
}

export const GetUserInfoByIdReq = {
  encode(message: GetUserInfoByIdReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserInfoByIdReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserInfoByIdReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserInfoByIdReq {
    return { id: isSet(object.id) ? Number(object.id) : 0 };
  },

  toJSON(message: GetUserInfoByIdReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserInfoByIdReq>, I>>(base?: I): GetUserInfoByIdReq {
    return GetUserInfoByIdReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetUserInfoByIdReq>, I>>(object: I): GetUserInfoByIdReq {
    const message = createBaseGetUserInfoByIdReq();
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseGetCountryByCodeReq(): GetCountryByCodeReq {
  return { code: "" };
}

export const GetCountryByCodeReq = {
  encode(message: GetCountryByCodeReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCountryByCodeReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCountryByCodeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCountryByCodeReq {
    return { code: isSet(object.code) ? String(object.code) : "" };
  },

  toJSON(message: GetCountryByCodeReq): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCountryByCodeReq>, I>>(base?: I): GetCountryByCodeReq {
    return GetCountryByCodeReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetCountryByCodeReq>, I>>(object: I): GetCountryByCodeReq {
    const message = createBaseGetCountryByCodeReq();
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseInternalCheckUserSsoSessionExpiredReq(): InternalCheckUserSsoSessionExpiredReq {
  return { sessionId: "" };
}

export const InternalCheckUserSsoSessionExpiredReq = {
  encode(message: InternalCheckUserSsoSessionExpiredReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sessionId !== "") {
      writer.uint32(10).string(message.sessionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InternalCheckUserSsoSessionExpiredReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInternalCheckUserSsoSessionExpiredReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sessionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InternalCheckUserSsoSessionExpiredReq {
    return { sessionId: isSet(object.sessionId) ? String(object.sessionId) : "" };
  },

  toJSON(message: InternalCheckUserSsoSessionExpiredReq): unknown {
    const obj: any = {};
    message.sessionId !== undefined && (obj.sessionId = message.sessionId);
    return obj;
  },

  create<I extends Exact<DeepPartial<InternalCheckUserSsoSessionExpiredReq>, I>>(
    base?: I,
  ): InternalCheckUserSsoSessionExpiredReq {
    return InternalCheckUserSsoSessionExpiredReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InternalCheckUserSsoSessionExpiredReq>, I>>(
    object: I,
  ): InternalCheckUserSsoSessionExpiredReq {
    const message = createBaseInternalCheckUserSsoSessionExpiredReq();
    message.sessionId = object.sessionId ?? "";
    return message;
  },
};

function createBaseInternalCheckUserSsoSessionExpiredRes(): InternalCheckUserSsoSessionExpiredRes {
  return { exist: false };
}

export const InternalCheckUserSsoSessionExpiredRes = {
  encode(message: InternalCheckUserSsoSessionExpiredRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.exist === true) {
      writer.uint32(8).bool(message.exist);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InternalCheckUserSsoSessionExpiredRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInternalCheckUserSsoSessionExpiredRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.exist = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InternalCheckUserSsoSessionExpiredRes {
    return { exist: isSet(object.exist) ? Boolean(object.exist) : false };
  },

  toJSON(message: InternalCheckUserSsoSessionExpiredRes): unknown {
    const obj: any = {};
    message.exist !== undefined && (obj.exist = message.exist);
    return obj;
  },

  create<I extends Exact<DeepPartial<InternalCheckUserSsoSessionExpiredRes>, I>>(
    base?: I,
  ): InternalCheckUserSsoSessionExpiredRes {
    return InternalCheckUserSsoSessionExpiredRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InternalCheckUserSsoSessionExpiredRes>, I>>(
    object: I,
  ): InternalCheckUserSsoSessionExpiredRes {
    const message = createBaseInternalCheckUserSsoSessionExpiredRes();
    message.exist = object.exist ?? false;
    return message;
  },
};

function createBaseAdminCreateUserGroupReq(): AdminCreateUserGroupReq {
  return { title: "", description: undefined, user_ids: [] };
}

export const AdminCreateUserGroupReq = {
  encode(message: AdminCreateUserGroupReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.title !== "") {
      writer.uint32(10).string(message.title);
    }
    if (message.description !== undefined) {
      writer.uint32(18).string(message.description);
    }
    writer.uint32(26).fork();
    for (const v of message.user_ids) {
      writer.int64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminCreateUserGroupReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminCreateUserGroupReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag === 24) {
            message.user_ids.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.user_ids.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminCreateUserGroupReq {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : undefined,
      user_ids: Array.isArray(object?.user_ids) ? object.user_ids.map((e: any) => Number(e)) : [],
    };
  },

  toJSON(message: AdminCreateUserGroupReq): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    if (message.user_ids) {
      obj.user_ids = message.user_ids.map((e) => Math.round(e));
    } else {
      obj.user_ids = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminCreateUserGroupReq>, I>>(base?: I): AdminCreateUserGroupReq {
    return AdminCreateUserGroupReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminCreateUserGroupReq>, I>>(object: I): AdminCreateUserGroupReq {
    const message = createBaseAdminCreateUserGroupReq();
    message.title = object.title ?? "";
    message.description = object.description ?? undefined;
    message.user_ids = object.user_ids?.map((e) => e) || [];
    return message;
  },
};

function createBaseAdminUpdateUserGroupReq(): AdminUpdateUserGroupReq {
  return { id: 0, title: "", description: undefined };
}

export const AdminUpdateUserGroupReq = {
  encode(message: AdminUpdateUserGroupReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.description !== undefined) {
      writer.uint32(26).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminUpdateUserGroupReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminUpdateUserGroupReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminUpdateUserGroupReq {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : undefined,
    };
  },

  toJSON(message: AdminUpdateUserGroupReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminUpdateUserGroupReq>, I>>(base?: I): AdminUpdateUserGroupReq {
    return AdminUpdateUserGroupReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminUpdateUserGroupReq>, I>>(object: I): AdminUpdateUserGroupReq {
    const message = createBaseAdminUpdateUserGroupReq();
    message.id = object.id ?? 0;
    message.title = object.title ?? "";
    message.description = object.description ?? undefined;
    return message;
  },
};

function createBaseAdminAssignUserToGroupReq(): AdminAssignUserToGroupReq {
  return { group_id: 0, user_id: 0 };
}

export const AdminAssignUserToGroupReq = {
  encode(message: AdminAssignUserToGroupReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group_id !== 0) {
      writer.uint32(8).int64(message.group_id);
    }
    if (message.user_id !== 0) {
      writer.uint32(16).int64(message.user_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminAssignUserToGroupReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminAssignUserToGroupReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.group_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminAssignUserToGroupReq {
    return {
      group_id: isSet(object.group_id) ? Number(object.group_id) : 0,
      user_id: isSet(object.user_id) ? Number(object.user_id) : 0,
    };
  },

  toJSON(message: AdminAssignUserToGroupReq): unknown {
    const obj: any = {};
    message.group_id !== undefined && (obj.group_id = Math.round(message.group_id));
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminAssignUserToGroupReq>, I>>(base?: I): AdminAssignUserToGroupReq {
    return AdminAssignUserToGroupReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminAssignUserToGroupReq>, I>>(object: I): AdminAssignUserToGroupReq {
    const message = createBaseAdminAssignUserToGroupReq();
    message.group_id = object.group_id ?? 0;
    message.user_id = object.user_id ?? 0;
    return message;
  },
};

function createBaseAdminAssignListUsersToGroupReq(): AdminAssignListUsersToGroupReq {
  return { group_id: 0, user_ids: [] };
}

export const AdminAssignListUsersToGroupReq = {
  encode(message: AdminAssignListUsersToGroupReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group_id !== 0) {
      writer.uint32(8).int64(message.group_id);
    }
    writer.uint32(18).fork();
    for (const v of message.user_ids) {
      writer.int64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminAssignListUsersToGroupReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminAssignListUsersToGroupReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.group_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag === 16) {
            message.user_ids.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.user_ids.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminAssignListUsersToGroupReq {
    return {
      group_id: isSet(object.group_id) ? Number(object.group_id) : 0,
      user_ids: Array.isArray(object?.user_ids) ? object.user_ids.map((e: any) => Number(e)) : [],
    };
  },

  toJSON(message: AdminAssignListUsersToGroupReq): unknown {
    const obj: any = {};
    message.group_id !== undefined && (obj.group_id = Math.round(message.group_id));
    if (message.user_ids) {
      obj.user_ids = message.user_ids.map((e) => Math.round(e));
    } else {
      obj.user_ids = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminAssignListUsersToGroupReq>, I>>(base?: I): AdminAssignListUsersToGroupReq {
    return AdminAssignListUsersToGroupReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminAssignListUsersToGroupReq>, I>>(
    object: I,
  ): AdminAssignListUsersToGroupReq {
    const message = createBaseAdminAssignListUsersToGroupReq();
    message.group_id = object.group_id ?? 0;
    message.user_ids = object.user_ids?.map((e) => e) || [];
    return message;
  },
};

function createBaseAdminDeleteUserFromGroupReq(): AdminDeleteUserFromGroupReq {
  return { group_id: 0, user_id: 0 };
}

export const AdminDeleteUserFromGroupReq = {
  encode(message: AdminDeleteUserFromGroupReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group_id !== 0) {
      writer.uint32(8).int64(message.group_id);
    }
    if (message.user_id !== 0) {
      writer.uint32(16).int64(message.user_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminDeleteUserFromGroupReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminDeleteUserFromGroupReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.group_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminDeleteUserFromGroupReq {
    return {
      group_id: isSet(object.group_id) ? Number(object.group_id) : 0,
      user_id: isSet(object.user_id) ? Number(object.user_id) : 0,
    };
  },

  toJSON(message: AdminDeleteUserFromGroupReq): unknown {
    const obj: any = {};
    message.group_id !== undefined && (obj.group_id = Math.round(message.group_id));
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminDeleteUserFromGroupReq>, I>>(base?: I): AdminDeleteUserFromGroupReq {
    return AdminDeleteUserFromGroupReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminDeleteUserFromGroupReq>, I>>(object: I): AdminDeleteUserFromGroupReq {
    const message = createBaseAdminDeleteUserFromGroupReq();
    message.group_id = object.group_id ?? 0;
    message.user_id = object.user_id ?? 0;
    return message;
  },
};

function createBaseAdminGetAllUserGroupsRes(): AdminGetAllUserGroupsRes {
  return { userGroups: [] };
}

export const AdminGetAllUserGroupsRes = {
  encode(message: AdminGetAllUserGroupsRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.userGroups) {
      BasicUserGroup.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetAllUserGroupsRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetAllUserGroupsRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userGroups.push(BasicUserGroup.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetAllUserGroupsRes {
    return {
      userGroups: Array.isArray(object?.userGroups)
        ? object.userGroups.map((e: any) => BasicUserGroup.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AdminGetAllUserGroupsRes): unknown {
    const obj: any = {};
    if (message.userGroups) {
      obj.userGroups = message.userGroups.map((e) => e ? BasicUserGroup.toJSON(e) : undefined);
    } else {
      obj.userGroups = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetAllUserGroupsRes>, I>>(base?: I): AdminGetAllUserGroupsRes {
    return AdminGetAllUserGroupsRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetAllUserGroupsRes>, I>>(object: I): AdminGetAllUserGroupsRes {
    const message = createBaseAdminGetAllUserGroupsRes();
    message.userGroups = object.userGroups?.map((e) => BasicUserGroup.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBasicUserGroup(): BasicUserGroup {
  return { id: 0, title: "", description: undefined, created_at: "", updated_at: undefined, type: 0 };
}

export const BasicUserGroup = {
  encode(message: BasicUserGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.description !== undefined) {
      writer.uint32(26).string(message.description);
    }
    if (message.created_at !== "") {
      writer.uint32(34).string(message.created_at);
    }
    if (message.updated_at !== undefined) {
      writer.uint32(42).string(message.updated_at);
    }
    if (message.type !== 0) {
      writer.uint32(48).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BasicUserGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBasicUserGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.created_at = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.updated_at = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.type = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BasicUserGroup {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : undefined,
      created_at: isSet(object.created_at) ? String(object.created_at) : "",
      updated_at: isSet(object.updated_at) ? String(object.updated_at) : undefined,
      type: isSet(object.type) ? Number(object.type) : 0,
    };
  },

  toJSON(message: BasicUserGroup): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    message.created_at !== undefined && (obj.created_at = message.created_at);
    message.updated_at !== undefined && (obj.updated_at = message.updated_at);
    message.type !== undefined && (obj.type = Math.round(message.type));
    return obj;
  },

  create<I extends Exact<DeepPartial<BasicUserGroup>, I>>(base?: I): BasicUserGroup {
    return BasicUserGroup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BasicUserGroup>, I>>(object: I): BasicUserGroup {
    const message = createBaseBasicUserGroup();
    message.id = object.id ?? 0;
    message.title = object.title ?? "";
    message.description = object.description ?? undefined;
    message.created_at = object.created_at ?? "";
    message.updated_at = object.updated_at ?? undefined;
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseUserGroup(): UserGroup {
  return { id: 0, title: "", description: undefined, users: [], created_at: "", updated_at: undefined, type: 0 };
}

export const UserGroup = {
  encode(message: UserGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.description !== undefined) {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.users) {
      BasicUserInfoForGroup.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.created_at !== "") {
      writer.uint32(42).string(message.created_at);
    }
    if (message.updated_at !== undefined) {
      writer.uint32(50).string(message.updated_at);
    }
    if (message.type !== 0) {
      writer.uint32(56).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.users.push(BasicUserInfoForGroup.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.created_at = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updated_at = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.type = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroup {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : undefined,
      users: Array.isArray(object?.users) ? object.users.map((e: any) => BasicUserInfoForGroup.fromJSON(e)) : [],
      created_at: isSet(object.created_at) ? String(object.created_at) : "",
      updated_at: isSet(object.updated_at) ? String(object.updated_at) : undefined,
      type: isSet(object.type) ? Number(object.type) : 0,
    };
  },

  toJSON(message: UserGroup): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    if (message.users) {
      obj.users = message.users.map((e) => e ? BasicUserInfoForGroup.toJSON(e) : undefined);
    } else {
      obj.users = [];
    }
    message.created_at !== undefined && (obj.created_at = message.created_at);
    message.updated_at !== undefined && (obj.updated_at = message.updated_at);
    message.type !== undefined && (obj.type = Math.round(message.type));
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroup>, I>>(base?: I): UserGroup {
    return UserGroup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroup>, I>>(object: I): UserGroup {
    const message = createBaseUserGroup();
    message.id = object.id ?? 0;
    message.title = object.title ?? "";
    message.description = object.description ?? undefined;
    message.users = object.users?.map((e) => BasicUserInfoForGroup.fromPartial(e)) || [];
    message.created_at = object.created_at ?? "";
    message.updated_at = object.updated_at ?? undefined;
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseBasicUserInfoForGroup(): BasicUserInfoForGroup {
  return { user_id: 0, first_name: "", last_name: "", phone_number: "", email: "" };
}

export const BasicUserInfoForGroup = {
  encode(message: BasicUserInfoForGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    if (message.first_name !== "") {
      writer.uint32(18).string(message.first_name);
    }
    if (message.last_name !== "") {
      writer.uint32(26).string(message.last_name);
    }
    if (message.phone_number !== "") {
      writer.uint32(34).string(message.phone_number);
    }
    if (message.email !== "") {
      writer.uint32(42).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BasicUserInfoForGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBasicUserInfoForGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.first_name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.last_name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.phone_number = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BasicUserInfoForGroup {
    return {
      user_id: isSet(object.user_id) ? Number(object.user_id) : 0,
      first_name: isSet(object.first_name) ? String(object.first_name) : "",
      last_name: isSet(object.last_name) ? String(object.last_name) : "",
      phone_number: isSet(object.phone_number) ? String(object.phone_number) : "",
      email: isSet(object.email) ? String(object.email) : "",
    };
  },

  toJSON(message: BasicUserInfoForGroup): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    message.first_name !== undefined && (obj.first_name = message.first_name);
    message.last_name !== undefined && (obj.last_name = message.last_name);
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    message.email !== undefined && (obj.email = message.email);
    return obj;
  },

  create<I extends Exact<DeepPartial<BasicUserInfoForGroup>, I>>(base?: I): BasicUserInfoForGroup {
    return BasicUserInfoForGroup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BasicUserInfoForGroup>, I>>(object: I): BasicUserInfoForGroup {
    const message = createBaseBasicUserInfoForGroup();
    message.user_id = object.user_id ?? 0;
    message.first_name = object.first_name ?? "";
    message.last_name = object.last_name ?? "";
    message.phone_number = object.phone_number ?? "";
    message.email = object.email ?? "";
    return message;
  },
};

function createBaseInternalGetListGroupsByUserIdReq(): InternalGetListGroupsByUserIdReq {
  return { user_id: 0 };
}

export const InternalGetListGroupsByUserIdReq = {
  encode(message: InternalGetListGroupsByUserIdReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InternalGetListGroupsByUserIdReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInternalGetListGroupsByUserIdReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InternalGetListGroupsByUserIdReq {
    return { user_id: isSet(object.user_id) ? Number(object.user_id) : 0 };
  },

  toJSON(message: InternalGetListGroupsByUserIdReq): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    return obj;
  },

  create<I extends Exact<DeepPartial<InternalGetListGroupsByUserIdReq>, I>>(
    base?: I,
  ): InternalGetListGroupsByUserIdReq {
    return InternalGetListGroupsByUserIdReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InternalGetListGroupsByUserIdReq>, I>>(
    object: I,
  ): InternalGetListGroupsByUserIdReq {
    const message = createBaseInternalGetListGroupsByUserIdReq();
    message.user_id = object.user_id ?? 0;
    return message;
  },
};

function createBaseInternalGetListGroupsByUserIdRes(): InternalGetListGroupsByUserIdRes {
  return { group_ids: [], list: [] };
}

export const InternalGetListGroupsByUserIdRes = {
  encode(message: InternalGetListGroupsByUserIdRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.group_ids) {
      writer.int64(v);
    }
    writer.ldelim();
    for (const v of message.list) {
      BasicUserGroup.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InternalGetListGroupsByUserIdRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInternalGetListGroupsByUserIdRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.group_ids.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.group_ids.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.list.push(BasicUserGroup.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InternalGetListGroupsByUserIdRes {
    return {
      group_ids: Array.isArray(object?.group_ids) ? object.group_ids.map((e: any) => Number(e)) : [],
      list: Array.isArray(object?.list) ? object.list.map((e: any) => BasicUserGroup.fromJSON(e)) : [],
    };
  },

  toJSON(message: InternalGetListGroupsByUserIdRes): unknown {
    const obj: any = {};
    if (message.group_ids) {
      obj.group_ids = message.group_ids.map((e) => Math.round(e));
    } else {
      obj.group_ids = [];
    }
    if (message.list) {
      obj.list = message.list.map((e) => e ? BasicUserGroup.toJSON(e) : undefined);
    } else {
      obj.list = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InternalGetListGroupsByUserIdRes>, I>>(
    base?: I,
  ): InternalGetListGroupsByUserIdRes {
    return InternalGetListGroupsByUserIdRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InternalGetListGroupsByUserIdRes>, I>>(
    object: I,
  ): InternalGetListGroupsByUserIdRes {
    const message = createBaseInternalGetListGroupsByUserIdRes();
    message.group_ids = object.group_ids?.map((e) => e) || [];
    message.list = object.list?.map((e) => BasicUserGroup.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProviderGetUserProfileReq(): ProviderGetUserProfileReq {
  return { phone_number: "" };
}

export const ProviderGetUserProfileReq = {
  encode(message: ProviderGetUserProfileReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.phone_number !== "") {
      writer.uint32(10).string(message.phone_number);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProviderGetUserProfileReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProviderGetUserProfileReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phone_number = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProviderGetUserProfileReq {
    return { phone_number: isSet(object.phone_number) ? String(object.phone_number) : "" };
  },

  toJSON(message: ProviderGetUserProfileReq): unknown {
    const obj: any = {};
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProviderGetUserProfileReq>, I>>(base?: I): ProviderGetUserProfileReq {
    return ProviderGetUserProfileReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProviderGetUserProfileReq>, I>>(object: I): ProviderGetUserProfileReq {
    const message = createBaseProviderGetUserProfileReq();
    message.phone_number = object.phone_number ?? "";
    return message;
  },
};

function createBaseProviderGetUserProfileRes(): ProviderGetUserProfileRes {
  return {
    user_id: undefined,
    first_name: "",
    last_name: "",
    birthdate: "",
    suffix: undefined,
    email: undefined,
    phone_number: "",
    email_verified: false,
    phone_number_verified: false,
  };
}

export const ProviderGetUserProfileRes = {
  encode(message: ProviderGetUserProfileRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== undefined) {
      writer.uint32(8).int64(message.user_id);
    }
    if (message.first_name !== "") {
      writer.uint32(18).string(message.first_name);
    }
    if (message.last_name !== "") {
      writer.uint32(26).string(message.last_name);
    }
    if (message.birthdate !== "") {
      writer.uint32(34).string(message.birthdate);
    }
    if (message.suffix !== undefined) {
      writer.uint32(42).string(message.suffix);
    }
    if (message.email !== undefined) {
      writer.uint32(50).string(message.email);
    }
    if (message.phone_number !== "") {
      writer.uint32(58).string(message.phone_number);
    }
    if (message.email_verified === true) {
      writer.uint32(64).bool(message.email_verified);
    }
    if (message.phone_number_verified === true) {
      writer.uint32(72).bool(message.phone_number_verified);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProviderGetUserProfileRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProviderGetUserProfileRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.first_name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.last_name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.birthdate = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.suffix = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.email = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.phone_number = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.email_verified = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.phone_number_verified = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProviderGetUserProfileRes {
    return {
      user_id: isSet(object.user_id) ? Number(object.user_id) : undefined,
      first_name: isSet(object.first_name) ? String(object.first_name) : "",
      last_name: isSet(object.last_name) ? String(object.last_name) : "",
      birthdate: isSet(object.birthdate) ? String(object.birthdate) : "",
      suffix: isSet(object.suffix) ? String(object.suffix) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      phone_number: isSet(object.phone_number) ? String(object.phone_number) : "",
      email_verified: isSet(object.email_verified) ? Boolean(object.email_verified) : false,
      phone_number_verified: isSet(object.phone_number_verified) ? Boolean(object.phone_number_verified) : false,
    };
  },

  toJSON(message: ProviderGetUserProfileRes): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    message.first_name !== undefined && (obj.first_name = message.first_name);
    message.last_name !== undefined && (obj.last_name = message.last_name);
    message.birthdate !== undefined && (obj.birthdate = message.birthdate);
    message.suffix !== undefined && (obj.suffix = message.suffix);
    message.email !== undefined && (obj.email = message.email);
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    message.email_verified !== undefined && (obj.email_verified = message.email_verified);
    message.phone_number_verified !== undefined && (obj.phone_number_verified = message.phone_number_verified);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProviderGetUserProfileRes>, I>>(base?: I): ProviderGetUserProfileRes {
    return ProviderGetUserProfileRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProviderGetUserProfileRes>, I>>(object: I): ProviderGetUserProfileRes {
    const message = createBaseProviderGetUserProfileRes();
    message.user_id = object.user_id ?? undefined;
    message.first_name = object.first_name ?? "";
    message.last_name = object.last_name ?? "";
    message.birthdate = object.birthdate ?? "";
    message.suffix = object.suffix ?? undefined;
    message.email = object.email ?? undefined;
    message.phone_number = object.phone_number ?? "";
    message.email_verified = object.email_verified ?? false;
    message.phone_number_verified = object.phone_number_verified ?? false;
    return message;
  },
};

function createBaseProviderCreateUserReq(): ProviderCreateUserReq {
  return { phone_number: "" };
}

export const ProviderCreateUserReq = {
  encode(message: ProviderCreateUserReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.phone_number !== "") {
      writer.uint32(10).string(message.phone_number);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProviderCreateUserReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProviderCreateUserReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phone_number = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProviderCreateUserReq {
    return { phone_number: isSet(object.phone_number) ? String(object.phone_number) : "" };
  },

  toJSON(message: ProviderCreateUserReq): unknown {
    const obj: any = {};
    message.phone_number !== undefined && (obj.phone_number = message.phone_number);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProviderCreateUserReq>, I>>(base?: I): ProviderCreateUserReq {
    return ProviderCreateUserReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProviderCreateUserReq>, I>>(object: I): ProviderCreateUserReq {
    const message = createBaseProviderCreateUserReq();
    message.phone_number = object.phone_number ?? "";
    return message;
  },
};

function createBaseInternalGetAllUserIdsByGroupIdRes(): InternalGetAllUserIdsByGroupIdRes {
  return { user_ids: [] };
}

export const InternalGetAllUserIdsByGroupIdRes = {
  encode(message: InternalGetAllUserIdsByGroupIdRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.user_ids) {
      writer.int64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InternalGetAllUserIdsByGroupIdRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInternalGetAllUserIdsByGroupIdRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.user_ids.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.user_ids.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InternalGetAllUserIdsByGroupIdRes {
    return { user_ids: Array.isArray(object?.user_ids) ? object.user_ids.map((e: any) => Number(e)) : [] };
  },

  toJSON(message: InternalGetAllUserIdsByGroupIdRes): unknown {
    const obj: any = {};
    if (message.user_ids) {
      obj.user_ids = message.user_ids.map((e) => Math.round(e));
    } else {
      obj.user_ids = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InternalGetAllUserIdsByGroupIdRes>, I>>(
    base?: I,
  ): InternalGetAllUserIdsByGroupIdRes {
    return InternalGetAllUserIdsByGroupIdRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InternalGetAllUserIdsByGroupIdRes>, I>>(
    object: I,
  ): InternalGetAllUserIdsByGroupIdRes {
    const message = createBaseInternalGetAllUserIdsByGroupIdRes();
    message.user_ids = object.user_ids?.map((e) => e) || [];
    return message;
  },
};

function createBaseUserAssignInvitationCodeReq(): UserAssignInvitationCodeReq {
  return { iam: undefined, invitation_code: "" };
}

export const UserAssignInvitationCodeReq = {
  encode(message: UserAssignInvitationCodeReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.invitation_code !== "") {
      writer.uint32(18).string(message.invitation_code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserAssignInvitationCodeReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserAssignInvitationCodeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.invitation_code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserAssignInvitationCodeReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      invitation_code: isSet(object.invitation_code) ? String(object.invitation_code) : "",
    };
  },

  toJSON(message: UserAssignInvitationCodeReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.invitation_code !== undefined && (obj.invitation_code = message.invitation_code);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserAssignInvitationCodeReq>, I>>(base?: I): UserAssignInvitationCodeReq {
    return UserAssignInvitationCodeReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserAssignInvitationCodeReq>, I>>(object: I): UserAssignInvitationCodeReq {
    const message = createBaseUserAssignInvitationCodeReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.invitation_code = object.invitation_code ?? "";
    return message;
  },
};

function createBaseInvitationPointConfigs(): InvitationPointConfigs {
  return { list: [] };
}

export const InvitationPointConfigs = {
  encode(message: InvitationPointConfigs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.list) {
      InvitationPointConfig.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InvitationPointConfigs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvitationPointConfigs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.list.push(InvitationPointConfig.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InvitationPointConfigs {
    return { list: Array.isArray(object?.list) ? object.list.map((e: any) => InvitationPointConfig.fromJSON(e)) : [] };
  },

  toJSON(message: InvitationPointConfigs): unknown {
    const obj: any = {};
    if (message.list) {
      obj.list = message.list.map((e) => e ? InvitationPointConfig.toJSON(e) : undefined);
    } else {
      obj.list = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InvitationPointConfigs>, I>>(base?: I): InvitationPointConfigs {
    return InvitationPointConfigs.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InvitationPointConfigs>, I>>(object: I): InvitationPointConfigs {
    const message = createBaseInvitationPointConfigs();
    message.list = object.list?.map((e) => InvitationPointConfig.fromPartial(e)) || [];
    return message;
  },
};

function createBaseInvitationPointConfig(): InvitationPointConfig {
  return {
    id: 0,
    key: "",
    desc: "",
    fixed_point: 0,
    fixed_percentage: 0,
    primary_percentage: 0,
    father_L1_percentage: 0,
    father_L2_percentage: 0,
  };
}

export const InvitationPointConfig = {
  encode(message: InvitationPointConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.key !== "") {
      writer.uint32(18).string(message.key);
    }
    if (message.desc !== "") {
      writer.uint32(26).string(message.desc);
    }
    if (message.fixed_point !== 0) {
      writer.uint32(32).int32(message.fixed_point);
    }
    if (message.fixed_percentage !== 0) {
      writer.uint32(41).double(message.fixed_percentage);
    }
    if (message.primary_percentage !== 0) {
      writer.uint32(49).double(message.primary_percentage);
    }
    if (message.father_L1_percentage !== 0) {
      writer.uint32(57).double(message.father_L1_percentage);
    }
    if (message.father_L2_percentage !== 0) {
      writer.uint32(65).double(message.father_L2_percentage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InvitationPointConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvitationPointConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.key = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.desc = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.fixed_point = reader.int32();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.fixed_percentage = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.primary_percentage = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.father_L1_percentage = reader.double();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.father_L2_percentage = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InvitationPointConfig {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      key: isSet(object.key) ? String(object.key) : "",
      desc: isSet(object.desc) ? String(object.desc) : "",
      fixed_point: isSet(object.fixed_point) ? Number(object.fixed_point) : 0,
      fixed_percentage: isSet(object.fixed_percentage) ? Number(object.fixed_percentage) : 0,
      primary_percentage: isSet(object.primary_percentage) ? Number(object.primary_percentage) : 0,
      father_L1_percentage: isSet(object.father_L1_percentage) ? Number(object.father_L1_percentage) : 0,
      father_L2_percentage: isSet(object.father_L2_percentage) ? Number(object.father_L2_percentage) : 0,
    };
  },

  toJSON(message: InvitationPointConfig): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.key !== undefined && (obj.key = message.key);
    message.desc !== undefined && (obj.desc = message.desc);
    message.fixed_point !== undefined && (obj.fixed_point = Math.round(message.fixed_point));
    message.fixed_percentage !== undefined && (obj.fixed_percentage = message.fixed_percentage);
    message.primary_percentage !== undefined && (obj.primary_percentage = message.primary_percentage);
    message.father_L1_percentage !== undefined && (obj.father_L1_percentage = message.father_L1_percentage);
    message.father_L2_percentage !== undefined && (obj.father_L2_percentage = message.father_L2_percentage);
    return obj;
  },

  create<I extends Exact<DeepPartial<InvitationPointConfig>, I>>(base?: I): InvitationPointConfig {
    return InvitationPointConfig.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InvitationPointConfig>, I>>(object: I): InvitationPointConfig {
    const message = createBaseInvitationPointConfig();
    message.id = object.id ?? 0;
    message.key = object.key ?? "";
    message.desc = object.desc ?? "";
    message.fixed_point = object.fixed_point ?? 0;
    message.fixed_percentage = object.fixed_percentage ?? 0;
    message.primary_percentage = object.primary_percentage ?? 0;
    message.father_L1_percentage = object.father_L1_percentage ?? 0;
    message.father_L2_percentage = object.father_L2_percentage ?? 0;
    return message;
  },
};

function createBaseUserInvitationList(): UserInvitationList {
  return { l1: [], l2: [] };
}

export const UserInvitationList = {
  encode(message: UserInvitationList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.l1) {
      UserInvitationItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.l2) {
      UserInvitationItem.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserInvitationList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserInvitationList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.l1.push(UserInvitationItem.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.l2.push(UserInvitationItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserInvitationList {
    return {
      l1: Array.isArray(object?.l1) ? object.l1.map((e: any) => UserInvitationItem.fromJSON(e)) : [],
      l2: Array.isArray(object?.l2) ? object.l2.map((e: any) => UserInvitationItem.fromJSON(e)) : [],
    };
  },

  toJSON(message: UserInvitationList): unknown {
    const obj: any = {};
    if (message.l1) {
      obj.l1 = message.l1.map((e) => e ? UserInvitationItem.toJSON(e) : undefined);
    } else {
      obj.l1 = [];
    }
    if (message.l2) {
      obj.l2 = message.l2.map((e) => e ? UserInvitationItem.toJSON(e) : undefined);
    } else {
      obj.l2 = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserInvitationList>, I>>(base?: I): UserInvitationList {
    return UserInvitationList.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserInvitationList>, I>>(object: I): UserInvitationList {
    const message = createBaseUserInvitationList();
    message.l1 = object.l1?.map((e) => UserInvitationItem.fromPartial(e)) || [];
    message.l2 = object.l2?.map((e) => UserInvitationItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserInvitationItem(): UserInvitationItem {
  return { info: undefined, created_at: "", children: [] };
}

export const UserInvitationItem = {
  encode(message: UserInvitationItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.info !== undefined) {
      BasicUserInfo.encode(message.info, writer.uint32(10).fork()).ldelim();
    }
    if (message.created_at !== "") {
      writer.uint32(26).string(message.created_at);
    }
    for (const v of message.children) {
      UserInvitationItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserInvitationItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserInvitationItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.info = BasicUserInfo.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.created_at = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.children.push(UserInvitationItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserInvitationItem {
    return {
      info: isSet(object.info) ? BasicUserInfo.fromJSON(object.info) : undefined,
      created_at: isSet(object.created_at) ? String(object.created_at) : "",
      children: Array.isArray(object?.children) ? object.children.map((e: any) => UserInvitationItem.fromJSON(e)) : [],
    };
  },

  toJSON(message: UserInvitationItem): unknown {
    const obj: any = {};
    message.info !== undefined && (obj.info = message.info ? BasicUserInfo.toJSON(message.info) : undefined);
    message.created_at !== undefined && (obj.created_at = message.created_at);
    if (message.children) {
      obj.children = message.children.map((e) => e ? UserInvitationItem.toJSON(e) : undefined);
    } else {
      obj.children = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserInvitationItem>, I>>(base?: I): UserInvitationItem {
    return UserInvitationItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserInvitationItem>, I>>(object: I): UserInvitationItem {
    const message = createBaseUserInvitationItem();
    message.info = (object.info !== undefined && object.info !== null)
      ? BasicUserInfo.fromPartial(object.info)
      : undefined;
    message.created_at = object.created_at ?? "";
    message.children = object.children?.map((e) => UserInvitationItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserInvitationCodeList(): UserInvitationCodeList {
  return { list: [] };
}

export const UserInvitationCodeList = {
  encode(message: UserInvitationCodeList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.list) {
      UserInvitationCodeItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserInvitationCodeList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserInvitationCodeList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.list.push(UserInvitationCodeItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserInvitationCodeList {
    return { list: Array.isArray(object?.list) ? object.list.map((e: any) => UserInvitationCodeItem.fromJSON(e)) : [] };
  },

  toJSON(message: UserInvitationCodeList): unknown {
    const obj: any = {};
    if (message.list) {
      obj.list = message.list.map((e) => e ? UserInvitationCodeItem.toJSON(e) : undefined);
    } else {
      obj.list = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserInvitationCodeList>, I>>(base?: I): UserInvitationCodeList {
    return UserInvitationCodeList.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserInvitationCodeList>, I>>(object: I): UserInvitationCodeList {
    const message = createBaseUserInvitationCodeList();
    message.list = object.list?.map((e) => UserInvitationCodeItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserInvitationCodeItem(): UserInvitationCodeItem {
  return { id: 0, invitation_code: "", status: 0, created_at: "", l1_sum: 0, l2_sum: 0 };
}

export const UserInvitationCodeItem = {
  encode(message: UserInvitationCodeItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.invitation_code !== "") {
      writer.uint32(18).string(message.invitation_code);
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    if (message.created_at !== "") {
      writer.uint32(34).string(message.created_at);
    }
    if (message.l1_sum !== 0) {
      writer.uint32(40).int64(message.l1_sum);
    }
    if (message.l2_sum !== 0) {
      writer.uint32(48).int64(message.l2_sum);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserInvitationCodeItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserInvitationCodeItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.invitation_code = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.created_at = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.l1_sum = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.l2_sum = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserInvitationCodeItem {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      invitation_code: isSet(object.invitation_code) ? String(object.invitation_code) : "",
      status: isSet(object.status) ? Number(object.status) : 0,
      created_at: isSet(object.created_at) ? String(object.created_at) : "",
      l1_sum: isSet(object.l1_sum) ? Number(object.l1_sum) : 0,
      l2_sum: isSet(object.l2_sum) ? Number(object.l2_sum) : 0,
    };
  },

  toJSON(message: UserInvitationCodeItem): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.invitation_code !== undefined && (obj.invitation_code = message.invitation_code);
    message.status !== undefined && (obj.status = Math.round(message.status));
    message.created_at !== undefined && (obj.created_at = message.created_at);
    message.l1_sum !== undefined && (obj.l1_sum = Math.round(message.l1_sum));
    message.l2_sum !== undefined && (obj.l2_sum = Math.round(message.l2_sum));
    return obj;
  },

  create<I extends Exact<DeepPartial<UserInvitationCodeItem>, I>>(base?: I): UserInvitationCodeItem {
    return UserInvitationCodeItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserInvitationCodeItem>, I>>(object: I): UserInvitationCodeItem {
    const message = createBaseUserInvitationCodeItem();
    message.id = object.id ?? 0;
    message.invitation_code = object.invitation_code ?? "";
    message.status = object.status ?? 0;
    message.created_at = object.created_at ?? "";
    message.l1_sum = object.l1_sum ?? 0;
    message.l2_sum = object.l2_sum ?? 0;
    return message;
  },
};

function createBaseUserDeleteInvitationCodeReq(): UserDeleteInvitationCodeReq {
  return { iam: undefined, invitation_id: 0 };
}

export const UserDeleteInvitationCodeReq = {
  encode(message: UserDeleteInvitationCodeReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.invitation_id !== 0) {
      writer.uint32(16).int32(message.invitation_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserDeleteInvitationCodeReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserDeleteInvitationCodeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.invitation_id = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserDeleteInvitationCodeReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      invitation_id: isSet(object.invitation_id) ? Number(object.invitation_id) : 0,
    };
  },

  toJSON(message: UserDeleteInvitationCodeReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.invitation_id !== undefined && (obj.invitation_id = Math.round(message.invitation_id));
    return obj;
  },

  create<I extends Exact<DeepPartial<UserDeleteInvitationCodeReq>, I>>(base?: I): UserDeleteInvitationCodeReq {
    return UserDeleteInvitationCodeReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserDeleteInvitationCodeReq>, I>>(object: I): UserDeleteInvitationCodeReq {
    const message = createBaseUserDeleteInvitationCodeReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.invitation_id = object.invitation_id ?? 0;
    return message;
  },
};

function createBaseUserGetInvitationListByInvitationCodeReq(): UserGetInvitationListByInvitationCodeReq {
  return { iam: undefined, invitation_id: 0 };
}

export const UserGetInvitationListByInvitationCodeReq = {
  encode(message: UserGetInvitationListByInvitationCodeReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.invitation_id !== 0) {
      writer.uint32(16).int32(message.invitation_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGetInvitationListByInvitationCodeReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGetInvitationListByInvitationCodeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.invitation_id = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGetInvitationListByInvitationCodeReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      invitation_id: isSet(object.invitation_id) ? Number(object.invitation_id) : 0,
    };
  },

  toJSON(message: UserGetInvitationListByInvitationCodeReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.invitation_id !== undefined && (obj.invitation_id = Math.round(message.invitation_id));
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGetInvitationListByInvitationCodeReq>, I>>(
    base?: I,
  ): UserGetInvitationListByInvitationCodeReq {
    return UserGetInvitationListByInvitationCodeReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGetInvitationListByInvitationCodeReq>, I>>(
    object: I,
  ): UserGetInvitationListByInvitationCodeReq {
    const message = createBaseUserGetInvitationListByInvitationCodeReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.invitation_id = object.invitation_id ?? 0;
    return message;
  },
};

function createBaseAdminUpdateConfigMapsReq(): AdminUpdateConfigMapsReq {
  return { list: [] };
}

export const AdminUpdateConfigMapsReq = {
  encode(message: AdminUpdateConfigMapsReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.list) {
      AdminUpdateConfigMapReq.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminUpdateConfigMapsReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminUpdateConfigMapsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.list.push(AdminUpdateConfigMapReq.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminUpdateConfigMapsReq {
    return {
      list: Array.isArray(object?.list) ? object.list.map((e: any) => AdminUpdateConfigMapReq.fromJSON(e)) : [],
    };
  },

  toJSON(message: AdminUpdateConfigMapsReq): unknown {
    const obj: any = {};
    if (message.list) {
      obj.list = message.list.map((e) => e ? AdminUpdateConfigMapReq.toJSON(e) : undefined);
    } else {
      obj.list = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminUpdateConfigMapsReq>, I>>(base?: I): AdminUpdateConfigMapsReq {
    return AdminUpdateConfigMapsReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminUpdateConfigMapsReq>, I>>(object: I): AdminUpdateConfigMapsReq {
    const message = createBaseAdminUpdateConfigMapsReq();
    message.list = object.list?.map((e) => AdminUpdateConfigMapReq.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAdminUpdateConfigMapReq(): AdminUpdateConfigMapReq {
  return { key: "", value: "" };
}

export const AdminUpdateConfigMapReq = {
  encode(message: AdminUpdateConfigMapReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminUpdateConfigMapReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminUpdateConfigMapReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminUpdateConfigMapReq {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: AdminUpdateConfigMapReq): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminUpdateConfigMapReq>, I>>(base?: I): AdminUpdateConfigMapReq {
    return AdminUpdateConfigMapReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminUpdateConfigMapReq>, I>>(object: I): AdminUpdateConfigMapReq {
    const message = createBaseAdminUpdateConfigMapReq();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseAdminGetAllConfigMapsRes(): AdminGetAllConfigMapsRes {
  return { config_maps: [] };
}

export const AdminGetAllConfigMapsRes = {
  encode(message: AdminGetAllConfigMapsRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.config_maps) {
      ConfigMap.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetAllConfigMapsRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetAllConfigMapsRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.config_maps.push(ConfigMap.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetAllConfigMapsRes {
    return {
      config_maps: Array.isArray(object?.config_maps) ? object.config_maps.map((e: any) => ConfigMap.fromJSON(e)) : [],
    };
  },

  toJSON(message: AdminGetAllConfigMapsRes): unknown {
    const obj: any = {};
    if (message.config_maps) {
      obj.config_maps = message.config_maps.map((e) => e ? ConfigMap.toJSON(e) : undefined);
    } else {
      obj.config_maps = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetAllConfigMapsRes>, I>>(base?: I): AdminGetAllConfigMapsRes {
    return AdminGetAllConfigMapsRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetAllConfigMapsRes>, I>>(object: I): AdminGetAllConfigMapsRes {
    const message = createBaseAdminGetAllConfigMapsRes();
    message.config_maps = object.config_maps?.map((e) => ConfigMap.fromPartial(e)) || [];
    return message;
  },
};

function createBaseConfigMap(): ConfigMap {
  return { id: 0, key: "", description: "", value: "", unit: "" };
}

export const ConfigMap = {
  encode(message: ConfigMap, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.key !== "") {
      writer.uint32(18).string(message.key);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.value !== "") {
      writer.uint32(34).string(message.value);
    }
    if (message.unit !== "") {
      writer.uint32(42).string(message.unit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConfigMap {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfigMap();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.key = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.value = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.unit = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfigMap {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      key: isSet(object.key) ? String(object.key) : "",
      description: isSet(object.description) ? String(object.description) : "",
      value: isSet(object.value) ? String(object.value) : "",
      unit: isSet(object.unit) ? String(object.unit) : "",
    };
  },

  toJSON(message: ConfigMap): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.key !== undefined && (obj.key = message.key);
    message.description !== undefined && (obj.description = message.description);
    message.value !== undefined && (obj.value = message.value);
    message.unit !== undefined && (obj.unit = message.unit);
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfigMap>, I>>(base?: I): ConfigMap {
    return ConfigMap.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConfigMap>, I>>(object: I): ConfigMap {
    const message = createBaseConfigMap();
    message.id = object.id ?? 0;
    message.key = object.key ?? "";
    message.description = object.description ?? "";
    message.value = object.value ?? "";
    message.unit = object.unit ?? "";
    return message;
  },
};

function createBaseUserPointExchangeReq(): UserPointExchangeReq {
  return { iam: undefined, point: 0 };
}

export const UserPointExchangeReq = {
  encode(message: UserPointExchangeReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.point !== 0) {
      writer.uint32(16).int64(message.point);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserPointExchangeReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserPointExchangeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.point = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserPointExchangeReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      point: isSet(object.point) ? Number(object.point) : 0,
    };
  },

  toJSON(message: UserPointExchangeReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.point !== undefined && (obj.point = Math.round(message.point));
    return obj;
  },

  create<I extends Exact<DeepPartial<UserPointExchangeReq>, I>>(base?: I): UserPointExchangeReq {
    return UserPointExchangeReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserPointExchangeReq>, I>>(object: I): UserPointExchangeReq {
    const message = createBaseUserPointExchangeReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.point = object.point ?? 0;
    return message;
  },
};

function createBaseIncreasePointReq(): IncreasePointReq {
  return { user_id: 0, key: 0, amount: 0 };
}

export const IncreasePointReq = {
  encode(message: IncreasePointReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    if (message.key !== 0) {
      writer.uint32(16).int32(message.key);
    }
    if (message.amount !== 0) {
      writer.uint32(24).int64(message.amount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IncreasePointReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIncreasePointReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.key = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.amount = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IncreasePointReq {
    return {
      user_id: isSet(object.user_id) ? Number(object.user_id) : 0,
      key: isSet(object.key) ? increaseInvitationPointKeyFromJSON(object.key) : 0,
      amount: isSet(object.amount) ? Number(object.amount) : 0,
    };
  },

  toJSON(message: IncreasePointReq): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    message.key !== undefined && (obj.key = increaseInvitationPointKeyToJSON(message.key));
    message.amount !== undefined && (obj.amount = Math.round(message.amount));
    return obj;
  },

  create<I extends Exact<DeepPartial<IncreasePointReq>, I>>(base?: I): IncreasePointReq {
    return IncreasePointReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IncreasePointReq>, I>>(object: I): IncreasePointReq {
    const message = createBaseIncreasePointReq();
    message.user_id = object.user_id ?? 0;
    message.key = object.key ?? 0;
    message.amount = object.amount ?? 0;
    return message;
  },
};

function createBaseAdminCreateMarketerExchangeRateReq(): AdminCreateMarketerExchangeRateReq {
  return { user_id: 0, exchange_rate: 0, status: 0 };
}

export const AdminCreateMarketerExchangeRateReq = {
  encode(message: AdminCreateMarketerExchangeRateReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    if (message.exchange_rate !== 0) {
      writer.uint32(16).int64(message.exchange_rate);
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminCreateMarketerExchangeRateReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminCreateMarketerExchangeRateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.exchange_rate = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminCreateMarketerExchangeRateReq {
    return {
      user_id: isSet(object.user_id) ? Number(object.user_id) : 0,
      exchange_rate: isSet(object.exchange_rate) ? Number(object.exchange_rate) : 0,
      status: isSet(object.status) ? Number(object.status) : 0,
    };
  },

  toJSON(message: AdminCreateMarketerExchangeRateReq): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    message.exchange_rate !== undefined && (obj.exchange_rate = Math.round(message.exchange_rate));
    message.status !== undefined && (obj.status = Math.round(message.status));
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminCreateMarketerExchangeRateReq>, I>>(
    base?: I,
  ): AdminCreateMarketerExchangeRateReq {
    return AdminCreateMarketerExchangeRateReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminCreateMarketerExchangeRateReq>, I>>(
    object: I,
  ): AdminCreateMarketerExchangeRateReq {
    const message = createBaseAdminCreateMarketerExchangeRateReq();
    message.user_id = object.user_id ?? 0;
    message.exchange_rate = object.exchange_rate ?? 0;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseAdminUpdateMarketerExchangeRateReq(): AdminUpdateMarketerExchangeRateReq {
  return { id: 0, exchange_rate: 0, status: 0 };
}

export const AdminUpdateMarketerExchangeRateReq = {
  encode(message: AdminUpdateMarketerExchangeRateReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.exchange_rate !== 0) {
      writer.uint32(16).int64(message.exchange_rate);
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminUpdateMarketerExchangeRateReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminUpdateMarketerExchangeRateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.exchange_rate = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminUpdateMarketerExchangeRateReq {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      exchange_rate: isSet(object.exchange_rate) ? Number(object.exchange_rate) : 0,
      status: isSet(object.status) ? Number(object.status) : 0,
    };
  },

  toJSON(message: AdminUpdateMarketerExchangeRateReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.exchange_rate !== undefined && (obj.exchange_rate = Math.round(message.exchange_rate));
    message.status !== undefined && (obj.status = Math.round(message.status));
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminUpdateMarketerExchangeRateReq>, I>>(
    base?: I,
  ): AdminUpdateMarketerExchangeRateReq {
    return AdminUpdateMarketerExchangeRateReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminUpdateMarketerExchangeRateReq>, I>>(
    object: I,
  ): AdminUpdateMarketerExchangeRateReq {
    const message = createBaseAdminUpdateMarketerExchangeRateReq();
    message.id = object.id ?? 0;
    message.exchange_rate = object.exchange_rate ?? 0;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseMarketerExchangeRate(): MarketerExchangeRate {
  return { id: 0, user: undefined, exchange_rate: 0, status: 0 };
}

export const MarketerExchangeRate = {
  encode(message: MarketerExchangeRate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.user !== undefined) {
      BasicUserInfo.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    if (message.exchange_rate !== 0) {
      writer.uint32(24).int64(message.exchange_rate);
    }
    if (message.status !== 0) {
      writer.uint32(32).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketerExchangeRate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketerExchangeRate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = BasicUserInfo.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.exchange_rate = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MarketerExchangeRate {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      user: isSet(object.user) ? BasicUserInfo.fromJSON(object.user) : undefined,
      exchange_rate: isSet(object.exchange_rate) ? Number(object.exchange_rate) : 0,
      status: isSet(object.status) ? Number(object.status) : 0,
    };
  },

  toJSON(message: MarketerExchangeRate): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.user !== undefined && (obj.user = message.user ? BasicUserInfo.toJSON(message.user) : undefined);
    message.exchange_rate !== undefined && (obj.exchange_rate = Math.round(message.exchange_rate));
    message.status !== undefined && (obj.status = Math.round(message.status));
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketerExchangeRate>, I>>(base?: I): MarketerExchangeRate {
    return MarketerExchangeRate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MarketerExchangeRate>, I>>(object: I): MarketerExchangeRate {
    const message = createBaseMarketerExchangeRate();
    message.id = object.id ?? 0;
    message.user = (object.user !== undefined && object.user !== null)
      ? BasicUserInfo.fromPartial(object.user)
      : undefined;
    message.exchange_rate = object.exchange_rate ?? 0;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseAdminGetAllMarketerExchangeRatesRes(): AdminGetAllMarketerExchangeRatesRes {
  return { list: [] };
}

export const AdminGetAllMarketerExchangeRatesRes = {
  encode(message: AdminGetAllMarketerExchangeRatesRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.list) {
      MarketerExchangeRate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminGetAllMarketerExchangeRatesRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminGetAllMarketerExchangeRatesRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.list.push(MarketerExchangeRate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminGetAllMarketerExchangeRatesRes {
    return { list: Array.isArray(object?.list) ? object.list.map((e: any) => MarketerExchangeRate.fromJSON(e)) : [] };
  },

  toJSON(message: AdminGetAllMarketerExchangeRatesRes): unknown {
    const obj: any = {};
    if (message.list) {
      obj.list = message.list.map((e) => e ? MarketerExchangeRate.toJSON(e) : undefined);
    } else {
      obj.list = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminGetAllMarketerExchangeRatesRes>, I>>(
    base?: I,
  ): AdminGetAllMarketerExchangeRatesRes {
    return AdminGetAllMarketerExchangeRatesRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminGetAllMarketerExchangeRatesRes>, I>>(
    object: I,
  ): AdminGetAllMarketerExchangeRatesRes {
    const message = createBaseAdminGetAllMarketerExchangeRatesRes();
    message.list = object.list?.map((e) => MarketerExchangeRate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserVerificationListReq(): UserVerificationListReq {
  return { iam: undefined };
}

export const UserVerificationListReq = {
  encode(message: UserVerificationListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserVerificationListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserVerificationListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserVerificationListReq {
    return { iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined };
  },

  toJSON(message: UserVerificationListReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserVerificationListReq>, I>>(base?: I): UserVerificationListReq {
    return UserVerificationListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserVerificationListReq>, I>>(object: I): UserVerificationListReq {
    const message = createBaseUserVerificationListReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    return message;
  },
};

function createBaseUserVerificationListRes(): UserVerificationListRes {
  return { list: [] };
}

export const UserVerificationListRes = {
  encode(message: UserVerificationListRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.list) {
      UserVerificationItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserVerificationListRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserVerificationListRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.list.push(UserVerificationItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserVerificationListRes {
    return { list: Array.isArray(object?.list) ? object.list.map((e: any) => UserVerificationItem.fromJSON(e)) : [] };
  },

  toJSON(message: UserVerificationListRes): unknown {
    const obj: any = {};
    if (message.list) {
      obj.list = message.list.map((e) => e ? UserVerificationItem.toJSON(e) : undefined);
    } else {
      obj.list = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserVerificationListRes>, I>>(base?: I): UserVerificationListRes {
    return UserVerificationListRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserVerificationListRes>, I>>(object: I): UserVerificationListRes {
    const message = createBaseUserVerificationListRes();
    message.list = object.list?.map((e) => UserVerificationItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserVerificationItem(): UserVerificationItem {
  return {
    id: 0,
    tracking_code: "",
    response_string: "",
    provider: 0,
    type: 0,
    verified: false,
    updated_at: "",
    created_at: "",
    request_string: "",
  };
}

export const UserVerificationItem = {
  encode(message: UserVerificationItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.tracking_code !== "") {
      writer.uint32(18).string(message.tracking_code);
    }
    if (message.response_string !== "") {
      writer.uint32(26).string(message.response_string);
    }
    if (message.provider !== 0) {
      writer.uint32(32).int32(message.provider);
    }
    if (message.type !== 0) {
      writer.uint32(40).int32(message.type);
    }
    if (message.verified === true) {
      writer.uint32(48).bool(message.verified);
    }
    if (message.updated_at !== "") {
      writer.uint32(58).string(message.updated_at);
    }
    if (message.created_at !== "") {
      writer.uint32(66).string(message.created_at);
    }
    if (message.request_string !== "") {
      writer.uint32(74).string(message.request_string);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserVerificationItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserVerificationItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tracking_code = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.response_string = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.provider = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.type = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.verified = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.updated_at = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.created_at = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.request_string = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserVerificationItem {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      tracking_code: isSet(object.tracking_code) ? String(object.tracking_code) : "",
      response_string: isSet(object.response_string) ? String(object.response_string) : "",
      provider: isSet(object.provider) ? Number(object.provider) : 0,
      type: isSet(object.type) ? Number(object.type) : 0,
      verified: isSet(object.verified) ? Boolean(object.verified) : false,
      updated_at: isSet(object.updated_at) ? String(object.updated_at) : "",
      created_at: isSet(object.created_at) ? String(object.created_at) : "",
      request_string: isSet(object.request_string) ? String(object.request_string) : "",
    };
  },

  toJSON(message: UserVerificationItem): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.tracking_code !== undefined && (obj.tracking_code = message.tracking_code);
    message.response_string !== undefined && (obj.response_string = message.response_string);
    message.provider !== undefined && (obj.provider = Math.round(message.provider));
    message.type !== undefined && (obj.type = Math.round(message.type));
    message.verified !== undefined && (obj.verified = message.verified);
    message.updated_at !== undefined && (obj.updated_at = message.updated_at);
    message.created_at !== undefined && (obj.created_at = message.created_at);
    message.request_string !== undefined && (obj.request_string = message.request_string);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserVerificationItem>, I>>(base?: I): UserVerificationItem {
    return UserVerificationItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserVerificationItem>, I>>(object: I): UserVerificationItem {
    const message = createBaseUserVerificationItem();
    message.id = object.id ?? 0;
    message.tracking_code = object.tracking_code ?? "";
    message.response_string = object.response_string ?? "";
    message.provider = object.provider ?? 0;
    message.type = object.type ?? 0;
    message.verified = object.verified ?? false;
    message.updated_at = object.updated_at ?? "";
    message.created_at = object.created_at ?? "";
    message.request_string = object.request_string ?? "";
    return message;
  },
};

function createBaseUserVerificationReq(): UserVerificationReq {
  return { iam: undefined };
}

export const UserVerificationReq = {
  encode(message: UserVerificationReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserVerificationReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserVerificationReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserVerificationReq {
    return { iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined };
  },

  toJSON(message: UserVerificationReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserVerificationReq>, I>>(base?: I): UserVerificationReq {
    return UserVerificationReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserVerificationReq>, I>>(object: I): UserVerificationReq {
    const message = createBaseUserVerificationReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    return message;
  },
};

function createBaseUserVerificationRes(): UserVerificationRes {
  return {
    id: 0,
    provider: 0,
    updated_at: "",
    created_at: "",
    status: 0,
    shareable_url: "",
    profile_verification: undefined,
    address_status: undefined,
    document_verification: undefined,
  };
}

export const UserVerificationRes = {
  encode(message: UserVerificationRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.provider !== 0) {
      writer.uint32(16).int32(message.provider);
    }
    if (message.updated_at !== "") {
      writer.uint32(26).string(message.updated_at);
    }
    if (message.created_at !== "") {
      writer.uint32(34).string(message.created_at);
    }
    if (message.status !== 0) {
      writer.uint32(40).int32(message.status);
    }
    if (message.shareable_url !== "") {
      writer.uint32(50).string(message.shareable_url);
    }
    if (message.profile_verification !== undefined) {
      ProfileVerificationRes.encode(message.profile_verification, writer.uint32(58).fork()).ldelim();
    }
    if (message.address_status !== undefined) {
      AddressVerificationRes.encode(message.address_status, writer.uint32(66).fork()).ldelim();
    }
    if (message.document_verification !== undefined) {
      DocumentVerification.encode(message.document_verification, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserVerificationRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserVerificationRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.provider = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updated_at = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.created_at = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.status = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.shareable_url = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.profile_verification = ProfileVerificationRes.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.address_status = AddressVerificationRes.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.document_verification = DocumentVerification.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserVerificationRes {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      provider: isSet(object.provider) ? Number(object.provider) : 0,
      updated_at: isSet(object.updated_at) ? String(object.updated_at) : "",
      created_at: isSet(object.created_at) ? String(object.created_at) : "",
      status: isSet(object.status) ? Number(object.status) : 0,
      shareable_url: isSet(object.shareable_url) ? String(object.shareable_url) : "",
      profile_verification: isSet(object.profile_verification)
        ? ProfileVerificationRes.fromJSON(object.profile_verification)
        : undefined,
      address_status: isSet(object.address_status) ? AddressVerificationRes.fromJSON(object.address_status) : undefined,
      document_verification: isSet(object.document_verification)
        ? DocumentVerification.fromJSON(object.document_verification)
        : undefined,
    };
  },

  toJSON(message: UserVerificationRes): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.provider !== undefined && (obj.provider = Math.round(message.provider));
    message.updated_at !== undefined && (obj.updated_at = message.updated_at);
    message.created_at !== undefined && (obj.created_at = message.created_at);
    message.status !== undefined && (obj.status = Math.round(message.status));
    message.shareable_url !== undefined && (obj.shareable_url = message.shareable_url);
    message.profile_verification !== undefined && (obj.profile_verification = message.profile_verification
      ? ProfileVerificationRes.toJSON(message.profile_verification)
      : undefined);
    message.address_status !== undefined &&
      (obj.address_status = message.address_status ? AddressVerificationRes.toJSON(message.address_status) : undefined);
    message.document_verification !== undefined && (obj.document_verification = message.document_verification
      ? DocumentVerification.toJSON(message.document_verification)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserVerificationRes>, I>>(base?: I): UserVerificationRes {
    return UserVerificationRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserVerificationRes>, I>>(object: I): UserVerificationRes {
    const message = createBaseUserVerificationRes();
    message.id = object.id ?? 0;
    message.provider = object.provider ?? 0;
    message.updated_at = object.updated_at ?? "";
    message.created_at = object.created_at ?? "";
    message.status = object.status ?? 0;
    message.shareable_url = object.shareable_url ?? "";
    message.profile_verification = (object.profile_verification !== undefined && object.profile_verification !== null)
      ? ProfileVerificationRes.fromPartial(object.profile_verification)
      : undefined;
    message.address_status = (object.address_status !== undefined && object.address_status !== null)
      ? AddressVerificationRes.fromPartial(object.address_status)
      : undefined;
    message.document_verification =
      (object.document_verification !== undefined && object.document_verification !== null)
        ? DocumentVerification.fromPartial(object.document_verification)
        : undefined;
    return message;
  },
};

function createBaseDocumentVerification(): DocumentVerification {
  return { dirver_licance: undefined };
}

export const DocumentVerification = {
  encode(message: DocumentVerification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dirver_licance !== undefined) {
      DocumentVerificationDirverLicanse.encode(message.dirver_licance, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentVerification {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDocumentVerification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dirver_licance = DocumentVerificationDirverLicanse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DocumentVerification {
    return {
      dirver_licance: isSet(object.dirver_licance)
        ? DocumentVerificationDirverLicanse.fromJSON(object.dirver_licance)
        : undefined,
    };
  },

  toJSON(message: DocumentVerification): unknown {
    const obj: any = {};
    message.dirver_licance !== undefined && (obj.dirver_licance = message.dirver_licance
      ? DocumentVerificationDirverLicanse.toJSON(message.dirver_licance)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DocumentVerification>, I>>(base?: I): DocumentVerification {
    return DocumentVerification.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DocumentVerification>, I>>(object: I): DocumentVerification {
    const message = createBaseDocumentVerification();
    message.dirver_licance = (object.dirver_licance !== undefined && object.dirver_licance !== null)
      ? DocumentVerificationDirverLicanse.fromPartial(object.dirver_licance)
      : undefined;
    return message;
  },
};

function createBaseDocumentVerificationDirverLicanse(): DocumentVerificationDirverLicanse {
  return { status: "", birthdate_status: "", expiration_date_status: "", issuing_country_status: "", name_status: "" };
}

export const DocumentVerificationDirverLicanse = {
  encode(message: DocumentVerificationDirverLicanse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    if (message.birthdate_status !== "") {
      writer.uint32(18).string(message.birthdate_status);
    }
    if (message.expiration_date_status !== "") {
      writer.uint32(26).string(message.expiration_date_status);
    }
    if (message.issuing_country_status !== "") {
      writer.uint32(34).string(message.issuing_country_status);
    }
    if (message.name_status !== "") {
      writer.uint32(42).string(message.name_status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentVerificationDirverLicanse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDocumentVerificationDirverLicanse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.birthdate_status = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.expiration_date_status = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.issuing_country_status = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name_status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DocumentVerificationDirverLicanse {
    return {
      status: isSet(object.status) ? String(object.status) : "",
      birthdate_status: isSet(object.birthdate_status) ? String(object.birthdate_status) : "",
      expiration_date_status: isSet(object.expiration_date_status) ? String(object.expiration_date_status) : "",
      issuing_country_status: isSet(object.issuing_country_status) ? String(object.issuing_country_status) : "",
      name_status: isSet(object.name_status) ? String(object.name_status) : "",
    };
  },

  toJSON(message: DocumentVerificationDirverLicanse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = message.status);
    message.birthdate_status !== undefined && (obj.birthdate_status = message.birthdate_status);
    message.expiration_date_status !== undefined && (obj.expiration_date_status = message.expiration_date_status);
    message.issuing_country_status !== undefined && (obj.issuing_country_status = message.issuing_country_status);
    message.name_status !== undefined && (obj.name_status = message.name_status);
    return obj;
  },

  create<I extends Exact<DeepPartial<DocumentVerificationDirverLicanse>, I>>(
    base?: I,
  ): DocumentVerificationDirverLicanse {
    return DocumentVerificationDirverLicanse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DocumentVerificationDirverLicanse>, I>>(
    object: I,
  ): DocumentVerificationDirverLicanse {
    const message = createBaseDocumentVerificationDirverLicanse();
    message.status = object.status ?? "";
    message.birthdate_status = object.birthdate_status ?? "";
    message.expiration_date_status = object.expiration_date_status ?? "";
    message.issuing_country_status = object.issuing_country_status ?? "";
    message.name_status = object.name_status ?? "";
    return message;
  },
};

function createBaseSubmitUserVerificationReq(): SubmitUserVerificationReq {
  return { iam: undefined };
}

export const SubmitUserVerificationReq = {
  encode(message: SubmitUserVerificationReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubmitUserVerificationReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitUserVerificationReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitUserVerificationReq {
    return { iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined };
  },

  toJSON(message: SubmitUserVerificationReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SubmitUserVerificationReq>, I>>(base?: I): SubmitUserVerificationReq {
    return SubmitUserVerificationReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubmitUserVerificationReq>, I>>(object: I): SubmitUserVerificationReq {
    const message = createBaseSubmitUserVerificationReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    return message;
  },
};

function createBaseUsereSubmitSpecialRoleReq(): UsereSubmitSpecialRoleReq {
  return { iam: undefined, special_role: 0 };
}

export const UsereSubmitSpecialRoleReq = {
  encode(message: UsereSubmitSpecialRoleReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.special_role !== 0) {
      writer.uint32(16).int32(message.special_role);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UsereSubmitSpecialRoleReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUsereSubmitSpecialRoleReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.special_role = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UsereSubmitSpecialRoleReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      special_role: isSet(object.special_role) ? Number(object.special_role) : 0,
    };
  },

  toJSON(message: UsereSubmitSpecialRoleReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.special_role !== undefined && (obj.special_role = Math.round(message.special_role));
    return obj;
  },

  create<I extends Exact<DeepPartial<UsereSubmitSpecialRoleReq>, I>>(base?: I): UsereSubmitSpecialRoleReq {
    return UsereSubmitSpecialRoleReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UsereSubmitSpecialRoleReq>, I>>(object: I): UsereSubmitSpecialRoleReq {
    const message = createBaseUsereSubmitSpecialRoleReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.special_role = object.special_role ?? 0;
    return message;
  },
};

function createBasePlaidIdentityVerificationWebhook(): PlaidIdentityVerificationWebhook {
  return { Data: undefined };
}

export const PlaidIdentityVerificationWebhook = {
  encode(message: PlaidIdentityVerificationWebhook, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Data !== undefined) {
      Struct.encode(Struct.wrap(message.Data), writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PlaidIdentityVerificationWebhook {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePlaidIdentityVerificationWebhook();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.Data = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PlaidIdentityVerificationWebhook {
    return { Data: isObject(object.Data) ? object.Data : undefined };
  },

  toJSON(message: PlaidIdentityVerificationWebhook): unknown {
    const obj: any = {};
    message.Data !== undefined && (obj.Data = message.Data);
    return obj;
  },

  create<I extends Exact<DeepPartial<PlaidIdentityVerificationWebhook>, I>>(
    base?: I,
  ): PlaidIdentityVerificationWebhook {
    return PlaidIdentityVerificationWebhook.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PlaidIdentityVerificationWebhook>, I>>(
    object: I,
  ): PlaidIdentityVerificationWebhook {
    const message = createBasePlaidIdentityVerificationWebhook();
    message.Data = object.Data ?? undefined;
    return message;
  },
};

function createBaseUserUpdateExtraInformationReq(): UserUpdateExtraInformationReq {
  return {
    iam: undefined,
    employment_title: "",
    employment_length: "",
    home_ownership: "",
    annual_income: "",
    gender: "",
    marital_status: "",
    dependents: "",
    educational_degree: "",
  };
}

export const UserUpdateExtraInformationReq = {
  encode(message: UserUpdateExtraInformationReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.iam !== undefined) {
      InternalIAM.encode(message.iam, writer.uint32(10).fork()).ldelim();
    }
    if (message.employment_title !== "") {
      writer.uint32(18).string(message.employment_title);
    }
    if (message.employment_length !== "") {
      writer.uint32(26).string(message.employment_length);
    }
    if (message.home_ownership !== "") {
      writer.uint32(34).string(message.home_ownership);
    }
    if (message.annual_income !== "") {
      writer.uint32(42).string(message.annual_income);
    }
    if (message.gender !== "") {
      writer.uint32(50).string(message.gender);
    }
    if (message.marital_status !== "") {
      writer.uint32(58).string(message.marital_status);
    }
    if (message.dependents !== "") {
      writer.uint32(66).string(message.dependents);
    }
    if (message.educational_degree !== "") {
      writer.uint32(74).string(message.educational_degree);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserUpdateExtraInformationReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserUpdateExtraInformationReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iam = InternalIAM.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.employment_title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.employment_length = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.home_ownership = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annual_income = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.gender = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.marital_status = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.dependents = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.educational_degree = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserUpdateExtraInformationReq {
    return {
      iam: isSet(object.iam) ? InternalIAM.fromJSON(object.iam) : undefined,
      employment_title: isSet(object.employment_title) ? String(object.employment_title) : "",
      employment_length: isSet(object.employment_length) ? String(object.employment_length) : "",
      home_ownership: isSet(object.home_ownership) ? String(object.home_ownership) : "",
      annual_income: isSet(object.annual_income) ? String(object.annual_income) : "",
      gender: isSet(object.gender) ? String(object.gender) : "",
      marital_status: isSet(object.marital_status) ? String(object.marital_status) : "",
      dependents: isSet(object.dependents) ? String(object.dependents) : "",
      educational_degree: isSet(object.educational_degree) ? String(object.educational_degree) : "",
    };
  },

  toJSON(message: UserUpdateExtraInformationReq): unknown {
    const obj: any = {};
    message.iam !== undefined && (obj.iam = message.iam ? InternalIAM.toJSON(message.iam) : undefined);
    message.employment_title !== undefined && (obj.employment_title = message.employment_title);
    message.employment_length !== undefined && (obj.employment_length = message.employment_length);
    message.home_ownership !== undefined && (obj.home_ownership = message.home_ownership);
    message.annual_income !== undefined && (obj.annual_income = message.annual_income);
    message.gender !== undefined && (obj.gender = message.gender);
    message.marital_status !== undefined && (obj.marital_status = message.marital_status);
    message.dependents !== undefined && (obj.dependents = message.dependents);
    message.educational_degree !== undefined && (obj.educational_degree = message.educational_degree);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserUpdateExtraInformationReq>, I>>(base?: I): UserUpdateExtraInformationReq {
    return UserUpdateExtraInformationReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserUpdateExtraInformationReq>, I>>(
    object: I,
  ): UserUpdateExtraInformationReq {
    const message = createBaseUserUpdateExtraInformationReq();
    message.iam = (object.iam !== undefined && object.iam !== null) ? InternalIAM.fromPartial(object.iam) : undefined;
    message.employment_title = object.employment_title ?? "";
    message.employment_length = object.employment_length ?? "";
    message.home_ownership = object.home_ownership ?? "";
    message.annual_income = object.annual_income ?? "";
    message.gender = object.gender ?? "";
    message.marital_status = object.marital_status ?? "";
    message.dependents = object.dependents ?? "";
    message.educational_degree = object.educational_degree ?? "";
    return message;
  },
};

function createBaseUserExtraInformationRes(): UserExtraInformationRes {
  return {
    user_id: 0,
    employment_title: "",
    employment_length: "",
    home_ownership: "",
    annual_income: "",
    gender: "",
    marital_status: "",
    dependents: "",
    educational_degree: "",
  };
}

export const UserExtraInformationRes = {
  encode(message: UserExtraInformationRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user_id !== 0) {
      writer.uint32(8).int64(message.user_id);
    }
    if (message.employment_title !== "") {
      writer.uint32(18).string(message.employment_title);
    }
    if (message.employment_length !== "") {
      writer.uint32(26).string(message.employment_length);
    }
    if (message.home_ownership !== "") {
      writer.uint32(34).string(message.home_ownership);
    }
    if (message.annual_income !== "") {
      writer.uint32(42).string(message.annual_income);
    }
    if (message.gender !== "") {
      writer.uint32(50).string(message.gender);
    }
    if (message.marital_status !== "") {
      writer.uint32(58).string(message.marital_status);
    }
    if (message.dependents !== "") {
      writer.uint32(66).string(message.dependents);
    }
    if (message.educational_degree !== "") {
      writer.uint32(74).string(message.educational_degree);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserExtraInformationRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserExtraInformationRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.user_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.employment_title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.employment_length = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.home_ownership = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annual_income = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.gender = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.marital_status = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.dependents = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.educational_degree = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserExtraInformationRes {
    return {
      user_id: isSet(object.user_id) ? Number(object.user_id) : 0,
      employment_title: isSet(object.employment_title) ? String(object.employment_title) : "",
      employment_length: isSet(object.employment_length) ? String(object.employment_length) : "",
      home_ownership: isSet(object.home_ownership) ? String(object.home_ownership) : "",
      annual_income: isSet(object.annual_income) ? String(object.annual_income) : "",
      gender: isSet(object.gender) ? String(object.gender) : "",
      marital_status: isSet(object.marital_status) ? String(object.marital_status) : "",
      dependents: isSet(object.dependents) ? String(object.dependents) : "",
      educational_degree: isSet(object.educational_degree) ? String(object.educational_degree) : "",
    };
  },

  toJSON(message: UserExtraInformationRes): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = Math.round(message.user_id));
    message.employment_title !== undefined && (obj.employment_title = message.employment_title);
    message.employment_length !== undefined && (obj.employment_length = message.employment_length);
    message.home_ownership !== undefined && (obj.home_ownership = message.home_ownership);
    message.annual_income !== undefined && (obj.annual_income = message.annual_income);
    message.gender !== undefined && (obj.gender = message.gender);
    message.marital_status !== undefined && (obj.marital_status = message.marital_status);
    message.dependents !== undefined && (obj.dependents = message.dependents);
    message.educational_degree !== undefined && (obj.educational_degree = message.educational_degree);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserExtraInformationRes>, I>>(base?: I): UserExtraInformationRes {
    return UserExtraInformationRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserExtraInformationRes>, I>>(object: I): UserExtraInformationRes {
    const message = createBaseUserExtraInformationRes();
    message.user_id = object.user_id ?? 0;
    message.employment_title = object.employment_title ?? "";
    message.employment_length = object.employment_length ?? "";
    message.home_ownership = object.home_ownership ?? "";
    message.annual_income = object.annual_income ?? "";
    message.gender = object.gender ?? "";
    message.marital_status = object.marital_status ?? "";
    message.dependents = object.dependents ?? "";
    message.educational_degree = object.educational_degree ?? "";
    return message;
  },
};

function createBaseAdminAnalyticsGetTotalUsersCountRes(): AdminAnalyticsGetTotalUsersCountRes {
  return { total_user_count: 0, total_borrower_count: 0, total_investor_count: 0, total_provider_count: 0 };
}

export const AdminAnalyticsGetTotalUsersCountRes = {
  encode(message: AdminAnalyticsGetTotalUsersCountRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.total_user_count !== 0) {
      writer.uint32(8).int64(message.total_user_count);
    }
    if (message.total_borrower_count !== 0) {
      writer.uint32(16).int64(message.total_borrower_count);
    }
    if (message.total_investor_count !== 0) {
      writer.uint32(24).int64(message.total_investor_count);
    }
    if (message.total_provider_count !== 0) {
      writer.uint32(32).int64(message.total_provider_count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdminAnalyticsGetTotalUsersCountRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdminAnalyticsGetTotalUsersCountRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.total_user_count = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.total_borrower_count = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.total_investor_count = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.total_provider_count = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdminAnalyticsGetTotalUsersCountRes {
    return {
      total_user_count: isSet(object.total_user_count) ? Number(object.total_user_count) : 0,
      total_borrower_count: isSet(object.total_borrower_count) ? Number(object.total_borrower_count) : 0,
      total_investor_count: isSet(object.total_investor_count) ? Number(object.total_investor_count) : 0,
      total_provider_count: isSet(object.total_provider_count) ? Number(object.total_provider_count) : 0,
    };
  },

  toJSON(message: AdminAnalyticsGetTotalUsersCountRes): unknown {
    const obj: any = {};
    message.total_user_count !== undefined && (obj.total_user_count = Math.round(message.total_user_count));
    message.total_borrower_count !== undefined && (obj.total_borrower_count = Math.round(message.total_borrower_count));
    message.total_investor_count !== undefined && (obj.total_investor_count = Math.round(message.total_investor_count));
    message.total_provider_count !== undefined && (obj.total_provider_count = Math.round(message.total_provider_count));
    return obj;
  },

  create<I extends Exact<DeepPartial<AdminAnalyticsGetTotalUsersCountRes>, I>>(
    base?: I,
  ): AdminAnalyticsGetTotalUsersCountRes {
    return AdminAnalyticsGetTotalUsersCountRes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdminAnalyticsGetTotalUsersCountRes>, I>>(
    object: I,
  ): AdminAnalyticsGetTotalUsersCountRes {
    const message = createBaseAdminAnalyticsGetTotalUsersCountRes();
    message.total_user_count = object.total_user_count ?? 0;
    message.total_borrower_count = object.total_borrower_count ?? 0;
    message.total_investor_count = object.total_investor_count ?? 0;
    message.total_provider_count = object.total_provider_count ?? 0;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
