import AccessControl from 'abacl';
import { NavigateFunction } from 'react-router';

import { UserPermissionType } from '@/@types/stub/authorization_message';
import { useAppDispatch } from '@/hooks/redux';
import { setSelectedRole } from '@/redux/slices/authSlice';
import { GetUserPermissionsService } from '@/services/UserService';

import constants from './constants';

export enum Role {
  Admin = 'admin',
  User = 'user',
  Investor = 'investor',
  Provider = 'provider'
}

const accessControl = new AccessControl([], { strict: true });

export default accessControl;

export const getPermissionsAsync = async (
  dispatch: ReturnType<typeof useAppDispatch>,
  selectedRole: UserPermissionType | null,
  navigate: NavigateFunction
) => {
  const storedRole = localStorage.getItem(constants.USER_ROLE);

  if (storedRole === 'null') {
    dispatch(setSelectedRole(null));
  } else if (
    !storedRole ||
    !Object.values(UserPermissionType).includes(Number(storedRole))
  ) {
    navigate('/auth/login', {
      replace: true,
      state: {
        step: 2
      }
    });
    return;
  } else if (!selectedRole) {
    dispatch(setSelectedRole(storedRole ? Number(storedRole) : null));
  }

  const response = await GetUserPermissionsService(
    selectedRole === null ? undefined : selectedRole
  );

  accessControl.clear();

  response?.data?.data?.permissions?.map((permission) =>
    accessControl.update({
      subject: Role.User,
      action: permission?.method,
      object: permission?.route
    })
  );

  await Promise.resolve();
};
