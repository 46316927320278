/* eslint-disable react/display-name */
import { ComponentType, FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from '@/hooks/redux';
import { selectAccessToken } from '@/redux/slices/authSlice';

const withAuthentication =
  <P extends JSX.IntrinsicAttributes>(Component: ComponentType<P>): FC<P> =>
  (props) => {
    // Redux
    const accessToken = useAppSelector(selectAccessToken);

    if (Boolean(accessToken)) {
      return <Component {...props} />;
    }

    return (
      <Navigate
        to={{
          pathname: '/auth/login'
        }}
      />
    );
  };

export default withAuthentication;
