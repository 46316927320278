import 'nprogress/nprogress.css';

import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

import App from '@/App';
import { SidebarProvider } from '@/contexts/SidebarContext';
import * as serviceWorker from '@/serviceWorker';

import Status500 from './content/pages/Status/Status500';
import { store } from './redux/store';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://6b84df348677f216a5e0f1add424649b@o4506536117731328.ingest.sentry.io/4506547736608768',
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        networkCaptureBodies: true,
        networkDetailAllowUrls: [
          'localhost',
          'https://api-dev.lendin.com',
          'https://api-stage.lendin.com',
          'https://api.lendin.com',
          'https://apiprod.lendin.com'
        ]
      })
    ],
    environment: import.meta.env.VITE_SENTRY_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      'https://api-dev.lendin.com',
      'https://api-stage.lendin.com',
      'https://api.lendin.com',
      'https://apiprod.lendin.com'
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0
  });
}

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <ReduxProvider store={store}>
          <Sentry.ErrorBoundary fallback={<Status500 />} showDialog>
            <App />
          </Sentry.ErrorBoundary>
        </ReduxProvider>
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
