import { ChangeCircleTwoTone, HighlightOff } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemButton,
  ListItemText,
  Stack
} from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';

import constants from '@/utils/constants';

import SelectRole from '../SelectRole';

export default function ChangeRoleModal() {
  // States
  const [open, setOpen] = useState<boolean>(false);

  // Navigate
  //const navigate = useNavigate();

  // Functions
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItemButton
        sx={{ gap: 1 }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <ChangeCircleTwoTone fontSize="small" color="error" />

        <ListItemText primary={t('common.change_role')} />
      </ListItemButton>

      {open && (
        <Dialog open={open} fullWidth maxWidth="sm">
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              px: 0.5
            }}
          >
            <DialogTitle
              variant="h5"
              fontSize="20px"
              sx={{ flex: 1, p: { xs: 1.5, sm: 2 } }}
            >
              {t('common.select_role')}
            </DialogTitle>

            <IconButton onClick={handleClose} sx={{ m: { xs: 1, sm: 1 } }}>
              <HighlightOff />
            </IconButton>
          </Stack>

          <DialogContent sx={{ pb: 7 }}>
            <SelectRole
              onRedirect={() => {
                // navigate(constants.REDIRECT_AFTER_LOGIN, { replace: true });
                // setOpen(false);
                // window.location.reload();
                window.location.replace(constants.REDIRECT_AFTER_LOGIN);
              }}
              modalMode
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
