import { Link as MuiLink } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';

function Link(props: PropsWithChildren<{ to: string; target?: string }>) {
  const { children, to, target } = props;

  return (
    <MuiLink
      color="info.main"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: 2,
        '&:hover': {
          textDecoration: 'none !important'
        }
      }}
      component={RouterLink}
      to={to}
      target={target}
    >
      {children}
    </MuiLink>
  );
}

export default Link;
