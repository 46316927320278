import { Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { FC, ReactNode } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

interface ScrollbarProps {
  className?: string;
  children?: ReactNode;
}

const Scrollbar: FC<ScrollbarProps> = ({ children, ...rest }) => {
  const theme = useTheme();

  return (
    <Scrollbars
      autoHide={false}
      renderThumbVertical={() => {
        return (
          <Box
            sx={{
              width: 5,
              background:
                theme.palette.mode === 'dark'
                  ? `${theme.colors.alpha.black[10]}`
                  : `${theme.colors.alpha.white[50]}`,
              borderRadius: `${theme.general.borderRadiusLg}`,
              transition: `${theme.transitions.create(['background'])}`,

              '&:hover': {
                background:
                  theme.palette.mode === 'dark'
                    ? `${theme.colors.alpha.black[40]}`
                    : `${theme.colors.alpha.white[70]}`
              }
            }}
          />
        );
      }}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Scrollbar;
