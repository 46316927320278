import { toast } from 'react-toastify';

class MyError extends Error {
  constructor(message: string) {
    super(message);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, MyError.prototype);
  }

  toastMessage(): void {
    toast.error(this.message);
  }
}

export default MyError;
